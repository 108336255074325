// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Camera = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <g fill="none" fill-rule="evenodd">
        <g>
            <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.685" d="M14.037 3.078h3.616c.828 0 1.5.671 1.5 1.5v9.22c0 .828-.672 1.5-1.5 1.5H2.343c-.828 0-1.5-.672-1.5-1.5v-9.22c0-.829.672-1.5 1.5-1.5h3.616" transform="translate(0 2)"/>
            <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.685" d="M13.79 8.761c0 2.094-1.697 3.792-3.792 3.792-2.095 0-3.792-1.698-3.792-3.792 0-2.095 1.697-3.793 3.792-3.793 2.095 0 3.792 1.698 3.792 3.793z" transform="translate(0 2)"/>
            <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width=".5" d="M7.762 8.761c0-1.235 1-2.236 2.236-2.236" transform="translate(0 2)"/>
            <path fill="#FFF" d="M16.219 4.824c0 .287-.232.52-.52.52-.285 0-.517-.234-.517-.52 0-.285.232-.518.518-.518.287 0 .519.232.519.518" transform="translate(0 2)"/>
            <path stroke="#FFF" stroke-linecap="round" stroke-width="1.685" d="M14.037 3.078h0c0-1.23-.59-2.236-1.312-2.236H7.269c-.72 0-1.31 1.006-1.31 2.236" transform="translate(0 2)"/>
        </g>
    </g>
  </Icon>
)

export default Camera;