import { createGlobalStyle } from 'styled-components';

export const BetaReset = createGlobalStyle`
  h1, h2, h3, h4, h5, h6, p {
    margin-top: unset;
    margin-bottom: unset;
  }

  input {
    transition: unset;
  }

  a,
  input,
  select,
  textarea,
  button {
    transition: unset;
  }
`;
