import styled from 'styled-components';

import { getModifier } from '../../utils/functions';
import {
  baseFontFamily,
  colors,
  componentWidth,
  fontSize,
  fontWeight,
  global,
  spacing,
} from '../../utils/styles';

const hideText = `
  text-indent: -9999px;
  white-space: nowrap;
`;

export const StyledButton = styled.button`
  &,
  &:link,
  &:visited {
    -webkit-appearance: none;
    background-color: ${colors.orange};
    border-radius: 0;
    border: none;
    color: ${colors.black};
    cursor: pointer;
    display: inline-block;
    font-family: ${baseFontFamily};
    font-size: ${fontSize.xSmall};
    font-weight: ${fontWeight.medium};
    line-height: 1;
    margin: 0;
    min-width: 200px;
    opacity: 1;
    overflow: hidden;
    padding: 18px ${spacing.base};
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: background ${global.transitionSpeed} ${global.easing} 0s,
      color ${global.transitionSpeed} ${global.easing} 0s,
      border-color ${global.transitionSpeed} ${global.easing} 0s,
      opacity ${global.transitionSpeed} ${global.easing} 0s;
    vertical-align: middle;
    width: auto;

    svg {
      display: inline-block;
      fill: ${colors.black};
      height: 9px;
      margin-right: ${spacing.xSmall};
      transition: fill ${global.transitionSpeed} ${global.easing} 0s;
      width: 9px;
    }
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${colors.white};
  }

  &:disabled {
    pointer-events: none;
  }

  /* Modifier: secondary */
  ${(props) =>
    getModifier(props, 'secondary') &&
    `
      &,
      &:link,
      &:visited {
        background-color: ${colors.transparent};
        border: solid 1px ${colors.orange};
        color: ${colors.white};
        padding: 18px ${spacing.base};

        svg {
          fill: ${colors.white};
        }
      }

      &:hover,
      &:active,
      &:focus {
        background-color: ${colors.transparent};
        border-color: ${colors.white};
      }
  `};

  /* Modifier: Link */
  ${(props) =>
    getModifier(props, 'link') &&
    `
      &,
      &:link,
      &:visited {
        background: ${colors.transparent};
        color: ${colors.orange};

        svg {
          fill: ${colors.orange};
        }
      }

      &:hover,
      &:active,
      &:focus {
        background: ${colors.transparent};
        color: ${colors.white};

        svg {
          fill: ${colors.white};
        }
      }
  `};

  /* Modifier: Inline Link */
  ${(props) =>
    getModifier(props, 'inlineLink') &&
    `
      &,
      &:link,
      &:visited {
        background: ${colors.transparent};
        border-bottom: solid 1px transparent;
        color: ${colors.orange};
        display: inline;
        font-family: ${baseFontFamily};
        font-size: ${fontSize.xSmall};
        font-weight: ${fontWeight.medium};
        line-height: 1.5;
        min-width: 0;
        padding: 0;
        text-transform: uppercase;

        &:hover {
          border-color: ${colors.orange};
        }

        svg {
          fill: ${colors.orange};
          margin-right: ${spacing.xxSmall};
        }
      }

      &:hover,
      &:active,
      &:focus {
        background: ${colors.transparent};
      }
  `};

  /* Modifier: Text Link */
  ${(props) =>
    getModifier(props, 'textLink') &&
    `
      &,
      &:link,
      &:visited {
        background: ${colors.transparent};
        border-bottom: solid 1px transparent;
        color: ${colors.orange};
        display: inline;
        font-family: ${baseFontFamily};
        font-size: ${fontSize.base};
        font-weight: ${fontWeight.normal};
        line-height: 1.5;
        min-width: 0;
        padding: 0;
        text-transform: none;

        &:hover {
          border-color: ${colors.orange};
        }
      }

      &:hover,
      &:active,
      &:focus {
        background: ${colors.transparent};
      }
  `};

  /* Modifier: isProcessing */
  ${(props) =>
    getModifier(props, 'isProcessing') &&
    `
      &,
      &:link,
      &:visited {
        ${hideText};
      }
  `};

  /* Modifier: Disabled */
  ${(props) =>
    getModifier(props, 'disabled') &&
    `
      &,
      &:link,
      &:visited {
        opacity: 0.3;
      }
  `};

  /* Modifier: iconOnly */
  ${(props) =>
    getModifier(props, 'iconOnly') &&
    `
      &,
      &:link,
      &:visited {
        svg {
          height: 12px;
          margin-right: 0;
          width: 12px;
        }
      }
  `};

  /* Modifier: iconOnly */
  ${(props) =>
    getModifier(props, 'altHover') &&
    `
      &:hover,
      &:active,
      &:focus {
        border-color: transparent;
        opacity: 0.5;
      }
  `};

  /* Modifier: Large Icon */
  ${(props) =>
    getModifier(props, 'largeIcon') &&
    `
      &,
      &:link,
      &:visited {
        svg {
          height: 16px;
          vertical-align: top;
          width: 16px;
        }
      }
  `};

  /* Modifier: smallPadding */
  ${(props) =>
    getModifier(props, 'smallPadding') &&
    `
      &,
      &:link,
      &:visited {
        min-width: 0;
        padding: ${spacing.xSmall};
      }
  `};

  /* Modifier: marginBottom */
  ${(props) =>
    getModifier(props, 'marginBottom') &&
    `
      &,
      &:link,
      &:visited {
        margin-bottom: ${spacing.base};
      }
  `};

  /* Modifier: fullWidth */
  ${(props) =>
    getModifier(props, 'fullWidth') &&
    `
    &,
    &:link,
    &:visited {
      display: block;
      max-width: ${componentWidth.forms}px;
      min-width: 0;
      width: 100%;
    }
  `}

  /* Modifier: Icon Right */
  ${(props) =>
    getModifier(props, 'iconRight') &&
    `
      &,
      &:link,
      &:visited {
        svg {
          margin: 0 0 0 ${spacing.small};
        }
      }
  `};
`;
