const AZURE_APPLICATION_ID = process.env.REACT_APP_AZURE_APPLICATION_ID;
const { origin } = window.location;

export const msalConfig = {
  auth: {
    clientId: AZURE_APPLICATION_ID || '',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: `${origin}/login`
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

export const loginRequest = {
  scopes: [`api://${AZURE_APPLICATION_ID}/access_as_user`]
};
