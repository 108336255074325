import React from 'react';

import {
  Container,
  Message,
  Link,
  LeftTopBackground,
  LeftBottomBackground,
  RightBottomBackground,
} from './learnMoreSectionStyles';
import learnMoreBackground from '../../assets/learnMoreBackground.png';
import leftTopBackground from '../../assets/leftTop.png';
import leftBottomBackground from '../../assets/leftBottom.png';
import rightBottomBackground from '../../assets/rightBottom.png';

const LearnMoreSection = () => {
  return (
    <Container backgroundImage={learnMoreBackground}>
      <LeftTopBackground backgroundImage={leftTopBackground} />
      <LeftBottomBackground backgroundImage={leftBottomBackground} />
      <RightBottomBackground backgroundImage={rightBottomBackground} />

      <Message>Branding Package is now available!</Message>
      <Message>
        Create your own branded webapp.{' '}
        <Link
          href="https://support.skand.io/portal/en/kb/articles/branding-package"
          target="_blank"
        >
          Learn more here.
        </Link>
      </Message>
    </Container>
  );
};

export default LearnMoreSection;
