// Import Dependencies
import styled from 'styled-components';

// Import vars
import typography from '../../global/typography';

const Value = styled.dd`
  ${typography.headingLarge};
  margin: 0;
`;

export default Value;
