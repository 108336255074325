import styled from 'styled-components';
import { baseFontFamily, colors, fontSize, fontWeight } from '../../utils/styles';

export const Container = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  bottom: 94px;
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  font-family: ${baseFontFamily};
  gap: 0.75rem;
  left: 62px;
  margin-left: 10px;
  padding: 0 1rem 1rem;
  position: fixed;
  width: 230px;
  z-index: 100;

  &:before {
    border: 7px solid transparent;
    border-left: 0;
    border-right-color: ${colors.white};
    bottom: 17px;
    content: '';
    height: 0px;
    left: -7px;
    position: absolute;
    width: 0px;
  }
`;

export const StyledHeaderBar = styled.div`
  align-items: center;
  background: ${(props) => props.theme.bubbleColor};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  height: 42px;
  justify-content: space-between;
  margin: 0 -1rem;
  padding: 0 1rem;
`;

export const StyledTitle = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
`;

export const StyledTitleText = styled.div`
  color: ${colors.white};
  line-height: 16px;
  padding-top: 2px;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.xSmall};
`;

export const StyledLogo = styled.div`
  height: 16px;
  width: 16px;
  margin-right: 0.5rem;
`;

export const StyledClose = styled.button`
  cursor: pointer;
  svg {
    fill: ${colors.white};
    height: 10px;
    width: 10px;
  }
`;

export const StyledSmallText = styled.div`
  color: ${colors.grey500};
  font-size: ${fontSize.xSmall};
  font-weight: ${fontWeight.normal};
`;

export const StyledTagBox = styled.div`
  display: flex;
  color: ${colors.black}80;
  cursor: pointer;
  align-items: center;
  justify-content: ${(props) => (props.hasIconBefore ? 'start' : 'space-between')};

  a {
    color: ${colors.black}80;
  }

  &:hover {
    color: ${colors.black};

    a {
      color: ${colors.black};
    }
  }
`;

export const StyledTagItem = styled.div`
  align-items: center;
  background-color: ${colors.white};
  border-radius: 5px;
  border: 1px solid #e9ecef;
  color: ${colors.black}80;
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  margin: -0.1rem 0;

  &:hover {
    color: ${colors.black};
    background-color: #e9ecef;
  }
`;

export const StyledTagIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: ${(props) => (props.hasIconBefore ? '0.25rem' : 0)};
  margin-left: ${(props) => (props.hasIconBefore ? '-0.25rem' : 0)};
`;

export const StyledTagText = styled.div`
  font-size: ${fontSize.base};
  font-weight: ${fontWeight.normal};
`;

export const StyledTagLink = styled.a`
  font-size: ${fontSize.base};
  font-weight: ${fontWeight.normal};
`;
