import styled from 'styled-components';

import { breakpoint, spacing } from '../../../../utils/styles';

const Logo = styled.div`
  display: block;
  margin: 0 auto;
  padding: ${spacing.medium} 0 ${spacing.small};
  width: 175px;

  ${breakpoint.medium`
    padding-bottom: ${spacing.base};
    padding-top: ${spacing.large};
    width: 200px;
  `};

  svg {
    display: block;
    height: 38px; // ratio is - width x 0.215589771
    width: 175px;

    ${breakpoint.medium`
      height: 43px;
      width: 200px;
    `};
  }
`;

export default Logo;
