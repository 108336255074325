// Import Dependencies
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Import Components
import Button from '../Button';

class TableAction extends PureComponent {
  handleClick = (e) => {
    e.preventDefault();
    const { id, action, item } = this.props;

    action(id, item);
  };

  render() {
    const {
      label, modifiers, action, isProcessing, ...props
    } = this.props;

    return (
      <Button
        onClick={this.handleClick}
        disabled={isProcessing}
        modifiers={{
          inlineLink: true,
          altHover: true,
          disabled: isProcessing,
          ...modifiers,
        }}
        {...props}
      >
        {label}
      </Button>
    );
  }
}

TableAction.propTypes = {
  action: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isProcessing: PropTypes.bool,
  item: PropTypes.shape({}),
  label: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
  modifiers: PropTypes.shape({}),
};

TableAction.defaultProps = {
  isProcessing: false,
  item: {},
  modifiers: {},
};

export default TableAction;
