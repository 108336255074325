import { isEmail, isRequired } from '../../../utils/validators';

const getFields = () => [
  {
    id: 'first_name',
    name: 'first_name',
    label: 'First name',
    type: 'text',
    validate: [isRequired],
    'data-cy': 'demoFirstNameField',
  },
  {
    id: 'last_name',
    name: 'last_name',
    label: 'Last name',
    type: 'text',
    'data-cy': 'demoLastNameField',
  },
  {
    id: 'email',
    name: 'email',
    label: 'Email',
    type: 'email',
    validate: [isRequired, isEmail],
    'data-cy': 'demoEmailField',
  },
  {
    id: 'company',
    name: 'company',
    label: 'Company',
    type: 'text',
    validate: [isRequired],
    'data-cy': 'demoCompanyField',
  },
];

export default getFields;
