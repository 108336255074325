// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import SkandReports from 'skand.reports/src/main/reportsWrapper';
import { RouteLeavingGuard } from 'skandComponents';
import { componentHeight, sizes } from '../../utils/styles';

interface ReportsProps {
  hasWhiteLabelingNavbar?: boolean;
}

const Reports: React.FC<ReportsProps> = ({ hasWhiteLabelingNavbar = false }: ReportsProps) => {
  const history = useHistory();
  const offsetLeft = componentHeight.nav.default;
  const offsetTop = hasWhiteLabelingNavbar
    ? `calc(${sizes.whiteLabelingColorBar} + ${sizes.whiteLabelingContentBar})`
    : '0px';

  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <main
        style={{
          background: 'radial-gradient(circle at 0 0, #484f60, #20232a 100%)',
          height: `calc(100vh - ${offsetTop})`,
          margin: `0 0 0 ${offsetLeft}`,
          overflow: 'auto',
          position: 'relative',
          width: `calc(100vw - ${offsetLeft})`,
        }}
      >
        <SkandReports
          offset={{
            left: offsetLeft,
            top: offsetTop,
          }}
        />

        <RouteLeavingGuard
          when
          navigate={(path) => history.push(path)}
          shouldBlockNavigation={(nextLocation) => {
            // Workaround, use destination to decide wether to prompt
            const { pathname: next } = nextLocation;
            const { pathname: current } = window.location;
            if (/\/reports\/.+$/.test(current)) {
              if (next === '/reports/') return false;
              if (next !== current) return true;
            }

            return false;
          }}
        />
      </main>
    </>
  );
};

export default Reports;
