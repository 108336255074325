import { gql } from '@apollo/client';

const ACCOUNT_PAGE_QUERY = gql`
  query AccountPageQuery {
    userByAccountId {
      id
      email
      firstName
      lastName
      isAdmin
      account {
        name
      }
    }
  }
`;

export default ACCOUNT_PAGE_QUERY;
