import Cookies from 'js-cookie';

const ACCESS_TOKEN_NAME = process.env.REACT_APP_ACCESS_TOKEN_NAME;
const SHARE_LINK_TOKEN_HEADER = 'skand-share-link-token';
const SHARE_LINK_TOKEN_URL_PARAM = 'shareLinkToken';

const getAuth = () => {
  return Cookies.get(ACCESS_TOKEN_NAME);
};

const getShareLinkToken = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  return params.get(SHARE_LINK_TOKEN_URL_PARAM);
};

const getHeaders = () => {
  const auth = getAuth();
  const shareLinkToken = getShareLinkToken();
  const headers: Record<string, string> = {};

  if (auth) headers.authorization = auth;
  if (shareLinkToken) headers[SHARE_LINK_TOKEN_HEADER] = shareLinkToken;

  return headers;
};

export { getHeaders };
