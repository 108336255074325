import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATHS } from '../../../utils/constants';
import { DELETE_USER_MUTATION } from './mutations';

import { useHandleInvalidToken } from '../../../hooks';

const useHandlers = (setConfirmationOpen) => {
  const history = useHistory();
  const { checkInvalidTokenError } = useHandleInvalidToken();
  const [userIdToDelete, setUserIdToDelete] = useState('');

  const handleInvite = () => {
    history.push(`${PATHS.USERS}/new`);
  };

  const handleEdit = (id) => {
    history.push(`${PATHS.USERS}/${id}`);
  };

  const handleHideModal = () => {
    history.push(PATHS.USERS);
  };

  const handleDeleteConfirmation = (userId) => {
    setUserIdToDelete(userId);
    setConfirmationOpen(true);
  };

  const [deleteUser, { loading: isProcessing, error }] = useMutation(DELETE_USER_MUTATION, {
    onError: (e) => checkInvalidTokenError(e),
    update(cache) {
      cache.modify({
        fields: {
          usersByAccountId(existingRefs = [], { readField }) {
            return existingRefs.filter((ref) => userIdToDelete !== readField('id', ref));
          },
        },
      });
    },
  });

  const handleDelete = () => {
    deleteUser({ variables: { userId: userIdToDelete } });
  };

  return {
    isProcessing,
    handleInvite,
    handleHideModal,
    handleEdit,
    handleDeleteConfirmation,
    handleDelete,
    error,
  };
};

export default useHandlers;
