// Import Dependencies
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';

// Import Styled Components
import { rgba } from 'polished';
import StyledChart from '../../styles/components/Chart';
import Loader from '../Loader';

// Import Utilities
import { getChartColor } from '../../utils/functions';

import { colors } from '../../utils/styles';

class LineChart extends PureComponent {
  renderColour = (node) => {
    const { data } = this.props;
    const index = data.findIndex((item) => item.id === node.id);

    return getChartColor(index);
  };

  renderLegend = () => {
    const { data } = this.props;

    return (
      <StyledChart.Legend modifiers={{ topRight: true }}>
        {data &&
          data.map((item, i) => (
            <StyledChart.Label
              key={item.label}
              modifiers={{ color: getChartColor(i), marginLeft: true }}
            >
              {item.label}
            </StyledChart.Label>
          ))}
      </StyledChart.Legend>
    );
  };

  render() {
    const { data, isFetching } = this.props;
    if (!data) return <p>No chart data found</p>;

    return (
      <StyledChart.Container modifiers={{ fontWeightMedium: true }}>
        <Loader isFetching={isFetching}>
          {this.renderLegend()}
          <ResponsiveLine
            theme={{
              axis: {
                textColor: `${rgba(colors.white, 0.5)}`,
                fontSize: '12px',
              },
            }}
            axisBottom={{
              orient: 'bottom',
              tickPadding: 20,
              tickSize: 0,
            }}
            axisLeft={{
              orient: 'left',
              tickPadding: 20,
              tickSize: 0,
            }}
            margin={{
              bottom: 35,
              left: 35,
              right: 20,
              top: 5,
            }}
            curve="cardinal"
            enableGridX={false}
            enableGridY={false}
            isInteractive={false}
            dotBorderWidth={2}
            dotSize={8}
            dotBorderColor={colors.grey700}
            colorBy={this.renderColour}
            data={data}
          />
        </Loader>
      </StyledChart.Container>
    );
  }
}

LineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isFetching: PropTypes.bool,
};

LineChart.defaultProps = {
  data: null,
  isFetching: false,
};

export default LineChart;
