import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { PATHS } from '../../../utils/constants';
import { useHandleInvalidToken } from '../../../hooks';
import { DELETE_SITE_MUTATION } from './mutations';

const useHandlers = (setConfirmationOpen) => {
  const history = useHistory();
  const [siteIdToDelete, setSiteIdToDelete] = useState('');
  const { checkInvalidTokenError } = useHandleInvalidToken();

  const handleAdd = () => {
    history.push(`${PATHS.SITES}/new`);
  };

  const handleEdit = (siteId) => {
    history.push(`${PATHS.SITES}/${siteId}`);
  };

  const handleDeleteConfirmation = (siteId) => {
    setSiteIdToDelete(siteId);
    setConfirmationOpen(true);
  };

  const [deleteSite, { loading: isProcessing, error }] = useMutation(DELETE_SITE_MUTATION, {
    onError: (e) => checkInvalidTokenError(e),
    update(cache) {
      cache.modify({
        fields: {
          sitesByAccountId(existingRefs = [], { readField }) {
            return existingRefs.filter((ref) => siteIdToDelete !== readField('id', ref));
          },
        },
      });
    },
  });

  const handleDelete = () => {
    deleteSite({ variables: { siteId: siteIdToDelete } });
  };

  return { handleAdd, handleEdit, handleDeleteConfirmation, handleDelete, isProcessing, error };
};

export default useHandlers;
