// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Info = ({ ...props }) => (
  <Icon width={20} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 0C9.31396 0 12 2.68655 12 5.99967C12 9.31345 9.31396 12 6 12C2.68604 12 0 9.31345 0 5.99967C0 2.68655 2.68604 0 6 0Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.12675 9.71004H6.82163V4.76868H5.12675V9.71004ZM5.03232 3.21483C5.03232 2.67339 5.44416 2.28531 5.9856 2.28531C6.52632 2.28531 6.96264 2.67339 6.96264 3.21483C6.96264 3.75555 6.52632 4.13283 5.9856 4.13283C5.44416 4.13283 5.03232 3.75555 5.03232 3.21483Z"
      fill="black"
    />
  </Icon>
);

export default Info;
