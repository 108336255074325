const getAccountFields = () => [
  {
    id: 'accountName',
    name: 'accountName',
    label: 'Display Name',
    type: 'text',
    disabled: true
  }
];

export default getAccountFields;
