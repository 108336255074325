// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { checkAuthentication } from '../utils';

import { PATHS } from '../../../utils/constants';

const UnauthorisedOnlyRoute = ({ component: Component, exact, path }) => {
  const isUserAuthenticated = checkAuthentication();
  if (!isUserAuthenticated) {
    return (
      <Route
        exact={exact}
        path={path}
        onEnter={checkAuthentication()}
        render={() => <Component />}
      />
    );
  }

  return <Redirect to={PATHS.ROOT} />;
};

UnauthorisedOnlyRoute.propTypes = {
  component: PropTypes.func.isRequired,
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired
};

export default UnauthorisedOnlyRoute;
