/* eslint-disable complexity */
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { useHandleInvalidToken } from '../../../hooks';
import { USER_PAGE_QUERY, USER_DETAIL_QUERY } from './queries';

import { UserPageQuery } from './graphqlTypes/UserPageQuery';
import { UserDetailQuery, UserDetailQueryVariables } from './graphqlTypes/UserDetailQuery';

const useFetchData = () => {
  const { userId } = useParams<{ userId: string }>();
  const { checkInvalidTokenError } = useHandleInvalidToken();

  // fetch all users
  const {
    data: { usersByAccountId: users } = {
      usersByAccountId: [],
    },
    error: usersError,
    loading: usersLoading,
  } = useQuery<UserPageQuery>(USER_PAGE_QUERY, {
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      checkInvalidTokenError(e);
    },
  });

  // fetch user by id
  const [
    getUser,
    {
      data: { user: userDetail } = {
        user: { id: '' },
      },
      error: userDetailError,
      loading: userDetailLoading,
    },
  ] = useLazyQuery<UserDetailQuery, UserDetailQueryVariables>(USER_DETAIL_QUERY, {
    variables: { userId },
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      checkInvalidTokenError(e);
    },
  });

  useEffect(() => {
    if (userId) {
      getUser();
    }
  }, [userId, getUser]);

  const errorMessage = userDetailError?.message || usersError?.message;
  const loadingStatus = userDetailLoading || usersLoading;
  const error = userDetailError || usersError;

  return { users, userDetail, isFetching: loadingStatus, message: errorMessage, error };
};

export default useFetchData;
