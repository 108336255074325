// Import Utilities
import getFieldError from './getFieldError';

const getFormErrors = (fields, formData) =>
  fields.reduce((a, field) => {
    const error =
      field.validate && getFieldError(formData[field.name], field.validate, field.name, formData);
    return error && error.length
      ? {
          ...a,
          [field.name]: error,
        }
      : a;
  }, {});

export default getFormErrors;
