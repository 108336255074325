// Import Dependencies
import styled from 'styled-components';

// Import Gobals
import { fontSize, spacing } from '../../../utils/styles';
import typography from '../../global/typography';

const InlineTooltip = styled.p`
  ${typography.base};
  font-size: ${fontSize.small};
  margin: ${spacing.xSmall} 0 0;
`;

export default InlineTooltip;
