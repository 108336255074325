// Import Dependencies
import styled from 'styled-components';

// Import Elements
import Input from './Input';
import Label from './Label';

// Import Gobals
import { spacing, colors } from '../../../utils/styles';

export const CheckboxLegoLabel = styled.p`
  margin: 0;
  color: ${(props) => props.checked ? `${colors.white}` : 'inherit'}
`;

const Checkbox = styled.div`
  margin: ${spacing.xxSmall} 0;
  position: relative;
`;

Checkbox.Input = Input;
Checkbox.Label = Label;

export default Checkbox;
