// Import Dependencies
import styled from 'styled-components';

// Import vars
import typography from '../../global/typography';
import { breakpoint, colors, layer, spacing } from '../../../utils/styles';

const Tooltip = styled.div`
  ${typography.label};
  background-color: ${colors.grey800};
  border-radius: 2px;
  color: ${colors.white};
  display: none;
  // left: -${spacing.xSmall};
  left: ${spacing.xSmall};
  padding: 1px ${spacing.xSmall};
  pointer-events: none;
  position: relative;
  z-index: ${layer.tooltipOnTop};

  ${breakpoint.medium`
    display: block;
  `};

  &:before {
    background-color: transparent;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-color: transparent ${colors.grey800} transparent transparent;
    content: '';
    display: block;
    height: 0;
    left: -5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
  }
`;

export default Tooltip;
