import { gql } from '@apollo/client';

const SITES_PAGE_QUERY = gql`
  query SitesPageQuery {
    userByAccountId {
      id
      firstName
      lastName
      accountId
    }
    sitesByAccountId {
      id
      name
      code
      buildingIds
      manager
      accountId
    }
    usersByAccountId {
      id
      firstName
      lastName
      accountId
    }
  }
`;

export default SITES_PAGE_QUERY;
