// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import Globals
import { breakpoint, spacing } from '../../../utils/styles';

const Container = styled.div`
  max-width: 1400px;
  padding: 0 ${spacing.base};
  width: auto;

  ${breakpoint.medium`
    max-width: 1450px;
    max-width: calc(1400px + ${spacing.base} + ${spacing.base});
    padding: 0 ${spacing.base};
  `};

  ${breakpoint.xLarge`
    max-width: 1500px;
    max-width: calc(1400px + ${spacing.large} + ${spacing.large});
    padding: 0 ${spacing.large};
  `};

  ${breakpoint.xxxxLarge`
    max-width: 1600px;
    max-width: calc(1400px + ${spacing.xLarge} + ${spacing.xLarge});
    padding: 0 ${spacing.xLarge};
  `};

  ${(props) =>
    getModifier(props, 'fullHeight') &&
    `
      height: 100vh;
      position: relative;
    `};

  ${(props) =>
    getModifier(props, 'noMaxWidth') &&
    breakpoint.medium`
      max-width: none;
    `};

  ${(props) =>
    getModifier(props, 'noMaxWidth') &&
    breakpoint.xLarge`
      max-width: none;
    `};

  ${(props) =>
    getModifier(props, 'noMaxWidth') &&
    breakpoint.xxxxLarge`
      max-width: none;
    `};
`;

export default Container;
