// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Magnifier = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path d="M312.5,0C208.9,0,125,83.9,125,187.5c0,44.3,15.4,84.9,41.1,117l-160,160c-8.1,8.1-8.1,21.3,0,29.5c4.1,4.1,9.4,6.1,14.7,6.1s10.7-2,14.7-6.1l160-160c32.1,25.7,72.7,41.1,117,41.1C416.1,375,500,291.1,500,187.5S416.1,0,312.5,0z M312.5,333.3c-80.4,0-145.8-65.4-145.8-145.8S232.1,41.7,312.5,41.7s145.8,65.4,145.8,145.8S392.9,333.3,312.5,333.3z" />
  </Icon>
);

export default Magnifier;
