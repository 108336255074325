/* eslint-disable no-param-reassign */
import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Loader } from '../../components';
import reportPreview from './assets/reportPreview.png';
import sharedSitePreview from './assets/sharedSitePreview.png';
import sharedSitePreviewWithoutWatermark from './assets/sharedSitePreviewWithoutWatermark.png';
import defaultReportLogo from '../Branding/assets/defaultReportLogo.svg';
import defaultSharedSiteLogo from '../Branding/assets/defaultSharedSiteLogo.svg';
import {
  Container,
  Banner,
  Logo,
  ReportContainer,
  ColorBar,
  ReportWrapper
} from './brandingPreviewStyles';
import { useFetchData } from './utils';
import { LOGO_TYPES } from '../../utils/constants';

const BrandingPreview = () => {
  const { logoType } = useParams();
  const logoRef = useRef();
  const [isLogoRendering, setIsLogoRendering] = useState(true);
  const [logoErrorCount, setLogoErrorCount] = useState(0);
  const { account, fetchAccountData, isFetching } = useFetchData();
  const { logoUrl, bannerUrl, hasSkandWatermark, brandingColor } = account;

  const getPreviewImage = () => {
    if (hasSkandWatermark) return sharedSitePreview;
    return sharedSitePreviewWithoutWatermark;
  };

  const handleLogoOnError = (event) => {
    const imageElement = event.target;
    const defaultLogo = imageElement.id === 'report' ? defaultReportLogo : defaultSharedSiteLogo;
    if (logoErrorCount === 5) {
      imageElement.onerror = null;
      imageElement.src = defaultLogo;
      return;
    }

    setTimeout(async () => {
      const { data: newAccountData } = await fetchAccountData();
      const { bannerUrl: newBannerUrl, logoUrl: newLogoUrl } = newAccountData.accountByContext;
      const newUrl = imageElement.id === 'report' ? newBannerUrl : newLogoUrl;
      imageElement.src = newUrl;
      setLogoErrorCount((preCount) => preCount + 1);
    }, 1000);
  };

  const handleLogoOnLoad = () => {
    const interval = setInterval(() => {
      if (logoRef.current.naturalWidth > 0 && logoRef.current.naturalHeight > 0) {
        clearInterval(interval);
        setIsLogoRendering(false);
      }
    }, 50);
  };

  return (
    <>
      <Helmet>
        <title>Branding - Preview</title>
      </Helmet>

      <Loader isFetching={isFetching || isLogoRendering} />

      {logoType === LOGO_TYPES.REPORT_LOGO && (
        <ReportContainer display={!isLogoRendering}>
          <ReportWrapper backgroundImage={reportPreview}>
            <Banner
              id="report"
              alt="Report Logo"
              draggable="false"
              onError={handleLogoOnError}
              onLoad={handleLogoOnLoad}
              ref={logoRef}
              src={bannerUrl || defaultReportLogo}
            />
            <ColorBar background={brandingColor} />
          </ReportWrapper>
        </ReportContainer>
      )}

      {logoType === LOGO_TYPES.SHARED_SITE_LOGO && (
        <Container backgroundImage={getPreviewImage()} display={!isLogoRendering}>
          <Logo
            id="sharedSite"
            alt="Shared Site Logo"
            draggable="false"
            onError={handleLogoOnError}
            onLoad={handleLogoOnLoad}
            ref={logoRef}
            src={logoUrl || defaultSharedSiteLogo}
          />
        </Container>
      )}
    </>
  );
};

export default BrandingPreview;
