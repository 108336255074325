import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from 'utils/styles';

export const BetaLogo = () => {
  return (
    <Link to="/">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '62px',
          height: '74px',
          backgroundColor: colors.primary400
        }}
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 15.7877V32H16.6754C25.1816 32 32 24.2858 32 15.5666C32 6.84875 25.0235 0 16.5166 0C8.00896 0 0 7.06779 0 15.7877ZM8 15.8938V24H16.3377C20.5908 24 24 20.1429 24 15.7833C24 11.4244 20.5117 8 16.2583 8C12.0045 8 8 11.5339 8 15.8938Z"
            fill="white"
          />
        </svg>
      </div>
    </Link>
  );
};
