import React from 'react';
import PropTypes from 'prop-types';

import {
  NavbarContainer,
  NavbarColorLine,
  WhiteLabelContainer,
  WhiteLabelLogo
} from './whiteLabelingNavbarStyles';

const WhiteLabelingNavbar = ({ whiteLabelingAccount }) => {
  return (
    <NavbarContainer>
      <NavbarColorLine color={whiteLabelingAccount.color} />
      <WhiteLabelContainer>
        <WhiteLabelLogo src={whiteLabelingAccount.logo} alt={whiteLabelingAccount.name} />
      </WhiteLabelContainer>
    </NavbarContainer>
  );
};

WhiteLabelingNavbar.propTypes = {
  whiteLabelingAccount: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
    color: PropTypes.string
  })
};

WhiteLabelingNavbar.defaultProps = {
  whiteLabelingAccount: {}
};

export default WhiteLabelingNavbar;
