import isStrongPassword from 'validator/lib/isStrongPassword';

import { $tsToFix } from '../types';

const isValidPassword = (value: $tsToFix): $tsToFix => {
  return value &&
    isStrongPassword(value, {
      minLength: 8,
      minLowercase: 0,
      minUppercase: 0,
      minNumbers: 0,
      minSymbols: 0,
    })
    ? undefined
    : 'Minimum 8 characters';
};

export default isValidPassword;
