// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import vars
import { spacing } from '../../../utils/styles';

// Import Elements
import Cell from './Cell';
import Header from './Header';
import Inner from './Inner';
import Message from './Message';
import Row from './Row';

const Table = styled.div`
  max-width: 100%;
  overflow: auto;
  width: 100%;
  margin-bottom: ${spacing.midLarge};

  /* Modifier: Align Center */
  ${(props) =>
    getModifier(props, 'noMarginBottom') &&
    `
      margin-bottom: 0;
    `};
`;

// Set elements
Table.Cell = Cell;
Table.Header = Header;
Table.Inner = Inner;
Table.Message = Message;
Table.Row = Row;

export default Table;
