import { gql } from '@apollo/client';

export const CREATE_LEAD_MUTATION = gql`
  mutation CreateLead($lead: CreateLeadInput) {
    createLead(lead: $lead) {
      firstName
      lastName
      email
      company
    }
  }
`;
