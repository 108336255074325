// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledTitle from '../../styles/components/Title';

const ElementTypes = {
  'h1': StyledTitle.withComponent('h1'),
  'h2': StyledTitle.withComponent('h2'),
  'h3': StyledTitle.withComponent('h3'),
  'h4': StyledTitle.withComponent('h4')
}

const Title = ({ type, children, modifiers, ...props }) => {
  const ElementType = ElementTypes[type];

  return (
    <ElementType modifiers={{ type, ...modifiers }} {...props}>
      {children}
    </ElementType>
  );
};

Title.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  modifiers: PropTypes.shape({}),
};

Title.defaultProps = {
  type: 'h3',
  modifiers: {},
};

export default Title;
