import { isRequired } from '../../../utils/validators';

const getFields = () => [
  {
    id: 'name',
    name: 'name',
    label: 'Site name',
    type: 'text',
    isRequired: true,
    validate: [isRequired],
    'data-cy': 'siteDetailNameField',
  },
];

export default getFields;
