/* eslint-disable complexity */
import PropTypes from 'prop-types';
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PATHS } from '../../../utils/constants';
import { checkAuthentication } from '../utils';
import { getShareLinkToken } from '../utils/shareLink';
import { useIsBetaFeatureEnabled } from '../utils/useIsBetaFeatureEnabled';
import { Fallback } from './Fallback';

const PrivateRoute = ({
  component: Component,
  betaComponent,
  exact,
  path,
  hasWhiteLabelingNavbar,
  hasSideNavbar
}) => {
  const isUserAuthenticated = checkAuthentication();
  const hasShareLinkToken = getShareLinkToken();
  const isBetaFeatureEnabled = useIsBetaFeatureEnabled();

  const isUsingBetaVersionOrShareLink = isBetaFeatureEnabled || hasShareLinkToken;
  const Comp = isUsingBetaVersionOrShareLink ? betaComponent ?? Fallback : Component ?? Fallback;

  if (isUserAuthenticated || hasShareLinkToken) {
    return (
      <Route
        exact={exact}
        path={path}
        render={() => (
          <Comp hasWhiteLabelingNavbar={hasWhiteLabelingNavbar} hasSideNavbar={hasSideNavbar} />
        )}
      />
    );
  }

  return <Redirect to={PATHS.LOGIN} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  hasWhiteLabelingNavbar: PropTypes.bool,
  hasSideNavbar: PropTypes.bool,
  betaComponent: PropTypes.node
};

PrivateRoute.defaultProps = {
  component: null,
  exact: false,
  hasWhiteLabelingNavbar: false,
  hasSideNavbar: true,
  betaComponent: null
};

export default PrivateRoute;
