const getSearch = () => {
  return window.location.search.slice(1);
};

export const getSearchItem = (key: string): null | string => {
  const search = getSearch();
  if (!search) return null;
  const params = new URLSearchParams(search);
  return params.get(key);
};

export const getShareLinkToken = (): null | string => {
  return getSearchItem('shareLinkToken');
};
