import styled from 'styled-components';

import { colors } from '../../../../utils/styles';

export const ErrorPageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

export const Text = styled.p`
  font-size: 1.75rem;
  font-weight: normal;
`;

export const LinkToHome = styled.span`
  color: ${colors.blue500};
  cursor: pointer;
  font-size: 1.75rem;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

export const BoldText = styled.p`
  font-size: 1.75rem;
  font-weight: bold;
  margin-top: 3rem;
`;
