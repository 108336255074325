// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import vars
import { spacing } from '../../../utils/styles';

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.base};

  > div {
    margin-bottom: ${spacing.base};

    &:last-child {
      margin-bottom: 0;
    }
  }

  /* Modifier: inlineActions */
  ${(props) =>
    getModifier(props, 'inlineActions') &&
    `
    flex-direction: row;

    > div {
      margin-bottom: 0;
    }
  `};
`;

export default Actions;
