// Import Dependencies
import styled from 'styled-components';

// Import Elements
import { rgba } from 'polished';
import Input from './Input';

// Import Gobals
import typography from '../../global/typography';
import { colors, global } from '../../../utils/styles';

const Label = styled.label`
  ${typography.body};
  color: ${colors.white};
  cursor: pointer;
  line-height: 1;
  padding-left: 22px;
  position: relative;

  &:after {
    background-color: ${colors.grey400};
    border-radius: 100%;
    border: solid 1px ${colors.transparent};
    content: '';
    cursor: pointer;
    height: 14px;
    left: 0;
    position: absolute;
    top: 4px;
    transition: background-color ${global.transitionSpeed} ${global.easing} 0s,
      border-color ${global.transitionSpeed} ${global.easing} 0s;
    width: 14px;
  }

  &:hover {
    &:after {
      background-color: ${colors.white};
    }
  }

  ${Input}:focus + &:after {
    border-color: ${rgba(colors.white, 0.4)};
  }

  ${Input}:checked + &:after {
    background-color: ${colors.orange};
  }
`;

export default Label;
