import { gql } from '@apollo/client';

export const BRANDING_PREVIEW_PAGE_QUERY = gql`
  query BrandingPreviewPageQuery {
    accountByContext {
      bannerUrl
      brandingColor
      brandingStatus
      hasSkandWatermark
      logoUrl
    }
  }
`;
