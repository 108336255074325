// Import Dependencies
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button as LegoButton } from '@skand/lego';
// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import Globals
import typography from '../../global/typography';
import * as utils from '../../global/utils';
import { colors, global, spacing, componentWidth } from '../../../utils/styles';

export const getButtonStyles = (modifiers) => {
  if (modifiers.secondary || modifiers.cancel) {
    return {
      tone: 'primary',
      variant: 'mixed'
    };
  }

  return {
    tone: 'primary',
    variant: 'filled'
  };
};

export const BetaPrimaryButtonStyles = styled(LegoButton)`
  width: 136px;
  height: 28px;
  border-radius: 4px;
  padding: 4px 8px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  border: none;
  cursor: pointer;
  font-size: 12px;
  margin-bottom: auto;
`;

export const CustomLegoButton = styled(LegoButton)`
  position: relative;
  ${(props) =>
    getModifier(props, 'secondary') &&
    `
      color: ${colors.white};
      background-color: ${colors.transparent};
      border: solid 1px ${colors.orange};
      svg {
        fill: ${colors.white};
      }

      &:hover:not(:disabled) {
        color: ${colors.white};
        border-color: ${colors.white};
      }
  `};

  ${(props) =>
    getModifier(props, 'isProcessing') &&
    `
      ${utils.hideText};
  `};
  ${(props) =>
    getModifier(props, 'fullWidth') &&
    `
      width: 100%;
    `}
`;

const Button = styled.button`
  &,
  &:link,
  &:visited {
    ${typography.smallCaps}
    -webkit-appearance: none;
    background-color: ${colors.orange};
    border-radius: 0;
    border: none;
    color: ${colors.black};
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    margin: 0;
    min-width: 200px;
    opacity: 1;
    overflow: hidden;
    padding: 18px ${spacing.base};
    position: relative;
    text-align: center;
    transition: background ${global.transitionSpeed} ${global.easing} 0s,
      color ${global.transitionSpeed} ${global.easing} 0s,
      border-color ${global.transitionSpeed} ${global.easing} 0s,
      opacity ${global.transitionSpeed} ${global.easing} 0s;
    vertical-align: middle;
    width: auto;

    svg {
      display: inline-block;
      fill: ${colors.black};
      height: 9px;
      margin-right: ${spacing.xSmall};
      transition: fill ${global.transitionSpeed} ${global.easing} 0s;
      width: 9px;
    }
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${colors.white};
  }

  &:disabled {
    pointer-events: none;
  }

  /* Modifier: secondary */
  ${(props) =>
    getModifier(props, 'secondary') &&
    `
      &,
      &:link,
      &:visited {
        background-color: ${colors.transparent};
        border: solid 1px ${colors.orange};
        color: ${colors.white};
        padding: 18px ${spacing.base};

        svg {
          fill: ${colors.white};
        }
      }

      &:hover,
      &:active,
      &:focus {
        background-color: ${colors.transparent};
        border-color: ${colors.white};
      }
  `};

  /* Modifier: confirm */
  ${(props) =>
    getModifier(props, 'confirm') &&
    `
      &,
      &:link,
      &:visited {
        height: 3rem;
        min-width: 12rem;
      }
  `};

  /* Modifier: cancel */
  ${(props) =>
    getModifier(props, 'cancel') &&
    `
      &,
      &:link,
      &:visited {
        background-color: ${colors.grey500};
        border: none;
        color: ${colors.white};
        height: 3rem;
        padding: 18px ${spacing.base};
        min-width: 12rem;
      }

      &:hover,
      &:active,
      &:focus {
        background-color: ${colors.black};
      }
  `};

  /* Modifier: Link */
  ${(props) =>
    getModifier(props, 'link') &&
    `
      &,
      &:link,
      &:visited {
        background: ${colors.transparent};
        color: ${colors.orange};

        svg {
          fill: ${colors.orange};
        }
      }

      &:hover,
      &:active,
      &:focus {
        background: ${colors.transparent};
        color: ${colors.white};

        svg {
          fill: ${colors.white};
        }
      }
  `};

  /* Modifier: Inline Link */
  ${(props) =>
    getModifier(props, 'inlineLink') &&
    `
      &,
      &:link,
      &:visited {
        ${typography.link};
        background: ${colors.transparent};
        min-width: 0;
        padding: 0;

        svg {
          fill: ${colors.orange};
          margin-right: ${spacing.xxSmall};
        }
      }

      &:hover,
      &:active,
      &:focus {
        background: ${colors.transparent};
      }
  `};

  /* Modifier: Text Link */
  ${(props) =>
    getModifier(props, 'textLink') &&
    `
      &,
      &:link,
      &:visited {
        ${typography.textLink};
        color: ${colors.orange};
        text-transform: none;
        background: ${colors.transparent};
        min-width: 0;
        padding: 0;
      }

      &:hover,
      &:active,
      &:focus {
        background: ${colors.transparent};
      }
  `};

  /* Modifier: isProcessing */
  ${(props) =>
    getModifier(props, 'isProcessing') &&
    `
      &,
      &:link,
      &:visited {
        ${utils.hideText};
      }
  `};

  /* Modifier: Disabled */
  ${(props) =>
    getModifier(props, 'disabled') &&
    `
      &,
      &:link,
      &:visited {
        opacity: 0.3;
      }
  `};

  /* Modifier: iconOnly */
  ${(props) =>
    getModifier(props, 'iconOnly') &&
    `
      &,
      &:link,
      &:visited {
        svg {
          height: 12px;
          margin-right: 0;
          width: 12px;
        }
      }
  `};

  /* Modifier: iconOnly */
  ${(props) =>
    getModifier(props, 'altHover') &&
    `
      &:hover,
      &:active,
      &:focus {
        border-color: transparent;
        opacity: 0.5;
      }
  `};

  /* Modifier: Large Icon */
  ${(props) =>
    getModifier(props, 'largeIcon') &&
    `
      &,
      &:link,
      &:visited {
        svg {
          height: 16px;
          vertical-align: top;
          width: 16px;
        }
      }
  `};

  /* Modifier: smallPadding */
  ${(props) =>
    getModifier(props, 'smallPadding') &&
    `
      &,
      &:link,
      &:visited {
        min-width: 0;
        padding: ${spacing.xSmall};
      }
  `};

  /* Modifier: marginBottom */
  ${(props) =>
    getModifier(props, 'marginBottom') &&
    `
      &,
      &:link,
      &:visited {
        margin-bottom: ${spacing.base};
      }
  `};

  /* Modifier: fullWidth */
  ${(props) =>
    getModifier(props, 'fullWidth') &&
    `
    &,
    &:link,
    &:visited {
      display: block;
      max-width: ${componentWidth.forms}px;
      min-width: 0;
      width: 100%;
    }
  `}

  /* Modifier: Icon Right */
  ${(props) =>
    getModifier(props, 'iconRight') &&
    `
      &,
      &:link,
      &:visited {
        svg {
          margin: 0 0 0 ${spacing.small};
        }
      }
  `};

  /* Modifier: No Border  */
  ${(props) =>
    getModifier(props, 'noBorder') &&
    `
      &,
      &:link,
      &:visited {
        border: none;
      }

      &:hover,
      &:active,
      &:focus {
        border: none;
      }
  `};
`;

export const ElementTypes = {
  button: Button.withComponent('button'),
  a: Button.withComponent('a'),
  Link: Button.withComponent(Link)
};

export default Button;
