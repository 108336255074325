// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { rgba } from 'polished';
import { getModifier } from '../../../utils/functions';
// Import elements
import Container from './Container';
import Header from './Header';
import Label from './Label';
import Legend from './Legend';
import Title from './Title';
import Total from './Total';
import Value from './Value';

// Import vars
import { colors, spacing } from '../../../utils/styles';

const Chart = styled.div`
  background: ${rgba(colors.black, 0.1)};
  height: 100%;
  padding: ${spacing.base};

  /* Modifier: storybook */
  ${(props) =>
    getModifier(props, 'storybook') &&
    `
    width: 500px;
  `};

  /* Modifier: xSmall padding right */
  ${(props) =>
    getModifier(props, 'paddingRightSmall') &&
    `
    padding-right: ${spacing.small};
  `};
`;

// Set elements
Chart.Container = Container;
Chart.Label = Label;
Chart.Legend = Legend;
Chart.Header = Header;
Chart.Title = Title;
Chart.Total = Total;
Chart.Value = Value;

export default Chart;
