import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import hasIn from 'lodash/hasIn';

const filterOptionsBySearchTerm = (searchTerm, options) => {
  const filteredBySearchTerm = groupBy(options, (option) => {
    const lowerCaseOption = option.label.toLowerCase();
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const optionIncludesSearchTerm = lowerCaseOption.includes(lowerCaseSearchTerm);
    if (!optionIncludesSearchTerm) return 'notInclude';

    const optionStartsWithSearchTerm = lowerCaseOption.startsWith(lowerCaseSearchTerm);
    if (optionStartsWithSearchTerm) return 'startsWith';

    return 'include';
  });

  let orderedOptions = [];
  if (hasIn(filteredBySearchTerm, 'startsWith'))
    orderedOptions = [...sortBy(filteredBySearchTerm.startsWith, 'label')];
  if (hasIn(filteredBySearchTerm, 'include'))
    orderedOptions = [...orderedOptions, ...sortBy(filteredBySearchTerm.include, 'label')];

  return orderedOptions;
};

export default filterOptionsBySearchTerm;
