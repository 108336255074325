// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Success = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="170" height="215" viewBox="0 0 170 215" {...props}>
    <path d="M31.2482 111.515C29.4727 116.332 23.8357 114.771 16.5563 110.176C7.85654 104.69 -3.32884 88.052 0.932259 76.5437C1.33174 75.4731 1.81999 74.4918 2.48578 73.5105C10.3866 62.0914 31.2038 99.5156 31.6921 105.27C31.9584 108.035 31.7808 110.087 31.2482 111.515Z" fill="url(#paint0_linear_261_526)" />
    <path d="M31.2482 111.515C29.4727 116.332 23.8357 114.771 16.5563 110.176C7.85654 104.69 -3.32884 88.0518 0.932259 76.5435C3.68422 87.3382 11.1855 105.002 31.2482 111.515Z" fill="#3DD5E0" />
    <path d="M14.1591 79.9782C16.0233 82.6546 18.7309 87.3828 17.8432 88.0965C16.9554 88.8102 12.3393 85.3755 9.45414 80.8703C7.50114 77.7925 5.992 73.6442 6.83534 73.0643C7.85623 72.4844 11.7178 76.499 14.1591 79.9782Z" fill="#B4E1E5" />
    <path d="M114.295 138.189C114.295 159.064 99.7364 183.598 76.5667 183.598C62.2299 183.598 50.3787 174.766 43.4988 162.945C39.2377 155.674 36.8408 147.333 36.8408 139.17C36.8408 117.759 60.0993 118.785 74.7912 118.785C90.9479 118.785 110.123 117.536 113.674 132.658C114.118 134.353 114.295 136.182 114.295 138.189Z" fill="url(#paint1_linear_261_526)" />
    <path d="M114.295 138.189C114.295 159.064 99.7364 183.598 76.5667 183.598C62.2299 183.598 50.3787 174.766 43.4988 162.945C57.88 169.77 98.8487 183.865 113.718 132.702C114.118 134.353 114.295 136.182 114.295 138.189Z" fill="#3DD5E0" />
    <path d="M44.8746 130.918C41.8563 135.869 43.9869 142.873 49.1801 145.638C54.6396 148.537 62.0522 145.95 64.0939 140.152C66.0025 134.665 62.3185 128.509 56.9921 126.77C52.6866 125.387 47.3602 126.814 44.8746 130.918Z" fill="#B4E1E5" />
    <path d="M140.572 122.8C132.404 127.795 126.324 129.134 125.436 122.131C125.303 120.926 125.303 119.499 125.436 117.759C126.013 112.005 147.185 74.8038 154.997 86.3121C156.995 89.3007 157.66 92.7799 157.35 96.393C156.462 106.786 147.673 118.428 140.572 122.8Z" fill="url(#paint2_linear_261_526)" />
    <path d="M140.572 122.8C132.405 127.795 126.324 129.134 125.436 122.131C130.807 121.105 141.548 116.287 157.35 96.393C156.462 106.786 147.674 118.428 140.572 122.8Z" fill="#3DD5E0" />
    <path d="M141.992 99.56C138.396 104.957 131.783 113.834 130.762 113.165C129.83 112.585 133.336 104.199 139.107 97.1513C142.302 93.226 146.564 89.3453 147.229 89.8806C147.984 90.505 143.101 97.9096 141.992 99.56Z" fill="white" />
    <path d="M45.7178 64.6338C44.6969 62.9834 44.0755 61.1546 43.7648 59.3257C43.0546 55.1328 44.1199 50.7614 46.8718 47.416C49.6682 44.026 53.8405 42.1971 58.1904 42.1971C59.4332 42.1971 60.676 42.3755 61.8745 42.6878C60.7648 59.5042 45.7178 64.6338 45.7178 64.6338Z" fill="url(#paint3_linear_261_526)" />
    <path d="M45.7181 64.634C44.6973 62.9835 44.0758 61.1547 43.7651 59.3259C46.1176 56.8726 48.4701 54.1962 50.867 51.386C53.5302 48.2636 55.927 45.1858 58.1907 42.2419C59.4335 42.2419 60.6764 42.4203 61.8748 42.7325C56.504 50.0033 51.1333 57.3186 45.7181 64.634Z" fill="#5FACDC" />
    <path d="M120.909 100.229C114.561 119.99 67.1123 115.351 48.6032 97.9544C43.4544 93.1369 40.5693 87.2936 41.4126 80.7365C45.2742 50.5384 71.1515 28.3693 93.3891 33.8112C101.467 35.7739 110.167 41.2158 116.337 50.0031C124.238 61.2884 128.01 78.0602 120.909 100.229Z" fill="url(#paint4_linear_261_526)" />
    <path d="M113.718 77.8374C112.83 81.4505 107.904 82.5656 101.911 82.4764C95.6085 82.3872 88.1072 80.9598 82.9583 79.6662C75.5902 77.7928 61.6528 73.1538 57.5693 67.7119C56.5928 66.4183 56.1933 65.1247 56.504 63.7866C57.4361 59.9059 60.0993 56.8281 63.6502 54.9546C67.2012 53.1258 71.6398 52.5459 76.1228 53.6164L100.979 59.7274C106.172 61.021 110.3 64.2772 112.431 68.3364C113.984 71.2803 114.517 74.5812 113.718 77.8374Z" fill="#050A0C" />
    <path d="M113.718 77.8374C112.83 81.4504 107.903 82.5656 101.911 82.4764C95.6083 82.3872 88.107 80.9598 82.9581 79.6662C75.59 77.7928 61.6526 73.1538 57.5691 67.7119C72.261 70.8789 97.6057 74.8488 112.431 68.3363C113.984 71.2803 114.517 74.5811 113.718 77.8374Z" fill="url(#paint5_linear_261_526)" />
    <path d="M120.908 100.229C114.561 119.99 67.1122 115.351 48.603 97.9544C61.1644 103.352 99.026 117.715 110.655 100.541C120.642 85.777 118.423 62.2697 116.337 50.0031C124.237 61.2884 128.01 78.0602 120.908 100.229Z" fill="#3DD5E0" />
    <path d="M62.94 47.3269C63.7389 49.2003 71.8173 47.6838 77.5875 45.275C83.1358 42.9555 89.2168 38.6734 88.551 36.8445C87.9296 35.2387 82.0705 35.8632 78.2533 36.8445C69.7311 39.0302 62.0523 45.275 62.94 47.3269Z" fill="#B4E1E5" />
    <path d="M44.697 76.0086C43.5873 81.4059 45.5847 87.3385 47.0051 87.2939C48.4699 87.2493 49.4907 80.7368 49.7127 77.0345C49.7571 75.964 50.1565 69.4962 48.6918 69.1839C47.5377 68.9163 45.4516 72.4847 44.697 76.0086Z" fill="#B4E1E5" />
    <path d="M90.2822 58.9246C90.2378 59.4152 86.6869 59.7721 83.2692 59.2368C77.6765 58.3893 73.1047 55.4453 73.3266 54.8654C73.4154 54.5978 76.8775 55.4007 83.8018 57.0511C89.3501 58.3447 90.3266 58.6569 90.2822 58.9246Z" fill="white" />
    <path d="M46.3396 50.0924C46.2064 49.9586 43.4545 46.3009 42.6555 40.3683C41.9454 34.9264 42.7887 26.4067 51.0002 16.8165L52.6869 18.2884C46.6059 25.4254 43.9427 32.7407 44.8749 39.9669C45.5407 45.3642 48.0707 48.7096 48.1151 48.7542L46.3396 50.0924Z" fill="#5FBAE9" />
    <path d="M57.1445 19.474C59.2982 17.5705 59.5086 14.2729 57.6145 12.1086C55.7204 9.94429 52.439 9.73283 50.2853 11.6363C48.1316 13.5398 47.9212 16.8374 49.8153 19.0017C51.7095 21.166 54.9908 21.3775 57.1445 19.474Z" fill="#294F80" />
    <path d="M56.3035 14.6028C56.6396 13.088 55.6901 11.5863 54.1828 11.2486C52.6755 10.9109 51.1811 11.8651 50.8451 13.3798C50.509 14.8946 51.4585 16.3963 52.9658 16.734C54.4731 17.0718 55.9675 16.1176 56.3035 14.6028Z" fill="#326AB3" />
    <path d="M79.3632 97.0626C75.8566 96.3043 73.5042 94.0294 71.995 91.4422C69.2875 86.7586 69.3318 81.0491 70.4859 81.1383C72.3057 81.3167 75.901 85.1082 80.6504 86.1341C86.1099 87.2939 90.2378 86.2233 93.0786 86.6694C93.2117 86.6694 93.3449 86.714 93.4337 86.7586C96.2744 87.3831 90.1047 99.3821 79.3632 97.0626Z" fill="#294F80" />
    <path d="M79.363 97.0625C75.8565 96.3042 73.504 94.0293 71.9949 91.4422C76.9218 91.6206 86.7312 91.3084 93.0784 86.714C93.2116 86.714 93.3447 86.7586 93.4335 86.8032C96.2742 87.3831 90.1045 99.382 79.363 97.0625Z" fill="#34487B" />
    <path d="M59.7 64.2772C60.3214 63.6081 61.0759 63.1174 61.8749 62.716C62.6738 62.3145 63.5616 62.0469 64.4493 61.8239C66.2248 61.467 68.1334 61.5116 69.9088 61.9577C71.6843 62.4037 73.371 63.2513 74.7469 64.3664C76.1673 65.4815 77.3214 66.8643 78.1203 68.4701C76.5224 67.7118 75.0577 66.9535 73.5929 66.2844C72.1282 65.6154 70.7078 65.1247 69.1986 64.7232C67.7339 64.3218 66.2248 64.0988 64.6712 64.0542C63.0289 64.0096 61.431 64.0542 59.7 64.2772Z" fill="#45ADD1" />
    <path d="M93.0342 71.5925C93.6112 70.8788 94.3658 70.3882 95.1647 69.9421C95.9637 69.4961 96.807 69.1838 97.6948 68.9608C99.4702 68.5147 101.334 68.5147 103.154 68.9162C104.93 69.3176 106.661 70.0759 108.081 71.1465C109.502 72.217 110.744 73.5106 111.588 75.1164C109.945 74.4027 108.481 73.689 106.972 73.1091C105.507 72.4847 104.042 72.0386 102.533 71.7264C101.024 71.4141 99.5146 71.2357 97.9611 71.1911C96.4075 71.1911 94.8096 71.2803 93.0342 71.5925Z" fill="#45ADD1" />
    <path d="M21.5275 51.252C21.0393 51.252 20.551 50.9398 20.3291 50.4491C20.2847 50.2707 13.6711 34.0788 2.17503 22.6151C1.68678 22.1244 1.68678 21.3215 2.17503 20.7862C2.66328 20.251 3.46223 20.2956 3.99487 20.7862C15.846 32.6068 22.4152 48.7987 22.6816 49.4678C22.9479 50.1369 22.6372 50.8505 21.9714 51.1182C21.8826 51.252 21.7051 51.252 21.5275 51.252Z" fill="#CAEAF5" />
    <path d="M34.4887 35.1045C33.8229 35.1045 33.2903 34.6138 33.2015 33.9447C33.2015 33.7663 31.7811 16.3254 24.2798 1.91774C23.9691 1.29326 24.191 0.534962 24.8125 0.178116C25.4339 -0.134125 26.1884 0.0889044 26.5435 0.713386C34.3111 15.6117 35.6871 33.008 35.7759 33.7217C35.8203 34.4354 35.2877 35.0599 34.6219 35.1045C34.5331 35.0599 34.5331 35.1045 34.4887 35.1045Z" fill="#CAEAF5" />
    <path d="M138.708 65.3475C138.308 65.3475 137.953 65.169 137.687 64.8568C137.243 64.3215 137.376 63.5186 137.909 63.0726C138.486 62.6265 152.246 51.9657 168.402 47.6836C169.068 47.5051 169.778 47.9066 169.956 48.5757C170.133 49.2448 169.734 49.9585 169.068 50.1369C153.4 54.2406 139.64 64.946 139.462 65.0798C139.24 65.2582 138.974 65.3475 138.708 65.3475Z" fill="#CAEAF5" />
    <path d="M135.557 44.7842C135.29 44.7842 135.068 44.6949 134.846 44.5611C134.269 44.1597 134.136 43.3568 134.536 42.7769C134.935 42.1524 144.878 27.8339 158.993 18.8682C159.57 18.5113 160.369 18.6898 160.768 19.2696C161.123 19.8495 160.946 20.6524 160.369 21.0539C146.698 29.752 136.755 44.1151 136.666 44.2489C136.4 44.6057 136.001 44.7842 135.557 44.7842Z" fill="#CAEAF5" />
    <path d="M69.543 17.8765L67.1033 18.55L69.9722 29.0453L72.4119 28.3718L69.543 17.8765Z" fill="#F4B25C" />
    <path d="M138.22 108.948L137.141 119.778L139.658 120.031L140.737 109.201L138.22 108.948Z" fill="#F4B25C" />
    <path d="M104.447 54.8849L98.0803 63.6888L100.127 65.1836L106.494 56.3797L104.447 54.8849Z" fill="#F4B25C" />
    <path d="M28.9255 90.3686L22.5583 99.1725L24.6049 100.667L30.972 91.8634L28.9255 90.3686Z" fill="#F4B25C" />
    <path d="M139.758 10.5971L135.79 19.4665L138.098 20.5089L142.065 11.6396L139.758 10.5971Z" fill="#E94754" />
    <path d="M152.572 79.8362L148.605 88.7055L150.913 89.7479L154.88 80.8786L152.572 79.8362Z" fill="#E94754" />
    <path d="M34.1366 41.9268L32.0547 43.3715L37.5527 51.3734L39.6347 49.9288L34.1366 41.9268Z" fill="#E94754" />
    <path d="M45.3854 104.83L43.3035 106.275L48.8015 114.277L50.8835 112.832L45.3854 104.83Z" fill="#E94754" />
    <path d="M118.975 23.1264L116.533 23.7915L119.877 36.1909L122.319 35.5258L118.975 23.1264Z" fill="#9DD092" />
    <path d="M120.624 93.8355L118.182 94.5006L121.526 106.9L123.968 106.235L120.624 93.8355Z" fill="#9DD092" />
    <path d="M86.3415 13.5337L81.7229 25.5117L84.0819 26.4303L88.7005 14.4523L86.3415 13.5337Z" fill="#9DD092" />
    <path d="M24.7075 77.6466L20.0889 89.6246L22.4479 90.5433L27.0665 78.5652L24.7075 77.6466Z" fill="#9DD092" />
    <path opacity="0.5" d="M75.7233 215C97.9575 215 115.982 210.307 115.982 204.518C115.982 198.728 97.9575 194.035 75.7233 194.035C53.4892 194.035 35.4648 198.728 35.4648 204.518C35.4648 210.307 53.4892 215 75.7233 215Z" fill="#8DEAB4" />
    <defs>
      <linearGradient id="paint0_linear_261_526" x1="0.000143752" y1="92.9293" x2="31.8395" y2="92.9293" gradientUnits="userSpaceOnUse">
        <stop offset="0.0396825" stop-color="#06CFF1" />
        <stop offset="0.0407546" stop-color="#06CFF1" />
        <stop offset="0.1607" stop-color="#37D9DB" />
        <stop offset="0.2745" stop-color="#5DE0CA" />
        <stop offset="0.3793" stop-color="#78E6BD" />
        <stop offset="0.4719" stop-color="#88E9B6" />
        <stop offset="0.5423" stop-color="#8EEAB3" />
        <stop offset="0.8004" stop-color="#B9F39F" />
        <stop offset="1" stop-color="#DEFA8E" />
      </linearGradient>
      <linearGradient id="paint1_linear_261_526" x1="36.8443" y1="151.22" x2="114.304" y2="151.22" gradientUnits="userSpaceOnUse">
        <stop offset="0.0396825" stop-color="#06CFF1" />
        <stop offset="0.0407546" stop-color="#06CFF1" />
        <stop offset="0.1607" stop-color="#37D9DB" />
        <stop offset="0.2745" stop-color="#5DE0CA" />
        <stop offset="0.3793" stop-color="#78E6BD" />
        <stop offset="0.4719" stop-color="#88E9B6" />
        <stop offset="0.5423" stop-color="#8EEAB3" />
        <stop offset="0.8004" stop-color="#B9F39F" />
        <stop offset="1" stop-color="#DEFA8E" />
      </linearGradient>
      <linearGradient id="paint2_linear_261_526" x1="125.332" y1="105.527" x2="157.436" y2="105.527" gradientUnits="userSpaceOnUse">
        <stop offset="0.0396825" stop-color="#06CFF1" />
        <stop offset="0.0733648" stop-color="#0FD1ED" />
        <stop offset="0.4151" stop-color="#67E2C5" />
        <stop offset="0.6937" stop-color="#A7EFA7" />
        <stop offset="0.8955" stop-color="#CFF795" />
        <stop offset="1" stop-color="#DEFA8E" />
      </linearGradient>
      <linearGradient id="paint3_linear_261_526" x1="43.5429" y1="53.423" x2="61.8773" y2="53.423" gradientUnits="userSpaceOnUse">
        <stop offset="0.0396825" stop-color="#06CFF1" />
        <stop offset="1" stop-color="#DEFA8E" />
      </linearGradient>
      <linearGradient id="paint4_linear_261_526" x1="41.2323" y1="73.0768" x2="124.653" y2="73.0768" gradientUnits="userSpaceOnUse">
        <stop offset="0.0396825" stop-color="#06CFF1" />
        <stop offset="1" stop-color="#DEFA8E" />
      </linearGradient>
      <linearGradient id="paint5_linear_261_526" x1="57.5541" y1="75.1236" x2="114.078" y2="75.1236" gradientUnits="userSpaceOnUse">
        <stop stop-color="#030D14" />
        <stop offset="1" stop-color="#0B2F45" />
      </linearGradient>
      <clipPath id="clip0_261_526">
        <rect width="170" height="215" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

export default Success;
