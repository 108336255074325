/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { ErrorPage, Success } from '../../components/Icons';
import { Container, LinkToHome, MessageContent, MessageTitle } from './azureAuthStyles';
import { LINK_IDENTITY_ISSUER_MUTATION } from './utils/mutations';

const AzureAuth = () => {
  const params = new URLSearchParams(document.location.search);
  const adminConsent = params.get('admin_consent');
  const tenant = params.get('tenant');
  const hasValidParams = adminConsent === 'True' && !!tenant;
  const [processStatus, setProcessStatus] = useState(null);

  const [linkIdentityIssuer] = useMutation(LINK_IDENTITY_ISSUER_MUTATION, {
    onCompleted: () => {
      setProcessStatus(true);
    },
    onError: (e) => {
      setProcessStatus(false);
    }
  });

  useEffect(async () => {
    if (adminConsent === 'True' && tenant) {
      const response = await linkIdentityIssuer({
        variables: {
          provider: 'AZURE_OAUTH',
          identityIssuerId: tenant
        }
      });

      setProcessStatus(response);
    }
  }, []);

  const getResponseText = () => {
    if (!hasValidParams)
      return (
        <>
          <ErrorPage />
          <MessageTitle>Error: Invalid Request</MessageTitle>
          <MessageContent>Please try again later or contact support.</MessageContent>
        </>
      );
    if (processStatus === null) return <MessageTitle>Processing the request...</MessageTitle>;
    if (processStatus)
      return (
        <>
          <Success />
          <MessageTitle>SSO with Azure has been set up. </MessageTitle>
          <MessageContent>
            You can now close the tab or{' '}
            <LinkToHome
              onClick={() => {
                window.location.href = '/';
              }}
            >
              go to home page
            </LinkToHome>
            .{' '}
          </MessageContent>
        </>
      );
    return (
      <>
        <ErrorPage />
        <MessageTitle>Error: Failed to link Azure</MessageTitle>
        <MessageContent>Please try again later or contact support.</MessageContent>
      </>
    );
  };

  return <Container>{getResponseText()}</Container>;
};

export default AzureAuth;
