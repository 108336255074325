import styled from 'styled-components';
import { colors, fontSize, spacing } from '../../utils/styles';

export const PageTitle = styled.p`
  font-size: ${fontSize.large};
  font-weight: 500;
  line-height: 2.25rem;
  margin-bottom: 0.75rem;
`;

export const PageText = styled.p`
  font-size: ${fontSize.base};
  line-height: 1.5rem;
  margin-bottom: ${spacing.medium};
`;

export const PageLink = styled.a`
  color: ${colors.blue500};
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
`;

export const ColorPickerSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: ${spacing.medium};
`;

export const ColorLabel = styled.label`
  margin-left: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  opacity: 0.5;
`;

export const CheckboxSection = styled.div`
  margin-top: ${spacing.base};
`;

export const ColorTitle = styled.div`
  color: ${colors.white};
  font-size: ${fontSize.xSmall};
  font-weight: 500;
  line-height: 1.125rem;
  margin-top: ${spacing.base};
  text-transform: uppercase;
`;

export const SectionMargin = styled.div`
  margin-top: ${spacing.medium};
`;
