// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';
import { $tsToFix } from '../../../utils/types';

// Import vars
import { calculateGridPercentage } from '../../global/utils';
import { breakpoint, grid } from '../../../utils/styles';

const Item = styled.li<$tsToFix>`
  ${breakpoint.medium`
      /* display: inline-block; */
      flex: ${(props: $tsToFix) =>
        `0 1 ${calculateGridPercentage(getModifier(props, 'cols'), grid.columns) || 'auto'}`};
      width: ${(props: $tsToFix) =>
        calculateGridPercentage(getModifier(props, 'cols'), grid.columns) || 'auto'};
  `};

  ${(props) =>
    getModifier(props, 'colsLarge') &&
    breakpoint.large`

      flex: ${`0 1 ${calculateGridPercentage(getModifier(props, 'colsLarge'), grid.columns)}`};
      width: ${calculateGridPercentage(getModifier(props, 'colsLarge'), grid.columns)};
  `};

  ${(props) =>
    getModifier(props, 'colsXLarge') &&
    breakpoint.xLarge`

      flex: ${`0 1 ${calculateGridPercentage(getModifier(props, 'colsXLarge'), grid.columns)}`};
      width: ${calculateGridPercentage(getModifier(props, 'colsXLarge'), grid.columns)};
  `};

  ${(props) =>
    getModifier(props, 'colsXxLarge') &&
    breakpoint.xxLarge`

      flex: ${`0 1 ${calculateGridPercentage(getModifier(props, 'colsXxLarge'), grid.columns)}`};
      width: ${calculateGridPercentage(getModifier(props, 'colsXxLarge'), grid.columns)};
  `};

  ${(props) =>
    getModifier(props, 'colsXxxLarge') &&
    breakpoint.xxxLarge`

      flex: ${`0 1 ${calculateGridPercentage(getModifier(props, 'colsXxxLarge'), grid.columns)}`};
      width: ${calculateGridPercentage(getModifier(props, 'colsXxxLarge'), grid.columns)};
  `};

  ${(props) =>
    getModifier(props, 'colsXxxxLarge') &&
    breakpoint.xxxxLarge`

      flex: ${`0 1 ${calculateGridPercentage(getModifier(props, 'colsXxxxLarge'), grid.columns)}`};
      width: ${calculateGridPercentage(getModifier(props, 'colsXxxxLarge'), grid.columns)};
  `};

  ${(props) =>
    getModifier(props, 'pdf') &&
    `
      @media print {
        display: block;
        float: left;
        margin-right: 1%;
        overflow: hidden;
        width: 32%;
      }
    `};
`;

export default Item;
