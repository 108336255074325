import styled from 'styled-components';

import { getModifier } from '../../utils/functions';
import { breakpoint, sizes, spacing } from '../../utils/styles';

import { $tsToFix } from '../../utils/types';

const StyledContainer = styled.div<$tsToFix>`
  max-width: 1400px;
  padding: 0 ${spacing.base};
  width: auto;

  ${breakpoint.medium`
    max-width: 1450px;
    max-width: calc(1400px + ${spacing.base} + ${spacing.base});
    padding: 0 ${spacing.base};
  `};

  ${breakpoint.xLarge`
    max-width: 1500px;
    max-width: calc(1400px + ${spacing.large} + ${spacing.large});
  `};

  ${breakpoint.xxxxLarge`
    max-width: 1600px;
    max-width: calc(1400px + ${spacing.xLarge} + ${spacing.xLarge});
  `};

  ${(props: $tsToFix) =>
    getModifier(props, 'fullHeight') &&
    `
      height: 100vh;
      position: relative;
    `};

  ${(props: $tsToFix) =>
    getModifier(props, 'noMaxWidth') &&
    breakpoint.medium`
      max-width: none;
    `};

  ${(props: $tsToFix) =>
    getModifier(props, 'noMaxWidth') &&
    breakpoint.xLarge`
      max-width: none;
    `};

  ${(props: $tsToFix) =>
    getModifier(props, 'noMaxWidth') &&
    breakpoint.xxxxLarge`
      max-width: none;
    `};
`;

export default StyledContainer;
