// eslint-disable-next-line import/no-unresolved
import { Root } from '@skand/webapp-manage';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { useSplitConfig } from 'splitConfig';
import { BetaReset } from 'utils/betaReset';
import { setSentryUser } from 'utils/sentry';

export const ResetPasswordBeta = () => {
  const { setKeyAndCookie } = useSplitConfig();
  const history = useHistory();

  const onLogIn = ({ accountId, userEmail }) => {
    setKeyAndCookie({ userEmail, accountId });
    setSentryUser({ userEmail, accountId });
    history.push('/');
  };

  return (
    <>
      <BetaReset />
      <Helmet>
        <title>Reset password</title>
      </Helmet>

      <Root onLogIn={onLogIn} />
    </>
  );
};
