/* eslint-disable complexity */
// Import Dependencies
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TetherComponent from 'react-tether';

// Import Utilities
import { checkUrlMatch } from '../../utils/functions';

// Import Styled Components
import StyledNav from '../../styles/components/Nav';
import StyledSubNav from '../../styles/components/SubNav';
import Tooltip from '../../styles/components/Tooltip';

const NavItem = ({ directTo, text, location, disabled, subNav, icon, children, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderAction = (ref) => {
    if (Array.isArray(directTo)) {
      return (
        <Link
          ref={ref}
          to={directTo[0]}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          {icon && icon}
          {children || text}
        </Link>
      );
    }
    if (directTo) {
      return (
        <Link
          ref={ref}
          to={directTo}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          {icon && icon}
          {children || text}
        </Link>
      );
    }

    return (
      <button
        type="button"
        ref={ref}
        onClick={() => onClick()}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        {icon && icon}
        {children || text}
      </button>
    );
  };

  const isActive = directTo && checkUrlMatch(location.pathname, directTo);
  const NavItemStyle = subNav ? StyledSubNav.Item : StyledNav.Item;

  if (subNav) {
    return (
      <NavItemStyle modifiers={{ isActive, isDisabled: disabled }}>{renderAction()}</NavItemStyle>
    );
  }

  return (
    <NavItemStyle modifiers={{ isActive, isDisabled: disabled }}>
      <TetherComponent
        attachment="middle right"
        constraints={[
          {
            to: 'scrollParent',
            attachment: 'together'
          }
        ]}
        renderTarget={(ref) => renderAction(ref)}
        renderElement={(ref) => isOpen && <Tooltip ref={ref}>{text}</Tooltip>}
      />
    </NavItemStyle>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  onClick: PropTypes.func,
  subNav: PropTypes.bool,
  text: PropTypes.node,
  directTo: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

NavItem.defaultProps = {
  children: null,
  disabled: false,
  icon: null,
  onClick: () => {},
  location: null,
  subNav: false,
  text: null,
  directTo: null
};

export default NavItem;
