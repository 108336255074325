/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@apollo/client';
import AwsS3 from '@uppy/aws-s3';
import * as Uppy from '@uppy/core';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import ImageEditor from '@uppy/image-editor';
import '@uppy/image-editor/dist/style.min.css';
import { DashboardModal } from '@uppy/react';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import { LOGO_TYPES } from 'utils/constants';
import {
  CREATE_ACCOUNT_FILES_MUTATION,
  CREATE_STORAGE_MUTATION,
  UPDATE_ACCOUNT_MUTATION
} from '../utils/mutations';
import { PRESIGNED_PUT_URL_QUERY } from '../utils/queries';

const BrandingUploader = ({
  accountId,
  logoType,
  modalOpen,
  setIsUploading,
  setModalOpen,
  setTempBannerData,
  setTempLogoData,
  setTempReportData
}) => {
  const { refetch: getPresignedPutUrl } = useQuery(PRESIGNED_PUT_URL_QUERY, { skip: true });
  const [createStorage] = useMutation(CREATE_STORAGE_MUTATION);
  const [createAccountFiles] = useMutation(CREATE_ACCOUNT_FILES_MUTATION);
  const [updateAccount] = useMutation(UPDATE_ACCOUNT_MUTATION);

  const defaultRatio = {
    report: 16 / 9,
    'share-site': 1 / 1,
    banner: NaN
  };

  const uppy = useMemo(() => {
    const aspectRatio = defaultRatio[logoType];

    return new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/jpeg', 'image/png']
      }
    })
      .use(AwsS3, {
        async getUploadParameters(file) {
          const presignedData = await getPresignedPutUrl({ fileName: file.name });
          const { key, region, bucket, method, url } = presignedData?.data?.presignedPutUrl;
          file.meta.key = key;
          file.meta.region = region;
          file.meta.bucket = bucket;
          return {
            method,
            url,
            headers: {
              'Content-Type': file.type
            }
          };
        }
      })
      .use(ImageEditor, {
        id: 'ImageEditor',
        quality: 1,
        cropperOptions: {
          viewMode: 0,
          background: false,
          autoCropArea: 1,
          responsive: true,
          aspectRatio
        },
        actions: {
          revert: false,
          rotate: false,
          flip: false,
          zoomIn: true,
          zoomOut: true,
          cropSquare: false,
          cropWidescreen: false,
          cropWidescreenVertical: false
        }
      })
      .on('complete', async (result) => {
        const { successful: files } = result;
        setIsUploading(true);
        await Promise.all(
          files.map(async (file) => {
            // Create Storage
            const {
              name,
              data: { size, type },
              meta: { key, region, bucket }
            } = file;
            const createStorageInput = { key, region, bucket, size, type, name };
            const { data: storage } = await createStorage({
              variables: { input: createStorageInput }
            });

            // Copy to permanent bucket and save as account file
            const { id } = storage?.createStorage;
            await createAccountFiles({
              variables: { storageIds: [id] }
            });

            // Update account document
            let updateAccountInput = {};
            if (logoType === LOGO_TYPES.REPORT_LOGO) {
              setTempReportData(URL.createObjectURL(file.data));
              updateAccountInput = {
                accountId,
                bannerId: id
              };
            } else if (logoType === LOGO_TYPES.SHARED_SITE_LOGO) {
              setTempLogoData(URL.createObjectURL(file.data));
              updateAccountInput = {
                accountId,
                logoId: id
              };
            } else if (logoType === LOGO_TYPES.BANNER_LOGO) {
              setTempBannerData(URL.createObjectURL(file.data));
              updateAccountInput = {
                accountId,
                bannerLogoStorageId: id
              };
            }
            await updateAccount({ variables: { input: updateAccountInput } });
          })
        );
        setIsUploading(false);
        if (logoType === LOGO_TYPES.BANNER_LOGO) window.location.reload();
      });
  }, [logoType]);

  useEffect(() => {
    return () => uppy.close();
  }, [uppy]);

  return (
    <DashboardModal
      allowMultipleUploads={false}
      allowNewUpload={false}
      autoOpenFileEditor
      closeAfterFinish
      closeModalOnClickOutside
      metaFields={[{ id: 'name', name: 'Name', placeholder: 'File name' }]}
      onRequestClose={() => setModalOpen(false)}
      open={modalOpen}
      plugins={['ImageEditor']}
      proudlyDisplayPoweredByUppy={false}
      theme="dark"
      uppy={uppy}
    />
  );
};

BrandingUploader.propTypes = {
  accountId: PropTypes.string.isRequired,
  logoType: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setIsUploading: PropTypes.func.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  setTempBannerData: PropTypes.func.isRequired,
  setTempLogoData: PropTypes.func.isRequired,
  setTempReportData: PropTypes.func.isRequired
};

export default BrandingUploader;
