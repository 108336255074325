// eslint-disable-next-line
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Chart, Loader, Notification, QuickLinks, Sidebar } from '../../components';
import { Container, PageMain, Section } from '../../skandComponents';
import { Grid, GridInner, GridItem } from '../../styles/objects';
import { getCharts, getQuickLinks, useFetchData } from './utils';

const Dashboard = ({ hasWhiteLabelingNavbar }) => {
  const { captures, reports, sites, users, isFetching, message, error } = useFetchData();

  const quickLinks = getQuickLinks();
  const charts = getCharts(captures, reports, sites, users, null);

  const renderCharts = () => {
    return (
      <div>
        <Grid>
          {charts &&
            charts.map((chart) => {
              if (!chart) return false;

              return (
                <GridItem key={chart.id} modifiers={{ cols: 6 }}>
                  <GridInner>
                    <Chart
                      actionLabel={chart.actionLabel}
                      actionUrl={chart.actionUrl}
                      cols={chart.cols}
                      data={chart.data}
                      id={chart.id}
                      indexBy={chart.indexBy}
                      isFetching={isFetching}
                      keys={chart.keys}
                      title={chart.title}
                      type={chart.type}
                      data-cy={`dashboard${chart.id}Chart`}
                    />
                  </GridInner>
                </GridItem>
              );
            })}
        </Grid>
      </div>
    );
  };

  const renderSideBar = () => {
    return (
      <Sidebar data-cy="dashboard" title="Dashboard">
        <Loader isFetching={isFetching}>
          <QuickLinks
            dataCyTestId="dashboardQuickLinks"
            links={quickLinks}
            hintContent={undefined}
            // hintContent={{
            //   title: 'Get Started',
            //   content: 'Add an organisation or user to get them started.',
            // }}
          />
        </Loader>
      </Sidebar>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <PageMain modifiers={{ hasWhiteLabelingNavbar }}>
        {renderSideBar()}
        <Container>
          <Section>
            {error && message && <Notification modifiers={{ error }}>{message}</Notification>}
            {renderCharts()}
          </Section>
        </Container>
      </PageMain>
    </div>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
