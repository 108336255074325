// Import Dependencies
import React from 'react';
// Import Styles
import Icon from '../../styles/components/Icon';


const Datasets = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path d="M9.948 6.69999C14.4301 6.69999 18.0636 5.40162 18.0636 3.79999C18.0636 2.19837 14.4301 0.899994 9.948 0.899994C5.46588 0.899994 1.8324 2.19837 1.8324 3.79999C1.8324 5.40162 5.46588 6.69999 9.948 6.69999Z" stroke="currentColor" fill="none" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.06 7.89999C18.06 9.49999 14.3952 10.8 9.89266 10.8C5.39015 10.8 1.83002 9.49999 1.83002 7.89999" stroke="currentColor" fill="none" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.06 12C18.06 13.6 14.3952 14.9 9.89266 14.9C5.39015 14.9 1.83002 13.7 1.83002 12"  stroke="currentColor" fill="none"strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.0636 16.2C18.0636 17.8 14.422 19.1 9.948 19.1C5.47402 19.1 1.8324 17.8 1.8324 16.2" stroke="currentColor" fill="none" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.0636 3.8V16.2" stroke="currentColor" fill="none" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.83002 3.8V16.2" stroke="currentColor" fill="none" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </Icon>
);

export default Datasets;
