import React from 'react';
import PropTypes from 'prop-types';

// Import components
import FilterTabsList from '../../styles/components/FilterTabs';
import FilterTabsItem from './FilterTabsItem';

const FilterTabs = ({ items, handleTabChange, activeTab }) => (
  <FilterTabsList>
    {items.map(item => (
      <FilterTabsList.Item key={item.id}>
        <FilterTabsItem
          checked={item.id === activeTab}
          handleTabChange={handleTabChange}
          id={item.id}
          label={item.label}
          name={item.id}
          value={item.id}
        />
      </FilterTabsList.Item>
    ))}
  </FilterTabsList>
);

FilterTabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeTab: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func,
};

FilterTabs.defaultProps = {
  handleTabChange: () => {},
};

export default FilterTabs;
