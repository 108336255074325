// Import Dependencies
import styled from 'styled-components';

// Import Elements
import Input from './Input';
import Label from './Label';

// Import Gobals
import { spacing } from '../../../utils/styles';

const Radio = styled.div`
  margin: ${spacing.xxSmall} 0;
  position: relative;
`;

Radio.Input = Input;
Radio.Label = Label;

export default Radio;
