// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import elements
import Item from './Item';

// Import Globals
import { spacing } from '../../../utils/styles';

const Actions = styled.ul`
  display: inline-block;

  /* Modifier: alignCenter */
  ${(props) =>
    getModifier(props, 'alignCenter') &&
    `
      display: flex;
      justify-content: center;
  `};

  /* Modifier: alignRight */
  ${(props) =>
    getModifier(props, 'alignRight') &&
    `
      display: block;
      margin-bottom: ${spacing.small};
      text-align: right;
  `};

  /* Modifier: block */
  ${(props) =>
    getModifier(props, 'stacked') &&
    `
      li {
        display: block;
        margin-bottom: ${spacing.base};
        margin-left: 0;
        margin-right: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
  `};
`;

// Set elements
Actions.Item = Item;

export default Actions;
