// Import Dependencies
import styled from 'styled-components';

// Import elements
import Item from './Item';
import Count from './Count';

// Import vars
import { spacing } from '../../../utils/styles';

const DefectList = styled.ul`
  margin: ${spacing.base} 0;
  position: relative;
`;

// Set elements
DefectList.Priority = Count;
DefectList.Count = Count;
DefectList.Item = Item;

export default DefectList;
