import { rgba } from 'polished';
import styled from 'styled-components';
import { colors, fontSize, spacing } from '../../../../utils/styles';

export const Container = styled.div`
  line-height: 0;
`;

export const Title = styled.span`
  background: ${rgba(colors.white, 0.1)};
  color: ${colors.white};
  font-size: ${fontSize.xSmall};
  font-weight: 500;
  line-height: 1.125rem;
  text-transform: uppercase;
  padding: 0 ${spacing.xSmall};
`;

export const Underline = styled.hr`
  border: 0;
  border-top: 2px solid ${colors.white};
  height: 2px;
  margin: 0 0 ${spacing.medium} 0;
  opacity: 0.2;
`;

export const LogoSection = styled.div`
  display: flex;
`;

export const Logo = styled.img`
  border-radius: 10px;
  object-fit: contain;
  height: 100%;
`;

export const LogoControlLayer = styled.div`
  align-items: center;
  background-color: ${colors.grey400};
  border-radius: 10px;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

export const LogoAction = styled.div`
  cursor: pointer;
  height: 20px;
  margin: 0 0.5rem;
  width: 20px;
`;

export const LogoContainer = styled.div`
  border-radius: 10px;
  background-color: ${colors.white};
  height: 100px;
  margin-right: ${spacing.base};
  position: relative;
  width: 100px;

  &:hover ${LogoControlLayer} {
    opacity: 0.75;
  }

  &:hover ${Logo} {
    opacity: 0.5;
  }
`;

export const LogoInfoContainer = styled.div`
  width: 325px;
`;

export const LogoTitle = styled.div`
  color: ${colors.white};
  display: flex;
  font-size: ${fontSize.xSmall};
  font-weight: 500;
  line-height: 1.125rem;
  margin-bottom: ${spacing.small};
  text-transform: uppercase;
`;

export const LogoSize = styled.p`
  color: ${colors.white};
  font-size: ${fontSize.base};
  line-height: 1.5rem;
  margin: 0;
  opacity: 0.5;
`;

export const Preview = styled.p`
  color: ${colors.white};
  cursor: pointer;
  font-size: ${fontSize.xSmall};
  font-weight: 500;
  line-height: 1.125rem;
  margin-bottom: ${spacing.small};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
`;
