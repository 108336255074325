/* eslint-disable complexity */
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Space } from '@skand/lego';
import { BetaPrimaryButtonStyles } from 'styles/components/Button';
import { Form, Loader, Notification, Title } from '../../components';
import { Container, PageMain, Section } from '../../skandComponents';
import { Grid, GridInner, GridItem } from '../../styles/objects';
import { getPasswordFields, getUserFields, useFetchData, useHandlers } from './utils';

const BetaAccount = ({ hasWhiteLabelingNavbar }) => {
  const { user, isFetching } = useFetchData();
  const {
    handleUserSubmit,
    handlePasswordSubmit,
    handleLogout,
    isProcessing,
    userMessage,

    userError
  } = useHandlers(user);

  const passwordFields = getPasswordFields();
  const userFields = getUserFields();

  const renderPersonalDetails = () => {
    return (
      <>
        <GridItem modifiers={{ cols: 6 }}>
          <GridInner>
            <Title
              type="h2"
              style={{
                fontFamily: 'Raleway, sans-serif',
                fontWeight: 600,
                fontSize: '20px',
                color: '#333234'
              }}
            >
              Signed in as
            </Title>

            <Loader isFetching={isFetching}>
              <Form
                fields={userFields}
                formData={user}
                onSubmit={handleUserSubmit}
                errorMessage={userError ? userMessage : null}
                isProcessing={isFetching}
                submitLabel="Update details"
                submitButtonProps={{ 'data-cy': 'accountUpdateButton' }}
                betaPageName="accountDetailsPage"
              />
            </Loader>
            <Space />

            {!userError && userMessage && (
              <Notification
                modifiers={{ error: false, spacingTop: true }}
                data-cy="accountUpdateMessage"
              >
                {userMessage}
              </Notification>
            )}
          </GridInner>
        </GridItem>
      </>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Account</title>
      </Helmet>

      <PageMain modifiers={{ hasWhiteLabelingNavbar, noSidebar: true }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            backgroundColor: 'white',
            color: 'black',
            overflow: 'hidden',
            padding: '16px',
            overflowY: 'auto'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: 'full',
              borderBottom: '1px solid #8A858E',
              padding: '8px 8px ',
              margin: '0 0 0 0 '
            }}
          >
            <p
              style={{
                fontFamily: 'Raleway, sans-serif',
                fontWeight: 600,
                fontSize: '28px',
                margin: '0',
                lineHeight: '36px',
                color: '#333234'
              }}
            >
              Account Details
            </p>

            <BetaPrimaryButtonStyles onClick={handleLogout}>Log Out</BetaPrimaryButtonStyles>
          </div>

          <Container>
            <Section>
              <Grid>{renderPersonalDetails()}</Grid>
            </Section>
            <Title
              type="h2"
              style={{
                fontFamily: 'Raleway, sans-serif',
                fontWeight: 600,
                fontSize: '20px',
                color: '#333234'
              }}
            >
              Manage password
            </Title>

            <Form
              fields={passwordFields}
              onSubmit={handlePasswordSubmit}
              errorMessage={false}
              isProcessing={isProcessing}
              submitButtonProps={{ 'data-cy': 'accountSubmitButton' }}
              betaPageName="accountDetailsPage"
            />
          </Container>
        </div>
      </PageMain>
    </div>
  );
};

BetaAccount.propTypes = {
  hasWhiteLabelingNavbar: PropTypes.bool
};

BetaAccount.defaultProps = {
  hasWhiteLabelingNavbar: false
};

export default BetaAccount;
