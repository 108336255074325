import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import SkandExplore from 'skand.explore/src/main/exploreWrapper';
import { PageMain, RouteLeavingGuard } from '../../skandComponents';
import { sizes } from '../../utils/styles';
import { StyledContainer } from './exploreStyles';

const Explore = ({ hasWhiteLabelingNavbar }) => {
  const history = useHistory();
  const [haveChanges, setHaveChanges] = useState(false);
  const topNavbarHeight = hasWhiteLabelingNavbar
    ? `calc(${sizes.whiteLabelingColorBar} + ${sizes.whiteLabelingContentBar})`
    : '0px';

  return (
    <StyledContainer topNavbarHeight={topNavbarHeight}>
      <Helmet>
        <title>Explore</title>
      </Helmet>
      <PageMain modifiers={{ explore: true }}>
        <SkandExplore
          setHaveChanges={setHaveChanges}
          topNavbarHeight={topNavbarHeight}
          hasWhiteLabelingNavbar={hasWhiteLabelingNavbar}
        />
      </PageMain>

      <RouteLeavingGuard
        when={haveChanges}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={(nextLocation) =>
          !!haveChanges && nextLocation.pathname !== window.location.pathname
        }
      />
    </StyledContainer>
  );
};

Explore.propTypes = {
  hasWhiteLabelingNavbar: PropTypes.bool
};

Explore.defaultProps = {
  hasWhiteLabelingNavbar: false
};

export default Explore;
