// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

const Container = styled.div`
  height: 250px;
  position: relative;

  /* Modifier: Chart sits within a max-width */
  ${(props) =>
    getModifier(props, 'contained') &&
    `
      margin: 0 auto;
      max-width: 360px;
      padding-right: 120px;
    `};

  /* Modifier: Font weight Medium */
  ${(props) =>
    getModifier(props, 'fontWeightMedium') &&
    `
      font-weight: 500;
    `};

  /* Modifier: Font weight Medium */
  ${(props) =>
    getModifier(props, 'bar') &&
    `
      rect {

        &:after {
          content: "";
          background: red;
          width: 10px;
          height: 10px;
          display: block;
        }
      }
    `};
`;

export default Container;
