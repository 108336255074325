import React from 'react';

const PageToBreak = () => {
  const a = undefined;
  console.log('a.id', a.id);
  return <p>This page is used to test if Sentry works on production</p>;
};

PageToBreak.propTypes = {};

export default PageToBreak;
