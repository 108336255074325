// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Import Components
import * as Icons from '../Icons';
import Hint from '../Hint';

// Import Styled Components
import StyledQuickLinks from '../../styles/components/QuickLinks';

// Import Prop Types
import { quickLinkPropType } from '../../propTypes';

const QuickLinks = ({ links, dataCyTestId, hintContent, ...props }) => (
  <StyledQuickLinks data-cy={dataCyTestId} {...props}>
    <StyledQuickLinks.Heading>Quick Links</StyledQuickLinks.Heading>
    {links &&
      links.map(
        (link) =>
          link && (
            <StyledQuickLinks.Item key={link.id}>
              <Link data-cy={link.dataCyTestId} to={link.url}>
                <Icons.AddHeavy /> {link.label}
              </Link>
            </StyledQuickLinks.Item>
          )
      )}
    {hintContent && (
      <StyledQuickLinks.Hint>
        <Hint {...hintContent} />
      </StyledQuickLinks.Hint>
    )}
  </StyledQuickLinks>
);

QuickLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape(quickLinkPropType)).isRequired,
  hintContent: PropTypes.shape({ title: PropTypes.string, content: PropTypes.string }),
};

QuickLinks.defaultProps = {
  hintContent: null,
};

export default QuickLinks;
