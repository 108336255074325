import PropTypes from 'prop-types';

const reportData = {
  id: PropTypes.string,
  category_id: PropTypes.string,
  first_name: PropTypes.string,
  sites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default reportData;
