// Import Dependencies
import { keyframes } from 'styled-components';

export const grow = keyframes`
  0% {
    transform: scaleY(1);
    border-radius: 100%;
  }

  1% {
    border-radius: 40%;
  }

  25% {
    transform: scaleY(4);
    border-radius: 24%;
  }

  49% {
    border-radius: 40%;
  }

  50% {
    transform: scaleY(1);
    border-radius: 100%;
  }
`;

export const placeholderShimmer = keyframes`
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
`;
