// Import Dependencies
import styled from 'styled-components';

const Header = styled.dt`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default Header;
