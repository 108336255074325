import { PATHS } from '../../../utils/constants';

const getQuickLinks = () => [
  // {
  //   id: '2',
  //   url: `${PATHS.SITES}/new`,
  //   label: 'Add Site',
  //   dataCyTestId: 'dashboardAddSitesLink',
  // },
  {
    id: '3',
    url: `${PATHS.REPORTS}/new`,
    label: 'Create Report',
    dataCyTestId: 'dashboardAddReportsLink',
  },
];

export default getQuickLinks;
