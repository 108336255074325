// Import Dependencies
import styled from 'styled-components';

// Import utilities
import { getModifier } from '../../../utils/functions';

// Import vars
import { spacing } from '../../../utils/styles';

const Icon = styled.svg`
  display: block;

  /* Modifier: storybook */
  ${(props) =>
    getModifier(props, 'storybook') &&
    `
    display: inline-block;
    height: 50px;
    margin: 0 ${spacing.base} ${spacing.base} 0;
    width: 50px;
  `};
`;

export default Icon;
