import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from '../../components/Icons';
import {
  StyledClose,
  StyledHeaderBar,
  StyledLogo,
  StyledTitle,
  StyledTitleText
} from './bubbleBoxStyles';
import { useIsBetaFeatureEnabled } from 'pages/App/utils/useIsBetaFeatureEnabled';

const BubbleBoxBar = ({ title, setIsSupportOpen }) => {
  const isBetaFeatureEnabled = useIsBetaFeatureEnabled();

  return (
    <StyledHeaderBar>
      <StyledTitle>
        <StyledLogo>{isBetaFeatureEnabled ? <Icons.LogoBeta /> : <Icons.Logo />}</StyledLogo>
        <StyledTitleText>{title}</StyledTitleText>
      </StyledTitle>

      <StyledClose onClick={() => setIsSupportOpen(false)} type="button">
        <Icons.Close />
      </StyledClose>
    </StyledHeaderBar>
  );
};

BubbleBoxBar.propTypes = {
  title: PropTypes.string
};

BubbleBoxBar.defaultProps = {
  title: ''
};

export default BubbleBoxBar;
