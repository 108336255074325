// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { rgba } from 'polished';
import { getModifier } from '../../../utils/functions';

// Import Globals
import { colors, global, spacing } from '../../../utils/styles';

const Card = styled.div`
  background: ${rgba(colors.black, 0.1)};
  border-radius: ${global.radius};
  height: 100%;
  padding: ${spacing.medium};
  width: 100%;

  @media print {
    background: transparent;
    height: auto;
    padding: 0;
  }

  /* Modifier: storybook */
  ${(props) =>
    getModifier(props, 'storybook') &&
    `
    width: 300px;
  `};

  /* Modifier: Plan Card */
  ${(props) =>
    getModifier(props, 'plan') &&
    `
    display: flex;
    flex-direction: column;
    padding: ${spacing.base} ${spacing.base} ${spacing.medium};
    text-align: center;
  `};
`;

export default Card;
