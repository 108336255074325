// Import Dependencies
import styled from 'styled-components';

// Import Components
import StyledTitle from '../../../components/Title';

import { colors } from '../../../utils/styles';

const Title = styled(StyledTitle)`
  color: ${colors.blue500};
`;

export default Title;
