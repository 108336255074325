// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import PlaceholderTableRow from '../PlaceholderTableRow';
import TableHeader from '../TableHeader';
import TableRow from '../TableRow';

// Import Styled Components
import StyledTable from '../../styles/components/Table';

const Table = ({
  cols,
  data,
  emptyMessage,
  renderTableActions,
  doubleActions,
  isFetching,
  dataCyTestId,
  ...props
}) => (
  <div>
    <StyledTable {...props}>
      <StyledTable.Inner data-cy={props.dataCyTestId}>
        {cols && !!cols.length && (
          <TableHeader
            data-cy={dataCyTestId + 'Header'}
            cols={cols}
            hasActions={!!renderTableActions}
            doubleActions={doubleActions}
            {...props}
          />
        )}
        {!isFetching && data !== null && data.length > 0 && (
          <tbody data-cy={dataCyTestId + 'Body'}>
            {data.map((item) => (
              <TableRow
                dataCyTestId={item.id + dataCyTestId}
                key={item.id || item.name}
                cols={cols}
                item={item}
                renderTableActions={renderTableActions}
                {...props}
              />
            ))}
          </tbody>
        )}

        {isFetching && (
          <tbody>
            <PlaceholderTableRow cols={cols} hasActions={!!renderTableActions} {...props} />
            <PlaceholderTableRow cols={cols} hasActions={!!renderTableActions} {...props} />
            <PlaceholderTableRow cols={cols} hasActions={!!renderTableActions} {...props} />
            <PlaceholderTableRow cols={cols} hasActions={!!renderTableActions} {...props} />
          </tbody>
        )}
      </StyledTable.Inner>
      {(!data || data.length === 0) && !isFetching && (
        <StyledTable.Message data-cy={dataCyTestId + 'Error'}>{emptyMessage}</StyledTable.Message>
      )}
    </StyledTable>
  </div>
);

Table.propTypes = {
  cols: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  emptyMessage: PropTypes.string,
  isFetching: PropTypes.bool,
  doubleActions: PropTypes.bool,
  renderTableActions: PropTypes.func,
};

Table.defaultProps = {
  data: null,
  emptyMessage: 'There is currently no data to display.',
  isFetching: false,
  doubleActions: false,
  renderTableActions: null,
};

export default Table;
