import { colors } from '../styles';

const getBarChartColor = (i) => {
  if (i === 'created') return colors.blue300;
  if (i === 'validated') return colors.blue400;
  if (i === 'published') return colors.blue500;
  return colors.blue500;
};

export default getBarChartColor;
