import Posthog from 'posthog-js';

const POSTHOG_API_KEY = process.env.REACT_APP_POSTHOG_API_KEY;
const POSTHOG_API_HOST = process.env.REACT_APP_POSTHOG_API_HOST;

export default (userData, accountData) => {
  if (process.env.NODE_ENV === 'production' && POSTHOG_API_KEY && POSTHOG_API_HOST) {
    Posthog.identify(userData.id, {
      email: userData.email,
      name: userData.displayName,
      accountId: userData.accountId
    });
    Posthog.group('account', accountData.id, {
      name: userData.account.name,
      createdAt: userData.account.createdAt,
      acquisitionChannel: accountData.acquisitionChannel
    });
  }
};
