import { gql } from '@apollo/client';

export const BRANDING_PAGE_QUERY = gql`
  query BrandingPageQuery {
    accountByContext {
      id
      bannerId
      bannerUrl
      brandingColor
      brandingStatus
      bannerLogoStorageId
      bannerLogoStorageUrl
      hasBanner
      hasSkandWatermark
      logoId
      logoUrl
    }
  }
`;

export const PRESIGNED_PUT_URL_QUERY = gql`
  query PresignedPutUrlQuery {
    presignedPutUrl {
      bucket
      key
      method
      region
      url
    }
  }
`;
