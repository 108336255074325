// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { rgba } from 'polished';
import { getModifier } from '../../../utils/functions';

// Import Globals
import forms from '../../global/forms';
import { placeholder } from '../../global/utils';
import { colors, spacing } from '../../../utils/styles';

const Inner = styled.input`
  ${forms.fieldReset};
  color: ${rgba(colors.white, 0.5)};
  padding-right: ${spacing.midLarge};

  ${placeholder`
     color:  ${rgba(colors.white, 0.5)};
  `};

  /* Modifier: hasError */
  ${(props) =>
    getModifier(props, 'hasError') &&
    `
      border-color: ${colors.error};
    `};
`;

export default Inner;
