// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Report = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <g>
      <path d="M333.3,395.8H166.7c-11.5,0-20.8-9.3-20.8-20.8s9.3-20.8,20.8-20.8h166.7c11.5,0,20.8,9.3,20.8,20.8S344.8,395.8,333.3,395.8z" />
    </g>
    <g>
      <path d="M333.3,312.5H166.7c-11.5,0-20.8-9.3-20.8-20.8s9.3-20.8,20.8-20.8h166.7c11.5,0,20.8,9.3,20.8,20.8S344.8,312.5,333.3,312.5z" />
    </g>
    <g>
      <path d="M333.3,229.2H166.7c-11.5,0-20.8-9.3-20.8-20.8s9.3-20.8,20.8-20.8h166.7c11.5,0,20.8,9.3,20.8,20.8S344.8,229.2,333.3,229.2z" />
    </g>
    <path d="M324.7,0H104.2c-23,0-41.7,18.7-41.7,41.7v416.7c0,23,18.7,41.7,41.7,41.7h291.7c23,0,41.7-18.7,41.7-41.7V112.8c0-5.5-2.2-10.8-6.1-14.7l-92-92C335.5,2.2,330.2,0,324.7,0z M375,458.3H125c-11.5,0-20.8-9.3-20.8-20.8v-375c0-11.5,9.3-20.8,20.8-20.8h187.5v62.5c0,11.5,9.3,20.8,20.8,20.8h62.5v312.5C395.8,449,386.5,458.3,375,458.3z" />
  </Icon>
);

export default Report;
