import styled from 'styled-components';
import { rgba } from 'polished';
import { baseFontFamily, fontSize, fontWeight, spacing, colors } from '../../utils/styles';

export const Title = styled.h2`
  font-family: ${baseFontFamily};
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.bold};
  line-height: 1.48;
  margin-bottom: ${spacing.base};
`;

export const MessageGrey = styled.span`
  color: ${rgba(colors.white, 0.5)};
`;
