// Import Dependencies
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Import vars
import { breakpoint, colors, componentHeight, spacing, layer } from '../../../utils/styles';

const Logo = styled(Link)`
  align-self: flex-start;
  background-color: ${colors.blue500};
  display: block;
  flex: 0 0 ${componentHeight.nav.default};
  height: ${componentHeight.nav.default};
  margin: 0 ${spacing.small} 0 0;
  overflow: hidden;
  position: relative;
  width: ${componentHeight.nav.default};
  z-index: ${layer.nav + 1};

  ${breakpoint.medium`
    align-self: flex-start;
    margin-right: 0;
  `};

  svg {
    display: block;
    height: 30px;
    margin: 16px; // Places logo in the centre of the 62 x 62 wrapper
    margin: calc((${componentHeight.nav.default} - 30px) / 2)
      calc((${componentHeight.nav.default} - 30px) / 2); // Places logo in the centre of the 62 x 62 wrapper
    width: 30px;
  }
`;

export default Logo;
