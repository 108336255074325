import { Root } from '@skand/webapp-reports';
import { LeafContainer } from 'components/LeafContainer';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BetaReset } from 'utils/betaReset';

export const ReportsBeta = ({ hasWhiteLabelingNavbar }) => {
  return (
    <LeafContainer hasWhiteLabelingNavbar={hasWhiteLabelingNavbar}>
      <BetaReset />
      <Helmet>
        <title>Reports</title>
      </Helmet>

      <Root />
    </LeafContainer>
  );
};

ReportsBeta.propTypes = {
  hasWhiteLabelingNavbar: PropTypes.bool
};

ReportsBeta.defaultProps = {
  hasWhiteLabelingNavbar: false
};
