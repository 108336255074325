import styled from 'styled-components';

import { getModifier } from '../../utils/functions';
import { componentWidth, spacing } from '../../utils/styles';

const StyledSection = styled.div`
  display: block;
  padding: ${spacing.medium} 0;

  /* Modifier: limitWidth */
  ${(props) =>
    getModifier(props, 'limitWidth') &&
    `
      max-width: ${componentWidth.forms}px;
    `};
`;

export default StyledSection;
