// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import Placeholder from '../../styles/components/Placeholder';
import StyledTable from '../../styles/components/Table';

const PlaceholderTableRow = ({ cols, hasActions, ...props }) => (
  <StyledTable.Row modifiers={{ noHover: true }}>
    {cols &&
      cols.length &&
      cols.map(col => (
        <StyledTable.Cell key={col.id} {...col} {...props}>
          <Placeholder />
        </StyledTable.Cell>
      ))}
    {hasActions && <StyledTable.Cell />}
  </StyledTable.Row>
);

PlaceholderTableRow.propTypes = {
  cols: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasActions: PropTypes.bool.isRequired,
};

export default PlaceholderTableRow;
