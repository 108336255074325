// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Componenys
import StyledTextInput, { CustomLegoTextInput } from '../../styles/components/TextInput';

const TextInput = ({ error: hasError, modifiers, ...props }) => {
  if (modifiers.lego) {
    return (<CustomLegoTextInput modifiers={{ hasError }} {...props} />);
  }

  return (
    <StyledTextInput modifiers={{ hasError }} {...props} />
  );
};

TextInput.propTypes = {
  error: PropTypes.arrayOf(PropTypes.string),
  modifiers: PropTypes.shape({}),
};

TextInput.defaultProps = {
  error: null,
  modifiers: null
};

export default TextInput;
