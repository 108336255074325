import * as Sentry from '@sentry/react';

export const setSentryUser = ({ userId, userEmail, accountId }) => {
  Sentry.setUser({ id: userId, email: userEmail });
  Sentry.setTag('accountId', accountId);
};

export const resetSentryUser = () => {
  Sentry.setUser(null);
  Sentry.setTag('accountId', null);
};
