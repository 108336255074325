import { isRequired } from '../../../utils/validators';

const getUserFields = () => [
  {
    id: 'firstName',
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    isRequired: true,
    validate: [isRequired],
    'data-cy': 'accountFirstNameField'
  },
  {
    id: 'lastName',
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    isRequired: true,
    validate: [isRequired],
    'data-cy': 'accountLastNameField'
  },
  {
    id: 'email',
    name: 'email',
    label: 'Email',
    type: 'text',
    disabled: true,
    'data-cy': 'accountEmailField'
  }
];

export default getUserFields;
