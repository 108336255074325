// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { rgba } from 'polished';
import { getModifier } from '../../../utils/functions';

// Import Elements
import Hint from './Hint';
import InlineError from './InlineError';
import InlineTooltip from './InlineTooltip';
import Label from './Label';
import RequiredStar from './RequiredStar';
import Toggle from './Toggle';
import { breakpoint, colors, componentWidth, grid, spacing } from '../../../utils/styles';

// Import Globals
import { calculateGridPercentage } from '../../global/utils';

const Field = styled.li`
  list-style: none;
  margin-bottom: ${spacing.base};
  max-width: ${componentWidth.forms + grid.gutter}px;
  padding: 0 ${grid.gutter / 2}px;
  position: relative;

  /* Modifier: hasBorderBottom */
  ${(props) =>
    getModifier(props, 'hasBorderBottom') &&
    `
      border-bottom: solid 1px ${rgba(colors.grey400, 0.8)};
      margin-bottom: ${spacing.small};
      padding-bottom: ${spacing.xSmall};
  `};

  /* Modifier: Columns */
  ${(props) =>
    getModifier(props, 'cols') &&
    breakpoint.medium`
      display: inline-block;
      max-width: none;
      padding-right: ${spacing.medium};
      vertical-align: top;
      width: ${calculateGridPercentage(getModifier(props, 'cols'), grid.columns) || 'auto'};
  `};

  /* Modifier: Columns */
  ${(props) =>
    getModifier(props, 'positionBottom') &&
    breakpoint.medium`
      position: absolute;
      bottom: 0;
  `};
`;

// Set elements
Field.InlineError = InlineError;
Field.Label = Label;
Field.RequiredStar = RequiredStar;
Field.Toggle = Toggle;
Field.InlineTooltip = InlineTooltip;
Field.Hint = Hint;

export default Field;
