// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import elements
import Item from './Item';

import { breakpoint, layer, spacing } from '../../../utils/styles';

const Nav = styled.nav`
  display: block;

  ${breakpoint.medium`
    flex: 0 0 auto;
  `};

  ul {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    z-index: ${layer.nav};

    ${breakpoint.medium`
      flex-direction: column;
      padding: ${spacing.base} 0;
    `};
  }

  /* Modifier: Primary nav list */
  ${(props) =>
    getModifier(props, 'primary') &&
    `
      ul {
        justify-content: space-around;
      }
  `};

  ${(props) =>
    getModifier(props, 'primary') &&
    breakpoint.medium`
      align-self: flex-start;
  `};

  /* Modifier: Secondary nav list */
  ${(props) =>
    getModifier(props, 'secondary') &&
    `
      flex: 1 1 auto;

      ul {
        justify-content: flex-end;
      }
  `};

  ${(props) =>
    getModifier(props, 'secondary') &&
    breakpoint.medium`
      align-self: flex-end;
      margin-top: auto;
  `};

  /* Modifier: Show on larger screens only */
  ${(props) =>
    getModifier(props, 'hideOnSmall') &&
    `
      display: none;
  `};

  ${(props) =>
    getModifier(props, 'hideOnSmall') &&
    breakpoint.medium`
      display: block;
  `};
`;

// Set elements
Nav.Item = Item;

export default Nav;
