// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Componenys
import StyledLoader from '../../styles/components/Loader';

const Loader = ({ isFetching, children, ...props }) =>
  isFetching ? (
    <StyledLoader {...props}>
      <span />
      <span />
      <span />
    </StyledLoader>
  ) : (
    children || null
  );

Loader.propTypes = {
  isFetching: PropTypes.bool,
  children: PropTypes.node,
};

Loader.defaultProps = {
  isFetching: true,
  children: null,
};

export default Loader;
