// Import Dependencies
import styled from 'styled-components';

// Import Gobals
import typography from '../../global/typography';
import { colors, fontSize, spacing } from '../../../utils/styles';

const InlineError = styled.p`
  ${typography.body};
  color: ${colors.red};
  font-size: ${fontSize.xSmall};
  margin: ${spacing.xSmall} 0 0;
`;

export default InlineError;
