export default (userData) => {
  window.Intercom('update', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'khqdidm3',
    user_id: userData.id,
    name: userData.displayName,
    email: userData.email,
    company: {
      id: userData.account?.id,
      name: userData.account?.name
    }
  });
};
