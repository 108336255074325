// Import Dependencies
import styled from 'styled-components';

// Import Elements
import Inner from './Inner';

// Import Gobals
import forms from '../../global/forms';

const SearchInput = styled.div`
  ${forms.fieldBase};
  position: relative;
`;

SearchInput.Inner = Inner;

export default SearchInput;
