// Import Dependencies
import styled from 'styled-components';

// Import vars
import { spacing } from '../../../utils/styles';

const Hint = styled.div`
  position: absolute;
  right: 0;
  top: ${spacing.xSmall};
`;

export default Hint;
