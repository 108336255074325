// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Info = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <g>
      <path d="M250,41.7c114.9,0,208.3,93.5,208.3,208.3S364.9,458.3,250,458.3S41.7,364.9,41.7,250S135.1,41.7,250,41.7 M250,0C111.9,0,0,111.9,0,250s111.9,250,250,250s250-111.9,250-250S388.1,0,250,0L250,0z" />
      <g>
        <g>
          <path d="M270.5,150.3h-41v-39.4h41V150.3z" />
        </g>
        <rect x="229.2" y="201.6" width="41.7" height="187.5" />
      </g>
    </g>
  </Icon>
);

export default Info;
