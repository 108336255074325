// Import Dependencies
import styled from 'styled-components';

import { getModifier } from '../../../utils/functions';
import { $tsToFix } from '../../../utils/types';

// Import vars
import { breakpoint, grid } from '../../../utils/styles';

const Inner = styled.div<$tsToFix>`
  padding: 0 0 ${grid.gutter}px;

  ${breakpoint.medium`
    height: 100%;
    padding: ${grid.gutter / 2}px;

    /* Modifier: Report View */
    ${(props: $tsToFix) =>
      getModifier(props, 'reportView') &&
      `
        padding: 0.75rem;
    `}
  `};

  @media print {
    height: auto;
    padding: 0;
  }
`;

export default Inner;
