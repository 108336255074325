// Import Dependencies
import styled from 'styled-components';

// Import vars
import { spacing } from '../../../utils/styles';

const Item = styled.li`
  display: inline-block;
  margin: 0 ${spacing.xSmall};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export default Item;
