import { matchPath } from 'react-router-dom';

const checkCurrentRouteInRouteList = (currentPathName, routesList) => {
  return routesList.some((route) => {
    const { path } = route;
    const matchResult = matchPath(currentPathName, { path, exact: true });
    return !!matchResult;
  });
};

export default checkCurrentRouteInRouteList;
