// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useIsBetaFeatureEnabled } from '../utils/useIsBetaFeatureEnabled';
import { Fallback } from './Fallback';

const PublicRoute = ({ component: Component, exact, path, betaComponent }) => {
  const isBetaFeatureEnabled = useIsBetaFeatureEnabled();
  const Comp = isBetaFeatureEnabled ? betaComponent ?? Fallback : Component ?? Fallback;

  return <Route exact={exact} path={path} render={() => <Comp />} />;
};

PublicRoute.propTypes = {
  component: PropTypes.func,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  betaComponent: PropTypes.node
};

PublicRoute.defaultProps = {
  component: null,
  exact: false,
  betaComponent: null
};

export default PublicRoute;
