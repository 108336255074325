import { gql } from '@apollo/client';

export const REPORT_PDF_QUERY = gql`
  query ReportPdfQuery($shareId: ID) {
    reportByShareId(shareId: $shareId) {
      id
      pdfStorage {
        signedGetObjectUrl
      }
    }
  }
`;
