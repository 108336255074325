// Import Dependencies
import styled from 'styled-components';

// Import Elements
import Actions from './Actions';
import Content from './Content';
import Header from './Header';
import Inner from './Inner';
import { layer } from '../../../utils/styles';

const Modal = styled.article`
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(33, 33, 33, 0.85);
  z-index: ${layer.modal - 1};
`;

// Set elements
Modal.Actions = Actions;
Modal.Content = Content;
Modal.Header = Header;
Modal.Inner = Inner;

export default Modal;
