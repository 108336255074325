import styled, { keyframes } from 'styled-components';
import { colors } from '../../utils/styles';

export const breath = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(0.9); }
  100% { transform: scale(1); }
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MessageTitle = styled.p`
  font-size: 2rem;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
`;

export const MessageContent = styled.p`
  color: ${colors.white}80;
  font-size: 1.5rem;
`;

export const LinkToHome = styled.span`
  color: ${colors.blue500};
  cursor: pointer;
  font-size: 1.5rem;

  &:hover {
    text-decoration: underline;
  }
`;
