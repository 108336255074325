import { PATHS } from '../../../utils/constants';

const getSubNavItems = () => [
  {
    id: 'sites',
    text: 'Projects',
    directTo: PATHS.SITES
  },
  {
    id: 'users',
    text: 'Users',
    directTo: PATHS.USERS
  },
  {
    id: 'branding',
    text: 'Branding',
    directTo: PATHS.BRANDING
  }
];

export default getSubNavItems;
