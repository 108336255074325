import { validConfirmPassword } from '../validators';

const getFieldError = (value, validate, name = '', formData = {}) => {
  const errors = validate.reduce((a, validateFunction) => {
    const newError = validateFunction(value);
    const errorMessageExist = a && a.length;
    if (!newError || errorMessageExist) {
      return a;
    }

    return [newError];
  }, null);

  const updatedErrors = validConfirmPassword(name, value, formData.password, errors);

  return updatedErrors;
};

export default getFieldError;
