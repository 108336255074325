// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { rgba } from 'polished';
import { getModifier } from '../../../utils/functions';

import { placeholderShimmer } from '../../global/animations';
import { colors, spacing } from '../../../utils/styles';

const Placeholder = styled.div`
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeholderShimmer};
  animation-timing-function: linear;
  background-color: ${rgba(colors.white, 0.5)};
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0.07) 20%,
    rgba(255, 255, 255, 0.05) 40%,
    rgba(255, 255, 255, 0.05) 100%
  );
  background-size: 1000px 100%;
  display: block;
  height: ${(props) => getModifier(props, 'height') || spacing.small};
  width: ${(props) => getModifier(props, 'width') || '100%'};
  margin: ${(props) => getModifier(props, 'marginTop') || spacing.xxSmall} auto
    ${(props) => getModifier(props, 'marginBottom') || spacing.xxSmall};
`;

export default Placeholder;
