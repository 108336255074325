import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';

import { useHandleInvalidToken } from '../../../hooks';
import { SITE_DETAIL_PAGE_QUERY, USERS_QUERY } from './queries';
import { UsersQuery } from './graphqlTypes/UsersQuery';
import {
  SiteDetailPageQuery,
  SiteDetailPageQueryVariables,
} from './graphqlTypes/SiteDetailPageQuery';

const findManagerId = (managerName, users) => {
  const managerObject = users.find((user) => `${user.firstName} ${user.lastName}` === managerName);
  return managerObject?.id;
};

const useFetchData = () => {
  const { siteId } = useParams<{ siteId: string }>();
  const { checkInvalidTokenError } = useHandleInvalidToken();

  const [
    getSiteDetail,
    {
      data: { site: siteDetail } = {
        site: { manager: '' },
      },
      error: siteDetailError,
      loading: siteDetailLoading,
    },
  ] = useLazyQuery<SiteDetailPageQuery, SiteDetailPageQueryVariables>(SITE_DETAIL_PAGE_QUERY, {
    variables: { siteId },
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      checkInvalidTokenError(e);
    },
  });

  const {
    data: { usersByAccountId: users } = {
      usersByAccountId: [],
    },
    error: usersError,
    loading: usersLoading,
  } = useQuery<UsersQuery>(USERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      checkInvalidTokenError(e);
    },
  });

  useEffect(() => {
    if (siteId) {
      getSiteDetail();
    }
  }, [siteId, getSiteDetail]);

  const managerId = findManagerId(siteDetail?.manager, users);

  const updatedSiteDetail = {
    ...siteDetail,
    managerId,
    managerName: siteDetail?.manager,
  };

  const isFetching = usersLoading || siteDetailLoading;
  const error = usersError || siteDetailError;
  const message = usersError?.message || siteDetailError?.message;
  return { siteDetail: updatedSiteDetail, users, isFetching, error, message };
};

export default useFetchData;
