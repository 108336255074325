// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

import { colors, spacing } from '../../../utils/styles';
import { getIsBetaEnabled } from 'utils/betaEnabled';

const Notification = styled.div`
  color: ${getIsBetaEnabled() ? colors.green200 : colors.success};
  margin-bottom: ${spacing.base};
  padding-left: ${spacing.small};
  position: relative;

  &:first-letter {
    text-transform: uppercase;
  }

  svg {
    fill: ${colors.success};
    height: 10px;
    left: 0;
    position: absolute;
    top: 7px;
    width: 10px;
  }

  /* Modifier: Error */
  ${(props) =>
    getModifier(props, 'error') &&
    `
    color: ${colors.error400};

    svg {
      fill: ${colors.error400};
    }
  `};

  /* Modifier: Spacing along the bottom instead of top  */
  ${(props) =>
    getModifier(props, 'spacingTop') &&
    `
    margin-bottom: 0;
    margin-top: ${spacing.base};
    `};

  /* Modifier: Remove left padding  */
  ${(props) =>
    getModifier(props, 'noLeftPadding') &&
    `
      padding-left: 0;
    `};
`;

export default Notification;
