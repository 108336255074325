import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { useHandleInvalidToken } from '../../../hooks';
import SITES_PAGE_QUERY from './queries';
import { SitesPageQuery } from './graphqlTypes/SitesPageQuery';

const useFetchData = () => {
  const { checkInvalidTokenError } = useHandleInvalidToken();

  const {
    data: { userByAccountId: sessionData, sitesByAccountId: sitesData, usersByAccountId: users } = {
      userByAccountId: { id: '' },
      usersByAccountId: [],
    },
    loading,
    error,
  } = useQuery<SitesPageQuery>(SITES_PAGE_QUERY, {
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      checkInvalidTokenError(e);
    },
  });

  const [sites, setSites] = useState<any>(null);

  useEffect(() => {
    const result = sitesData?.map((site) => {
      const buildingsCount = site?.buildingIds?.length;
      return {
        id: site?.id,
        name: site?.name,
        manager: site?.manager,
        code: site?.code,
        buildingsCount,
        accountId: site?.accountId,
      };
    });
    setSites(result);
  }, [sitesData]);

  return {
    sessionData,
    sites,
    users,
    isFetching: loading,
    error,
    message: error?.message,
  };
};

export default useFetchData;
