import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Modal from '../Modal';

class Portal extends React.Component {
  constructor(props) {
    super(props);

    this.rootSelector = document.getElementById('modal');
    this.container = document.createElement('div');
  }

  componentDidMount() {
    this.rootSelector.appendChild(this.container);
  }

  componentWillUnmount() {
    this.rootSelector.removeChild(this.container);
  }

  render() {
    const { renderModalContent, children } = this.props;

    return ReactDOM.createPortal(
      <Modal {...this.props}>
        {renderModalContent && renderModalContent()}
        {children}
      </Modal>,
      this.container,
    );
  }
}

Portal.propTypes = {
  renderModalContent: PropTypes.func,
  children: PropTypes.node,
};

Portal.defaultProps = {
  renderModalContent: null,
  children: null,
};

export default Portal;
