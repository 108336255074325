/* eslint-disable no-param-reassign */
import moment from 'moment';
import * as types from './graphqlTypes/DashboardQuery';

const getPastSixMonth = () => {
  const pastSixMonthNames: { monthYear: string; monthName: string }[] = [];
  for (let i = 5; i >= 0; i -= 1) {
    const monthYear: string = moment().subtract(i, 'months').format('MMM YY');
    const monthName: string = moment().subtract(i, 'months').format('MMM');
    pastSixMonthNames.push({ monthYear, monthName });
  }
  return pastSixMonthNames;
};

const getScannedData = (captures: types.DashboardQuery['capturesByAccountId']) => {
  const pastSixMonth = getPastSixMonth();

  const scannedData = pastSixMonth.map(({ monthYear, monthName }) => {
    const metersScannedThisMonth = captures?.reduce(
      (result: number, capture: types.DashboardQuery_capturesByAccountId | null) => {
        const captureCreatedMonthYear =
          capture?.captureDate && moment(capture.captureDate).format('MMM YY');

        if (captureCreatedMonthYear === monthYear) {
          const squareMeters =
            capture?.site?.buildings?.reduce(
              (
                sum: number,
                building: types.DashboardQuery_capturesByAccountId_site_buildings | null
              ) => (sum += building?.squareMeters || 0),
              0
            ) || 0;

          result += squareMeters;
        }

        return result;
      },
      0
    );
    const metersInThousands: any = metersScannedThisMonth && metersScannedThisMonth / 1000;

    return {
      id: monthName,
      date: monthName,
      'Meters Scanned': metersInThousands,
    };
  });

  return scannedData;
};

export default getScannedData;
