import { gql } from '@apollo/client';

const USER_PAGE_QUERY = gql`
  query UserPageQuery {
    usersByAccountId {
      id
      firstName
      lastName
      email
      lastLoginAt
    }
  }
`;

const USER_DETAIL_QUERY = gql`
  query UserDetailQuery($userId: String!) {
    user(userId: $userId) {
      id
      email
      firstName
      lastName
    }
  }
`;
export { USER_PAGE_QUERY, USER_DETAIL_QUERY };
