// Import Dependencies
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TetherComponent from 'react-tether';

// Import Components
import * as Icons from '../Icons';

// Import Styled Components
import StyledHint from '../../styles/components/Hint';

export class Hint extends PureComponent {
  state = {
    isOpen: false,
  };

  componentWillMount() {
    document.body.addEventListener('click', this.closeHint);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.closeHint);
  }

  setRef = (element) => {
    this.button = element;
  };

  toggleHint = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  closeHint = (e) => {
    if (e.target === this.button) return;

    this.setState({
      isOpen: false,
    });
  };

  render() {
    const { title, content } = this.props;
    const { isOpen } = this.state;

    return (
      <StyledHint innerRef={this.setRef} onClick={this.toggleHint} type="button">
        <TetherComponent
          attachment="top left"
          targetAttachment="top right"
          constraints={[
            {
              to: 'window',
              attachment: 'together',
            },
          ]}
          renderTarget={(ref) => (
            <div ref={ref}>
              <Icons.Info />
            </div>
          )}
          renderElement={(ref) =>
            isOpen && (
              <StyledHint.Info ref={ref}>
                <StyledHint.Title>{title}</StyledHint.Title>
                {content}
                <StyledHint.Close onClick={this.toggleHint} type="button">
                  <Icons.Close />
                </StyledHint.Close>
              </StyledHint.Info>
            )
          }
        ></TetherComponent>
      </StyledHint>
    );
  }
}

Hint.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

Hint.defaultProps = {
  title: '',
  content: '',
};

export default Hint;
