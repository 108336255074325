// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import vars
import { breakpoint, spacing } from '../../../utils/styles';

const Legend = styled.dl`
  display: inline-block;
  margin: 0;
  position: absolute;
  text-align: left;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  width: ${spacing.xLarge};

  /* Modifier: legend aligned top right of container */
  ${(props) =>
    getModifier(props, 'topRight') &&
    `
      display: flex;
      left: 0;
      top: 0;
      transform: translate(-10px, -40px);
      width: auto;
  `};

  ${(props) =>
    getModifier(props, 'topRight') &&
    breakpoint.xLarge`
      left: auto;
      right: 0;
      transform: translate(0, -66px); // Height of heading
    `};
`;

export default Legend;
