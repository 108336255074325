import React from 'react';
import { componentHeight, sizes } from 'utils/styles';
import PropTypes from 'prop-types';

export const LeafContainer = ({ hasWhiteLabelingNavbar, children }) => {
  const offsetLeft = componentHeight.nav.default;
  const offsetTop = hasWhiteLabelingNavbar
    ? `calc(${sizes.whiteLabelingColorBar} + ${sizes.whiteLabelingContentBar})`
    : '0px';

  return (
    <div
      style={{
        marginLeft: offsetLeft,
        display: 'flex',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        height: `calc(100dvh - ${offsetTop} - 1px)`,
        width: `calc(100dvw - ${offsetLeft})`
      }}
    >
      {children}
    </div>
  );
};

LeafContainer.propTypes = {
  children: PropTypes.node,
  hasWhiteLabelingNavbar: PropTypes.bool
};

LeafContainer.defaultProps = {
  children: null,
  hasWhiteLabelingNavbar: false
};
