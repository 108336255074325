import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

class FilterTabsItem extends PureComponent {
  handleTabChange = () => {
    this.props.handleTabChange(this.props.id);
  };

  render() {
    const {
      label, id, name, checked,
    } = this.props;

    return (
      <Fragment>
        <input type="radio" id={id} value={id} name={name} onChange={this.handleTabChange} checked={checked} />
        <label htmlFor={id}>{label}</label>
      </Fragment>
    );
  }
}

FilterTabsItem.propTypes = {
  label: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};

FilterTabsItem.defaultProps = {
  checked: false,
};

export default FilterTabsItem;
