// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Delete = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
     <g fill="none" fill-rule="evenodd" stroke="#FFF" stroke-linecap="round">
        <path stroke-linejoin="round" d="M9.027 7.989L9.027 16.593M12.258 7.989L11.424 16.593M5.796 7.989L6.629 16.593" transform="translate(1)"/>
        <path stroke-width="1.685" d="M.842 6.408h0c0-1.461 1.196-2.656 2.657-2.656h11.055c1.462 0 2.657 1.195 2.657 2.656M15.021 8l-1 9.132c0 1.099-.818 2-1.819 2h-6.35c-1.001 0-1.82-.901-1.82-2L3.032 8M10.481 2.297c0 .804-.651 1.455-1.454 1.455-.803 0-1.455-.651-1.455-1.455 0-.803.652-1.454 1.455-1.454s1.454.65 1.454 1.454z" transform="translate(1)"/>
    </g>
  </Icon>
);

export default Delete;
