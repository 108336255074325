// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import Globals
import { componentWidth, spacing } from '../../../utils/styles';

const Section = styled.div`
  display: block;
  padding: ${spacing.medium} 0;

  /* Modifier: limitWidth */
  ${(props) =>
    getModifier(props, 'limitWidth') &&
    `
      max-width: ${componentWidth.forms}px;
    `};
`;

export default Section;
