// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import Gobals
import forms from '../../global/forms';
import { placeholder } from '../../global/utils';
import { colors } from '../../../utils/styles';

const TextArea = styled.textarea`
  ${forms.fieldReset};
  ${forms.fieldBase};
  resize: vertical;

  ${placeholder`
     color: ${colors.grey};
  `};

  /* Modifier: hasError */
  ${(props) =>
    getModifier(props, 'hasError') &&
    `
    border-color: ${colors.error};
  `};
`;

export default TextArea;
