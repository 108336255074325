import { rgba } from 'polished';

import { colors } from '../../utils/styles';

export const getCustomStyles = (modifiers) => ({
  control: (styles, { isDisabled, isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? colors.inputBackground : colors.dropdownBackground,
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    color: !isFocused ? colors.white : `${rgba(colors.white, 0.5)}`,
    cursor: 'pointer',
    opacity: isDisabled ? 0.5 : 1,
    width: modifiers.detailLayer && '12rem',
  }),
  input: (styles) => ({
    ...styles,
    color: colors.white,
    fontSize: '1rem',
    margin: 0,
    padding: 0,
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: colors.greyCharcoal,
    border: 'none',
    borderRadius: 0,
    boxShadow: `0 2px 6px 0 ${colors.greyCharcoal}`,
    color: `${rgba(colors.white, 0.5)}`,
    padding: '0 0 0.25rem 0',
    top: '2.5rem',
  }),
  menuList: (styles) => ({
    ...styles,
    '::-webkit-scrollbar': {
      position: 'absolute',
      right: '2rem',
      width: '0.5rem',
    },
    '::-webkit-scrollbar-track': {
      opacity: 0,
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.greyShark,
      borderRadius: '1rem',
      opacity: 0.5,
    },
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: colors.greyCharcoal,
    color: isFocused && colors.white,
    ':active': {
      ...styles[':active'],
      backgroundColor: colors.greyCharcoal,
    },
    padding: '0.5rem 1rem 0.125rem',
  }),
  placeholder: (styles, { isFocused }) => ({
    ...styles,
    color: isFocused ? colors.inputBackground : `${rgba(colors.white, 0.5)}`,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '0.75rem 1rem',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: 'calc(0.75rem - 8px) calc(1rem - 8px)',
  }),
  dropdownIndicator: (styles, { isFocused }) => ({
    ...styles,
    color: isFocused ? colors.inputBackground : `${rgba(colors.white, 0.5)}`,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    backgroundColor: 'none',
    margin: 0,
    padding: 0,
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    textAlign: 'left',
    padding: '0.5rem 1rem 0.125rem',
  }),
});
