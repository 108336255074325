// Import Dependencies
import styled from 'styled-components';

// Import Elements
import FilterTabsItem from './FilterTabsItem';
import { colors, spacing } from '../../../utils/styles';

const FilterTabsList = styled.ul`
  border-bottom: 1px solid ${colors.grey300};
  display: flex;
  margin-bottom: ${spacing.medium};
  padding-left: 0;
  position: relative;
`;

FilterTabsList.Item = FilterTabsItem;

export default FilterTabsList;
