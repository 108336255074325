// Import Dependencies
import styled from 'styled-components';

// Import vars
import typography from '../../global/typography';

// Import Utilities
import { getModifier } from '../../../utils/functions';
import { componentWidth, spacing } from '../../../utils/styles';

const Title = styled.h3`
  ${typography.headingBase};
  margin-bottom: ${spacing.base};

  /* Modifier: H1 */
  ${(props) =>
    getModifier(props, 'type') === 'h1' &&
    `
      ${typography.headingXLarge};
  `};

  /* Modifier: H2 */
  ${(props) =>
    getModifier(props, 'type') === 'h2' &&
    `
      ${typography.headingLarge};
  `};

  /* Modifier: H3 */
  ${(props) =>
    getModifier(props, 'type') === 'h3' &&
    `
    ${typography.headingMedium};
  `};

  /* Modifier: centered */
  ${(props) =>
    getModifier(props, 'centered') &&
    `
    text-align: center;
  `};

  /* Modifier: normalWeight */
  ${(props) =>
    getModifier(props, 'normalWeight') &&
    `
    font-weight: normal;
  `};

  /* Modifier: marginTop */
  ${(props) =>
    getModifier(props, 'marginTop') &&
    `
    margin-top: ${spacing.base};
  `};

  /* Modifier: smallMarginBottom */
  ${(props) =>
    getModifier(props, 'smallMarginBottom') &&
    `
    margin-bottom: ${spacing.xSmall};
  `};

  /* Modifier: limitWidth */
  ${(props) =>
    getModifier(props, 'limitWidth') &&
    `
    max-width: ${componentWidth.forms}px;
  `};
`;

export default Title;
