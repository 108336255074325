import { rgba } from 'polished';
import { baseFontSize, colors, spacing } from '../../utils/styles';

const forms = {
  fieldReset: `
    appearance: none;
    background-color: transparent;
    background-image: none;
    border-radius: 0;
    border: none;
    border: solid 1px transparent;
    box-shadow: none;
    color: ${colors.white};
    font-size: ${baseFontSize};
    line-height: 1;
    // 14px padding added to make the field height 48px
    padding: 14px;
    position: relative;
    width: 100%;

    &:focus {
      border-color: ${rgba(colors.white, 0.4)};
    }
  `,
  fieldBase: `
    background: ${rgba(colors.grey400, 0.5)};

    &:disabled {
      opacity: 0.5;
    }

    &:-webkit-autofill {
      -webkit-animation-fill-mode: both;
    }

    svg {
      fill:${rgba(colors.white, 0.5)};
      height: 20px;
      pointer-events: none;
      position: absolute;
      right: ${spacing.small};
      top: 50%;
      transform: translate(0, -50%);
      width: 20px;
    }
  `,
  rangeTrack: `
    -webkit-appearance: none;
    background: ${colors.white};
    border-radius: 0;
    border: none;
    box-shadow: none;
    cursor: pointer;
    height: 1px;
    width: 100%;
  `,
  rangeThumb: `
    -webkit-appearance: none;
    background: ${colors.orange};
    border-radius: 0;
    border: none;
    box-shadow: none;
    cursor: pointer;
    height: 9px;
    margin-top: -4px;
    width: 9px;
  `,
};

export default forms;
