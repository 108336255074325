// Import Dependencies
import styled from 'styled-components';

// Import elements
import { rgba } from 'polished';
import Item from './Item';
import Header from './Header';
import Value from './Value';

// Import vars
import { colors, spacing } from '../../../utils/styles';

const MetaTable = styled.ul`
  border-bottom: solid 1px ${rgba(colors.grey400, 0.8)};
  list-style: none;
  margin-bottom: ${spacing.base};
`;

// Set elements
MetaTable.Item = Item;
MetaTable.Header = Header;
MetaTable.Value = Value;

export default MetaTable;
