import { formatDate } from '../../../utils/functions';

const getTableCols = () => [
  { id: '1', label: 'First Name', key: 'firstName' },
  { id: '2', label: 'Last Name', key: 'lastName' },
  { id: '3', label: 'Email Address', key: 'email' },
  {
    id: '4',
    label: 'Last Login',
    key: 'lastLoginAt',
    transformer: formatDate
  }
];

export default getTableCols;
