import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, theme } from '@skand/lego';
import { ThemeProvider } from 'styled-components';

const LeaveModal = ({ open, onCancel, onConfirm }) => {
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onNo={onCancel}
        onYes={onConfirm}
        title="LEAVE CONFIRMATION"
        message="Are you sure you want to leave this page?"
        messageSecondary="You have unsaved changes. Changes you have made will be lost!"
      />
    </ThemeProvider>
  );
};

LeaveModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default LeaveModal;
