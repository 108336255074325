// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useCallback } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PATHS } from 'utils/constants';
import { checkAuthentication } from '../utils';
import { useIsBetaFeatureEnabled } from '../utils/useIsBetaFeatureEnabled';

export const Fallback = () => {
  const isUserAuthenticated = checkAuthentication();
  const isWebappBetaEnabled = useIsBetaFeatureEnabled();

  const handleRedirect = useCallback(() => {
    if (isUserAuthenticated) {
      return <Redirect to={isWebappBetaEnabled ? PATHS.EXPLORE_BETA_PROJECT : PATHS.ROOT} />;
    }

    return <Redirect to={PATHS.LOGIN} />;
  }, [isWebappBetaEnabled, isUserAuthenticated]);

  return <Route component={() => handleRedirect()} />;
};
