import styled from 'styled-components';
import { getModifier } from '../../utils/functions';
import { breakpoint, componentHeight, componentWidth, sizes } from '../../utils/styles';

const StyledPageMain = styled.main`
  height: 100vh;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: ${componentHeight.nav.default} 0;
  position: relative;

  ${breakpoint.medium`
    margin-right: auto;
    padding-bottom: 0;
    padding-left: 338px;
    padding-left: calc(${componentWidth.nav.medium} + ${componentWidth.sidebar.medium});
    padding-top: 0;
  `};

  ${breakpoint.xxLarge`
    padding-left: 412px;
    padding-left: calc(${componentWidth.nav.xxLarge} + ${componentWidth.sidebar.xxLarge});
  `};

  ${breakpoint.xxxLarge`
    padding-left: 412px;
    padding-left: calc(${componentWidth.nav.xxxLarge} + ${componentWidth.sidebar.xxxLarge});
  `};

  /* Modifier: noSidebar */
  ${(props) =>
    getModifier(props, 'noSidebar') &&
    breakpoint.medium`
      padding-left: ${componentWidth.nav.medium};
  `};

  ${(props) =>
    getModifier(props, 'noSidebar') &&
    breakpoint.xxLarge`
      padding-left: ${componentWidth.nav.xxLarge};
  `};

  ${(props) =>
    getModifier(props, 'noSidebar') &&
    breakpoint.xxxLarge`
      padding-left: ${componentWidth.nav.xxxLarge};
  `};

  /* Modifier: defectBar */
  ${(props) =>
    getModifier(props, 'defectBar') &&
    breakpoint.medium`
    padding-left: calc(${componentWidth.nav.medium} + ${componentWidth.sidebar.medium} + ${componentWidth.defectBar.medium});
  `};

  ${(props) =>
    getModifier(props, 'defectBar') &&
    breakpoint.xxLarge`
    padding-left: calc(${componentWidth.nav.xxLarge} + ${componentWidth.sidebar.xxLarge} + ${componentWidth.defectBar.xxLarge});
  `};

  ${(props) =>
    getModifier(props, 'defectBar') &&
    breakpoint.xxxLarge`
      padding-left: calc(${componentWidth.nav.xxxLarge} + ${componentWidth.sidebar.xxxLarge} + ${componentWidth.defectBar.xxxLarge});
  `};

  /* Modifier: narrow (no app header) */
  ${(props) =>
    getModifier(props, 'narrow') &&
    `
      margin: 0 auto;
      max-width: ${componentWidth.forms}px;
      padding-bottom: 0;
      padding-top: 0;
  `};

  ${(props) =>
    getModifier(props, 'narrow') &&
    breakpoint.medium`
      padding-left: 0;
  `};

  ${(props) =>
    getModifier(props, 'narrow') &&
    breakpoint.xxLarge`
      padding-left: 0;
  `};

  ${(props) =>
    getModifier(props, 'narrow') &&
    breakpoint.xxxLarge`
      padding-left: 0;
  `};

  /* Modifier: explore */
  ${(props) =>
    getModifier(props, 'explore') &&`
    height: 100%;
  `};

  ${(props) =>
    getModifier(props, 'explore') &&
    (breakpoint.medium || breakpoint.xxLarge || breakpoint.xxxLarge || breakpoint.xxxxLarge)`
    margin: 0 0 0 ${componentHeight.nav.default};
    padding-left: 0;
  `};

  /* Modifier: datasets */
  ${(props) =>
    getModifier(props, 'datasets') &&
    (breakpoint.medium || breakpoint.xxLarge || breakpoint.xxxLarge || breakpoint.xxxxLarge)`
    background-image: radial-gradient(circle at 0 0, #484f60, #20232a 100%);
    height: calc(100vh - ${getModifier(props, 'topNavbarHeight')});
    margin: ${getModifier(props, 'hasSideNavbar') ? `0 0 0 ${componentHeight.nav.default}` : `0`};
    padding-left: 0;
  `};

  /* Modifier: Ops */
  ${(props) =>
    getModifier(props, 'operations') &&
    (breakpoint.medium || breakpoint.xxLarge || breakpoint.xxxLarge || breakpoint.xxxxLarge)`
    background-image: radial-gradient(circle at 0 0, #484f60, #20232a 100%);
    height: calc(100vh - ${getModifier(props, 'topNavbarHeight')});
    margin: ${getModifier(props, 'hasSideNavbar') ? `0 0 0 ${componentHeight.nav.default}` : `0`};
    padding-left: 0;
  `};

  /* Modifier: hasWhiteLabelingNavbar */
  ${(props) =>
    getModifier(props, 'hasWhiteLabelingNavbar') &&
    `
    height: calc(100vh - ${sizes.whiteLabelingColorBar} - ${sizes.whiteLabelingContentBar} - 1px);
  `};
`;

export default StyledPageMain;
