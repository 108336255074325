import styled from 'styled-components';

export const StyledContainer = styled.div`
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};

  .rc-color-picker-wrap {
    display: flex;
    align-items: center;
  }
  .rc-color-picker-trigger {
    box-shadow: none;
  }
`;
