// Import Dependencies
import styled from 'styled-components';

// Import vars
import { rgba } from 'polished';
import typography from '../../global/typography';
import { colors } from '../../../utils/styles';

const Header = styled.h4`
  color: ${rgba(colors.white, 0.5)};
  font-size: ${typography.smallCaps};
  margin: 0 1.5rem 0 0;
`;

export default Header;
