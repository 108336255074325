// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { rgba } from 'polished';
import { getModifier } from '../../../utils/functions';

// Import vars
import { breakpoint, colors, global, spacing } from '../../../utils/styles';

const Item = styled.li`
  margin-bottom: ${spacing.xSmall};
  padding-right: ${spacing.base};
  position: relative;

  a {
    &,
    &:link,
    &:visited {
      color: ${rgba(colors.white, 0.5)};
    }

    &:hover {
      color: ${colors.white};
    }
  }

  &:before {
    background-color: ${colors.white};
    bottom: auto;
    content: '';
    display: block;
    height: 32px;
    left: auto;
    position: absolute;
    right: -${spacing.base};
    top: 50%;
    transition: transform 200ms ${global.easing} 0s;
    transform: translateY(-50%) scaleY(0);
    width: 4px;

    ${breakpoint.medium`
      right: -${spacing.midLarge}; // The size of spacing on the right of containing sidebar
    `};
  }

  /* Modifier: is Active */
  ${(props) =>
    getModifier(props, 'isActive') &&
    `
    &:before {
      transform: translateY(-50%) scaleY(1);
    }

    a {
      &,
      &:link,
      &:visited {
        color: ${colors.white};
      }
    }
`};
`;
export default Item;
