/* eslint-disable @typescript-eslint/no-empty-function */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useRouteMatch } from 'react-router-dom';
import { Button, FilterBar, Notification, Portal, SubNav, Table, Title } from '../../components';
import { Confirmation, PageMain } from '../../skandComponents';
import { Actions, StyledTable } from '../../styles/components';
import { PATHS } from '../../utils/constants';
import { checkUrlMatch, sortListByLowercaseName } from '../../utils/functions';
import { UserDetail } from './components';
import { InviteButtonHoverMessage } from './usersStyles';
import {
  getSubNavItems,
  getTableCols,
  nameOrEmailIncludes,
  useFetchData,
  useHandlers
} from './utils';

const Users = ({ hasWhiteLabelingNavbar }) => {
  const match = useRouteMatch();
  const { userId } = useParams();
  const subNavItems = getSubNavItems();
  const tableCols = getTableCols();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isInviteButtonHovered, setIsInviteButtonHovered] = useState(false);
  const { users, userDetail, isFetching, error, message } = useFetchData();
  const {
    // handleInvite,
    // handleEdit,
    handleHideModal,
    // handleDeleteConfirmation,
    handleDelete
  } = useHandlers(setConfirmationOpen);

  const renderActions = () => {
    return (
      <>
        {/* <Button
          onClick={handleInvite}
          modifiers={{ fullWidth: true, lego: true }}
          data-cy="usersInviteButton"
        >
          Invite a user
        </Button> */}

        <Button
          onClick={() => {}}
          onMouseEnter={() => setIsInviteButtonHovered(true)}
          onMouseLeave={() => setIsInviteButtonHovered(false)}
          modifiers={{ fullWidth: true, disabled: true, lego: true }}
        >
          Invite a user
        </Button>

        <InviteButtonHoverMessage isInviteButtonHovered={isInviteButtonHovered}>
          To add a user into your account, please reach out to your Skand account manager.
        </InviteButtonHoverMessage>
      </>
    );
  };

  const renderTableActions = () => {
    return (
      <StyledTable.Cell modifiers={{ actions: true }}>
        <Actions>
          {/* <Actions.Item>
            <TableAction
              id={item.id}
              label={<Icons.Edit />}
              action={handleEdit}
              modifiers={{ iconOnly: true }}
              data-cy="usersEditButton"
            />
          </Actions.Item> */}
          {/* <Actions.Item>
            <TableAction
              id={item.id}
              label={<Icons.Close />}
              action={handleDeleteConfirmation}
              modifiers={{ iconOnly: true }}
            />
          </Actions.Item> */}
        </Actions>
      </StyledTable.Cell>
    );
  };

  // eslint-disable-next-line react/prop-types
  const renderBody = ({ data }) => {
    const sortedUsers = sortListByLowercaseName(data);

    return (
      <div>
        <Title type="h3" data-cy="usersTitle">
          Users
        </Title>
        {message && <Notification modifiers={{ error }}>{message}</Notification>}
        <Table
          dataCyTestId="usersTable"
          cols={tableCols}
          data={sortedUsers}
          renderTableActions={renderTableActions}
          emptyMessage="There are no users to display."
          isFetching={isFetching}
        />
      </div>
    );
  };

  const renderModal = () => {
    const { path } = match;
    const modalActive = userId !== undefined || checkUrlMatch(path, `${PATHS.USERS}/new`);
    const title = `${userId ? 'Edit' : 'Invite'} user`;

    return (
      <Portal title={title} open={modalActive} onClose={handleHideModal}>
        <UserDetail
          userDetail={userDetail}
          isFetching={isFetching}
          error={error}
          message={message}
        />
      </Portal>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <PageMain modifiers={{ hasWhiteLabelingNavbar }}>
        <FilterBar
          render={renderBody}
          renderActions={renderActions}
          filterFunction={nameOrEmailIncludes}
          placeholder="Name"
          data={users}
          isFetching={isFetching}
          dataCyTestId="usersSearchBar"
        >
          <Title type="h2">Manage</Title>
          <SubNav items={subNavItems} modifiers={{ borderBottom: true }} />
        </FilterBar>
      </PageMain>
      {renderModal()}
      <Confirmation
        open={confirmationOpen}
        setOpen={setConfirmationOpen}
        message="Are you sure you want to delete this user?"
        submitAction={handleDelete}
      />
    </div>
  );
};

Users.propTypes = {
  hasWhiteLabelingNavbar: PropTypes.bool
};

Users.defaultProps = {
  hasWhiteLabelingNavbar: false
};

export default Users;
