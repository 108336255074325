// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const ArrowLeftHeavy = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path d="M464.2,214.2H122.3l105.5-105.5c14-14,14-36.7,0-50.7c-14-14-36.7-14-50.7,0L10.5,224.7C3.7,231.4,0,240.4,0,250s3.7,18.6,10.5,25.3L177.2,442c6.8,6.8,15.8,10.5,25.3,10.5s18.6-3.7,25.3-10.5c14-14,14-36.7,0-50.7L122.3,285.8h341.8c19.8,0,35.8-16.1,35.8-35.8S483.9,214.2,464.2,214.2z" />
  </Icon>
);

export default ArrowLeftHeavy;
