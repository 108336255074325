import { gql } from '@apollo/client';

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($resetPasswordToken: String!, $password: String!) {
    resetPassword(resetPasswordToken: $resetPasswordToken, password: $password) {
      email
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      bearerToken
      email
      accountId
    }
  }
`;
