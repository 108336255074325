import React from 'react';
import PropTypes from 'prop-types';

import StyledPageMain from './pageMainStyles';

const PageMain = ({ children, modifiers }) => (
  <StyledPageMain modifiers={modifiers}>{children}</StyledPageMain>
);

PageMain.propTypes = {
  children: PropTypes.node,
  modifiers: PropTypes.shape(),
};

PageMain.defaultProps = {
  children: null,
  modifiers: null,
};

export default PageMain;
