import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Title,
  IconButton,
  InfoTooltip,
  InfoText,
  InfoDismiss
} from './logoTitleStyles';
import * as Icons from '../../../../components/Icons';

const LogoTitle = ({ hasTooltip, title, tooltipTexts }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleTooltipClick = () => {
    setShowTooltip(!showTooltip);
  };

  const handleTooltipDismiss = () => {
    setShowTooltip(false);
  };

  return (
    <Container>
      <Title>{`${title}`}</Title>
      {hasTooltip && (
        <IconButton onClick={handleTooltipClick}>
          <Icons.InfoSolid style={{ paddingTop: 3 }} />
        </IconButton>
      )}

      {showTooltip && (
        <InfoTooltip>
          {tooltipTexts.map((text) => (
            <InfoText>{text}</InfoText>
          ))}
          <InfoDismiss onClick={handleTooltipDismiss}>Dismiss</InfoDismiss>
        </InfoTooltip>
      )}
    </Container>
  );
};

LogoTitle.propTypes = {
  title: PropTypes.string.isRequired,
  hasTooltip: PropTypes.bool,
  tooltipTexts: PropTypes.shape(PropTypes.string)
};

LogoTitle.defaultProps = {
  hasTooltip: false,
  tooltipTexts: []
};

export default LogoTitle;
