// Import Dependencies
import styled from 'styled-components';

// Import vars
import typography from '../../global/typography';
import { colors, global, spacing, layer } from '../../../utils/styles';

const Hint = styled.div`
  ${typography.body};
  background-color: ${colors.white};
  border-radius: ${global.radius};
  box-shadow: ${global.shadowLarge};
  color: ${colors.grey500};
  display: block;
  left: ${spacing.small};
  padding: ${spacing.small};
  position: relative;
  top: -20px;
  width: 280px;
  z-index: ${layer.tooltipOnTop};

  &:before {
    background-color: transparent;
    border-color: transparent ${colors.white} transparent transparent;
    border-style: solid;
    border-width: 8px 8px 8px 0;
    content: '';
    display: block;
    height: 0;
    left: -8px;
    position: absolute;
    top: ${spacing.base};
    width: 0;
  }

  .tether-target-attached-left & {
    left: auto;
    right: ${spacing.small};

    &:before {
      left: auto;
      right: -8px;
      transform: rotate(180deg);
    }
  }

  h3 {
    padding-right: ${spacing.base};
  }
`;

export default Hint;
