// Import Dependencies
import React from 'react';

// Import Styled Components
import Icon from '../../styles/components/Icon';
import StyledHeader from '../../styles/components/Header';

const HeaderLogo = ({ ...props }) => (
  <StyledHeader.Logo {...props}>
    <Icon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      style={{ enableBackground: 'new 0 0 500 500' }}
      {...props}
    >
      <g>
        <g>
          <linearGradient
            id="gradient0"
            gradientUnits="userSpaceOnUse"
            x1="69.5062"
            y1="-881.3652"
            x2="71.6711"
            y2="-982.0391"
            gradientTransform="matrix(1 0 0 -1 0 -599.2)"
          >
            <stop offset="8.292130e-03" style={{ stopColor: '#FFFFFF', stopOpacity: '0.4' }} />
            <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0.3' }} />
          </linearGradient>
          <path
            style={{ fill: 'url(#gradient0)' }}
            d="M0,266.6v226.8l140.5-140.5v-92.6c0-6.7,0.7-13.6,2.2-20.2H1.3C0.4,248.9,0,257.7,0,266.6z"
          />

          <linearGradient
            id="gradient1"
            gradientUnits="userSpaceOnUse"
            x1="425.3356"
            y1="-806.7236"
            x2="390.4247"
            y2="-752.3275"
            gradientTransform="matrix(1 0 0 -1 0 -599.2)"
          >
            <stop offset="0" style={{ stopColor: '#FFFFFF', stopOpacity: '0.8' }} />
            <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0.7' }} />
          </linearGradient>
          <path
            style={{ fill: 'url(#gradient1)' }}
            d="M428.8,77.9c-2.2-2.2-4.5-4.3-6.7-6.4L317.6,175.8c1.8,1.5,3.5,3.2,5.2,4.8c16.8,16.8,27,37.8,30.1,59.5
            h146.9C497.4,181.2,473.7,122.9,428.8,77.9z"
          />

          <linearGradient
            id="gradient2"
            gradientUnits="userSpaceOnUse"
            x1="388.8741"
            y1="-951.6827"
            x2="426.2208"
            y2="-872.1181"
            gradientTransform="matrix(1 0 0 -1 0 -599.2)"
          >
            <stop offset="0" style={{ stopColor: '#FFFFFF', stopOpacity: '0.9' }} />
            <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0.8' }} />
          </linearGradient>
          <path
            style={{ fill: 'url(#gradient2)' }}
            d="M352.9,240.1c4.3,29.5-4.2,60.2-26.5,83.3L427.1,424c0.6-0.6,1.1-1.1,1.7-1.6c50-50,73.7-116.6,71-182.2
            L352.9,240.1L352.9,240.1z"
          />

          <linearGradient
            id="gradient3"
            gradientUnits="userSpaceOnUse"
            x1="186.9838"
            y1="-689.6594"
            x2="97.2705"
            y2="-784.244"
            gradientTransform="matrix(1 0 0 -1 0 -599.2)"
          >
            <stop offset="8.292130e-03" style={{ stopColor: '#FFFFFF', stopOpacity: '0.6' }} />
            <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0.4' }} />
          </linearGradient>
          <path
            style={{ fill: 'url(#gradient3)' }}
            d="M79.3,76.3c-0.6,0.6-1.1,1.1-1.6,1.6C32.9,122.7,7.1,180.3,1.3,240.1h141.3c4.5-21.5,16.1-42.2,32.6-58.7
            c1.5-1.5,3.2-3,4.8-4.5c19.9-17.5,45.5-26.2,70.9-26V6.6C188.4,7.2,126.1,30.4,79.3,76.3z"
          />

          <linearGradient
            id="gradient4"
            gradientUnits="userSpaceOnUse"
            x1="350.1947"
            y1="-705.5762"
            x2="281.9964"
            y2="-681.2196"
            gradientTransform="matrix(1 0 0 -1 0 -599.2)"
          >
            <stop offset="8.292130e-03" style={{ stopColor: '#FFFFFF', stopOpacity: '0.7' }} />
            <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0.6' }} />
          </linearGradient>
          <path
            style={{ fill: 'url(#gradient4)' }}
            d="M422,71.4C374.7,27.6,312.7,6,250.9,6.6v144.4c24,0.2,47.9,8.4,66.7,24.8L422,71.4z"
          />

          <linearGradient
            id="gradient5"
            gradientUnits="userSpaceOnUse"
            x1="149.601"
            y1="-1013.9623"
            x2="325.5092"
            y2="-1005.8434"
            gradientTransform="matrix(1 0 0 -1 0 -599.2)"
          >
            <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
            <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: '0.9' }} />
          </linearGradient>
          <path
            style={{ fill: 'url(#gradient5)' }}
            d="M326.5,323.4c-0.4,0.5-0.9,0.9-1.3,1.4c-20.5,20.5-47.5,27.4-74.3,28.2c-1.5,0-3.1,0.1-4.6,0.1H140.5
            L0,493.4h241.3c3.2,0,6.3-0.1,9.6-0.2c62.8-1.8,127.4-21.5,176.2-69.3L326.5,323.4z"
          />
        </g>
        <rect x="0" y="352.9" style={{ fill: '#F47C31' }} width="140.5" height="140.5" />
      </g>
    </Icon>
  </StyledHeader.Logo>
);

export default HeaderLogo;
