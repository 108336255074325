import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { ButtonBack, Form, Loader, Sidebar, Title } from '../../components';
import { Container, PageMain, Section } from '../../skandComponents';
import { getFormTitle } from '../../utils/functions';
import { getFields, getSubNavItems, useFetchData } from './utils';
import useHandlers from './utils/useHandlers';

const SiteDetail = ({ hasWhiteLabelingNavbar }) => {
  const { siteId } = useParams();
  const { siteDetail, users, isFetching, error, message } = useFetchData();
  const fields = getFields(users);
  const subNavItems = getSubNavItems();
  const title = `${getFormTitle(siteId)} site`;

  const { handleSubmit } = useHandlers(siteId, siteDetail);

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageMain modifiers={{ hasWhiteLabelingNavbar }}>
        <Sidebar
          title="Manage"
          hintContent={{
            title: 'Sites',
            content: 'Add, delete and manage sites to get things started.',
          }}
          subNav={{
            items: subNavItems,
          }}
        />

        <Container>
          <Section>
            <ButtonBack />
            <Title type="h2" data-cy="siteDetailTitle">
              {title}
            </Title>
            <Loader isFetching={isFetching}>
              <Form
                fields={fields}
                formData={siteId && siteDetail}
                onSubmit={handleSubmit}
                isProcessing={isFetching}
                errorMessage={error ? message : null}
                submitLabel={`${siteId ? 'Update' : 'Create'} site`}
                submitButtonProps={{ 'data-cy': 'siteDetailSubmitButton' }}
              />
            </Loader>

            {/* {siteId && <Buildings buildings={siteDetail.buildings} />} */}
          </Section>
        </Container>
      </PageMain>
    </div>
  );
};

SiteDetail.propTypes = {
  hasWhiteLabelingNavbar: PropTypes.bool,
};

SiteDetail.defaultProps = {
  hasWhiteLabelingNavbar: false,
};

export default SiteDetail;
