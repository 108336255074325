// Import Dependencies
import styled from 'styled-components';
// Import Utilities
import { getModifier } from '../../../utils/functions';
import {
  breakpoint,
  colors,
  componentHeight,
  componentWidth,
  global,
  layer,
  spacing
} from '../../../utils/styles';

const Item = styled.li`
  flex: 0 0 ${componentHeight.nav.default};
  font-size: 10px;
  height: ${componentHeight.nav.default};
  margin: 0;
  overflow: hidden;
  position: relative;
  width: ${componentHeight.nav.default};
  z-index: ${layer.nav};

  ${breakpoint.medium`
    font-size: 0;
  `};

  &:after {
    background-color: ${(props) => props.theme.primaryColor};
    bottom: 0;
    content: '';
    display: block;
    height: 4px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%) scaleX(0);
    transition: transform 200ms ${global.easing} 0s;
    width: 34px;
    width: calc(${componentHeight.nav.default} - ${spacing.small} - ${spacing.small});

    ${breakpoint.medium`
      bottom: auto;
      height: calc(${componentWidth.nav.default} - ${spacing.small} - ${spacing.small});
      left: 0;
      top: 50%;
      transform: translateY(-50%) scaleY(0);
      width: 4px;
    `};
  }

  a,
  button {
    &,
    &:link,
    &:visited {
      color: ${colors.grey500};
      display: block;
      font-size: 0;
      height: 100%;
      padding: 37.5px ${spacing.xxSmall} ${spacing.small};
      position: relative;
      text-align: center;
      width: 100%;

      ${breakpoint.medium`
        padding: ${spacing.small};
      `};
    }

    &:hover,
    &:active,
    &:focus {
      svg {
        fill: ${(props) => props.theme.primaryColor};
        color: ${(props) => props.theme.primaryColor};
      }
    }
  }

  svg {
    display: block;
    fill: ${colors.grey500};
    height: 20px;
    left: 50%;
    position: absolute;
    top: 35%;
    transform: translate(-50%, -50%);
    transition: fill 200ms ${global.easing} 0s;
    width: 20px;

    ${breakpoint.medium`
      top: 50%;
    `};
  }

  /* Modifier: is Active */
  ${(props) =>
    getModifier(props, 'isActive') &&
    `
      &:after {
        transform: translateX(-50%) scaleX(1);
      }

      a,
      button {
        &,
        &:link,
        &:visited {
          color: ${(props) => props.theme.primaryColor};
          cursor: default;
        }
      }

      svg {
        fill: ${(props) => props.theme.primaryColor};
      }
  `};

  ${(props) =>
    getModifier(props, 'isActive') &&
    breakpoint.medium`
      &:after {
        transform: translateY(-50%) scaleY(1);
      }
  `};

  /* Modifier: is disabled */
  ${(props) =>
    getModifier(props, 'isDisabled') &&
    `
      opacity: 0.3;
      pointer-events: none;
  `};
`;

export default Item;
