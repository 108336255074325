// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Account = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path d="M250 41.7c40.2 0 72.9 32.7 72.9 73 0 24.1-11.9 46.7-31.9 60.3-13.1 8.9-20 24.5-17.8 40.2 2.2 15.7 13.1 28.8 28.1 33.8 69 22.9 115.3 88.4 115.3 162.8 0 13.9-56.7 46.5-166.7 46.5S83.3 425.7 83.3 411.8c0-74.5 46.3-139.9 115.3-162.8 15-5 25.9-18.1 28.1-33.8 2.2-15.7-4.7-31.3-17.8-40.2-20-13.6-31.9-36.2-31.9-60.3.1-40.3 32.8-73 73-73M250 0c-63.3 0-114.6 51.3-114.6 114.7 0 39.4 19.9 74.1 50.1 94.8C102 237.2 41.7 317.2 41.7 411.8c0 58.8 104.2 88.2 208.3 88.2s208.3-29.4 208.3-88.2c0-94.6-60.4-174.6-143.8-202.4 30.2-20.6 50.1-55.4 50.1-94.8C364.6 51.3 313.3 0 250 0z" />
  </Icon>
);

export default Account;
