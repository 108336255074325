// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

const FauxField = ({ value, transformer }) => (
  <p>{transformer ? transformer(value) : value}</p>
);

FauxField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  ]),
  transformer: PropTypes.func,
};

FauxField.defaultProps = {
  value: null,
  transformer: null,
};

export default FauxField;
