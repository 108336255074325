import { PATHS } from '../../../utils/constants';

const getSubNavItems = () => [
  {
    id: 'details',
    text: 'Details',
    directTo: `${PATHS.ACCOUNT}/details`
  }
];

export default getSubNavItems;
