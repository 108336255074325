// Import Dependencies
import styled from 'styled-components';

// Import elements
import Heading from './Heading';
import Item from './Item';
import Hint from './Hint';

// Import vars
import { spacing } from '../../../utils/styles';

const QuickLinks = styled.dl`
  list-style: none;
  margin: 0 0 ${spacing.medium};
  position: relative;
`;

// Set elements
QuickLinks.Heading = Heading;
QuickLinks.Item = Item;
QuickLinks.Hint = Hint;

export default QuickLinks;
