const getSitesFilterTabs = (accountId) => [
  {
    id: 'all',
    label: 'Projects',
    filterFunction: (item) => item.accountId === accountId
  },
  {
    id: 'shared',
    label: 'Shared with me',
    filterFunction: (item) => item.accountId !== accountId
  }
];

export default getSitesFilterTabs;
