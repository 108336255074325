import { gql } from '@apollo/client';

const SITE_DETAIL_PAGE_QUERY = gql`
  query SiteDetailPageQuery($siteId: String!) {
    site(siteId: $siteId) {
      id
      name
      code
      address
      manager
      buildings {
        id
        name
        roofMaterialTypeId
        roofConstructionTypeId
      }
    }
  }
`;

const USERS_QUERY = gql`
  query UsersQuery {
    usersByAccountId {
      id
      firstName
      lastName
    }
  }
`;

export { SITE_DETAIL_PAGE_QUERY, USERS_QUERY };
