import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import SkandOps from 'skand.webapp-ops/src/main/operationsWrapper';
import { PageMain } from '../../skandComponents';

const Operations = ({ hasSideNavbar }) => {
  return (
    <div>
      <Helmet>
        <title>Operations</title>
      </Helmet>
      <PageMain modifiers={{ operations: true, hasSideNavbar }}>
        <SkandOps isWebappV2 />
      </PageMain>
    </div>
  );
};

Operations.propTypes = {
  hasSideNavbar: PropTypes.bool
};

Operations.defaultProps = {
  hasSideNavbar: true
};

export default Operations;
