import { useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { LOGO_TYPES } from '../../../utils/constants';
import defaultReportLogo from '../assets/defaultReportLogo.svg';
import defaultSharedSiteLogo from '../assets/defaultSharedSiteLogo.svg';
import { DELETE_STORAGE_MUTATION, UPDATE_ACCOUNT_MUTATION } from './mutations';

const useHandlers = ({
  accountId,
  setLogoType,
  setNewBrandingColor,
  setTempBannerData,
  setTempLogoData,
  setUploaderOpen,
  setTempReportData
}) => {
  const { pathname: pathName } = useLocation();

  const [updateAccount, { loading: updatingAccount }] = useMutation(UPDATE_ACCOUNT_MUTATION);
  const [deleteStorage, { loading: deletingStorage }] = useMutation(DELETE_STORAGE_MUTATION);

  const handleReportPreview = () => {
    window.open(`${pathName}/preview/${LOGO_TYPES.REPORT_LOGO}`);
  };

  const handleReportLogoError = (event) => {
    // eslint-disable-next-line no-param-reassign
    event.target.src = defaultReportLogo;
  };

  const handleReportLogoUpload = () => {
    setLogoType(LOGO_TYPES.REPORT_LOGO);
    setUploaderOpen(true);
  };

  const handleReportLogoDelete = async (bannerId) => {
    await deleteStorage({ variables: { storageId: bannerId } });

    const updateAccountInput = { accountId, bannerId: null };
    await updateAccount({ variables: { input: updateAccountInput } });

    setTempReportData(null);
  };

  const handleReportColorUpdate = async ({ color }) => {
    const updateAccountInput = { accountId, brandingColor: color };
    await updateAccount({ variables: { input: updateAccountInput } });

    setNewBrandingColor(null);
  };

  const handleBannerLogoError = (event) => {
    // eslint-disable-next-line no-param-reassign
    event.target.src = defaultReportLogo;
  };

  const handleBannerLogoUpload = () => {
    setLogoType(LOGO_TYPES.BANNER_LOGO);
    setUploaderOpen(true);
  };

  const handleBannerLogoDelete = async (bannerLogoStorageId) => {
    await deleteStorage({ variables: { storageId: bannerLogoStorageId } });

    const updateAccountInput = { accountId, bannerLogoStorageId: null };
    await updateAccount({ variables: { input: updateAccountInput } });

    setTempBannerData(null);
  };

  const handleBannerColorUpdate = async ({ color }) => {
    const updateAccountInput = { accountId, brandingColor: color };
    await updateAccount({ variables: { input: updateAccountInput } });

    setNewBrandingColor(null);
  };

  const handleSharedSitePreview = () => {
    window.open(`${pathName}/preview/${LOGO_TYPES.SHARED_SITE_LOGO}`);
  };

  const handleSharedSiteLogoError = (event) => {
    // eslint-disable-next-line no-param-reassign
    event.target.src = defaultSharedSiteLogo;
  };

  const handleSharedSiteLogoUpload = () => {
    setLogoType(LOGO_TYPES.SHARED_SITE_LOGO);
    setUploaderOpen(true);
  };

  const handleSharedSiteLogoDelete = async (logoId) => {
    await deleteStorage({ variables: { storageId: logoId } });

    const updateAccountInput = { accountId, logoId: null };
    await updateAccount({ variables: { input: updateAccountInput } });

    setTempLogoData(null);
  };

  const handlePoweredBySkandClick = async (event) => {
    const updateAccountInput = { accountId, hasSkandWatermark: event.target.checked };
    await updateAccount({ variables: { input: updateAccountInput } });
  };

  return {
    isProcessing: updatingAccount || deletingStorage,
    handlePoweredBySkandClick,
    handleReportColorUpdate,
    handleReportLogoDelete,
    handleReportLogoError,
    handleReportLogoUpload,
    handleReportPreview,
    handleBannerLogoUpload,
    handleBannerLogoDelete,
    handleBannerLogoError,
    handleBannerColorUpdate,
    handleSharedSiteLogoDelete,
    handleSharedSiteLogoError,
    handleSharedSiteLogoUpload,
    handleSharedSitePreview
  };
};

export default useHandlers;
