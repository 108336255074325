// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import vars
import typography from '../../global/typography';
import { spacing } from '../../../utils/styles';

const Title = styled.h4`
  ${typography.smallCaps};
  margin-bottom: ${spacing.small};
  opacity: 0.5;

  /* Modifier: Extra spacing below title */
  ${(props) =>
    getModifier(props, 'marginBottomMidLarge') &&
    `
      margin-bottom: ${spacing.midLarge};
    `};
`;

export default Title;
