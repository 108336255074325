import { gql } from '@apollo/client';

export const LOGIN_INVITATION_MUTATION = gql`
  mutation LoginInvitation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      bearerToken
      email
      accountId
    }
  }
`;

export const ACCEPT_INVITATION_MUTATION = gql`
  mutation AcceptInvitation($invitationToken: String, $password: String) {
    acceptInvitation(invitationToken: $invitationToken, password: $password) {
      id
      email
    }
  }
`;
