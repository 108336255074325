import { gql } from '@apollo/client';

export const LOGOUT_MUTATION = gql`
  mutation LogoutMutation {
    logout
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation AccountPageUpdate($user: UpdateUserInput) {
    updateUser(user: $user) {
      id
      email
      firstName
      lastName
    }
  }
`;

export const CREATE_PORTAL_SESSION = gql`
  mutation CreatePortalSession {
    createSubscriptionPortalSession
  }
`;
