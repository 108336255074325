// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Componenys
import StyledTextArea from '../../styles/components/TextArea';

const TextArea = ({ error: hasError, value, ...props }) => (
  <StyledTextArea rows="4" modifiers={{ hasError }} value={value || ''} {...props} />
);

TextArea.propTypes = {
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
};

TextArea.defaultProps = {
  error: null,
  value: '',
};

export default TextArea;
