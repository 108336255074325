// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import * as Icons from '../Icons';

// Import Styled Components
import StyledSearchInput from '../../styles/components/SearchInput';

const SearchInput = ({ error: hasError, ...props }) => (
  <StyledSearchInput>
    <StyledSearchInput.Inner modifiers={{ hasError }} {...props} />
    <Icons.Magnifier />
  </StyledSearchInput>
);

SearchInput.propTypes = {
  error: PropTypes.arrayOf(PropTypes.string),
};

SearchInput.defaultProps = {
  error: null,
};

export default SearchInput;
