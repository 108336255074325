/* eslint-disable complexity */
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import * as Icons from '../../../components/Icons';
import { PATHS } from '../../../utils/constants';

const EXPLORE = {
  id: 'explore',
  text: 'Explore',
  icon: <Icons.Pin />,
  directTo: `${PATHS.EXPLORE}`,
  group: 'primary',
  'data-cy': 'navbarExploreTab',
  access: ['user']
};

const EXPLORE_BETA = {
  id: 'exploreBeta',
  text: 'Explore',
  icon: <Icons.Pin />,
  directTo: [PATHS.EXPLORE_BETA_PROJECT, PATHS.EXPLORE_BETA],
  group: 'primary',
  access: ['user']
};

const DASHBOARD = {
  id: 'dashboard',
  text: 'Dashboard',
  icon: <Icons.Home />,
  directTo: `${PATHS.ROOT}`,
  group: 'primary',
  'data-cy': 'navbarDashboardTab',
  access: ['user']
};

const DATASETS = {
  id: 'datasets',
  text: 'Data Management',
  icon: <Icons.Datasets />,
  directTo: `${PATHS.DATASETS}`,
  group: 'primary',
  'data-cy': 'navbarDatasetsTab',
  access: ['user']
};

const DATASETS_BETA = {
  id: 'datasetsBeta',
  text: 'Data Management',
  icon: <Icons.Datasets />,
  directTo: PATHS.DATASETS_BETA,
  group: 'primary',
  access: ['user']
};

const MANAGE = {
  id: 'manage',
  text: 'Manage',
  icon: <Icons.Settings />,
  directTo: `${PATHS.MANAGE}`,
  group: 'primary',
  'data-cy': 'navbarManageTab',
  access: ['user']
};

const MANAGE_BETA = {
  id: 'manageBeta',
  text: 'Manage',
  icon: <Icons.Settings />,
  directTo: PATHS.MANAGE_BETA,
  group: 'primary',
  access: ['user']
};

const OPERATIONS = {
  id: 'operations',
  text: 'Operations',
  icon: <Icons.Operations />,
  directTo: `${PATHS.OPERATIONS}`,
  group: 'primary',
  'data-cy': 'navbarOpsTab',
  access: ['user']
};

const REPORTS = {
  id: 'reports',
  text: 'Reports',
  icon: <Icons.Report />,
  directTo: `${PATHS.REPORTS}`,
  group: 'primary',
  'data-cy': 'navbarReportsTab',
  access: ['user']
};

const REPORTS_BETA = {
  id: 'reportsBeta',
  text: 'Reports',
  icon: <Icons.Report />,
  directTo: PATHS.REPORTS_BETA,
  group: 'primary',
  access: ['user']
};

export const getPrimaryNavItems = (
  isOperationsVisibleToUser: boolean,
  isWebappBetaEnabled: boolean
): object[] => {
  const primaryNavItems = [
    ...(isWebappBetaEnabled ? [] : [DASHBOARD]),
    isWebappBetaEnabled ? EXPLORE_BETA : EXPLORE,
    isWebappBetaEnabled ? DATASETS_BETA : DATASETS,
    isWebappBetaEnabled ? REPORTS_BETA : REPORTS,
    ...(isOperationsVisibleToUser ? [OPERATIONS] : []),
    isWebappBetaEnabled ? MANAGE_BETA : MANAGE
  ];

  return primaryNavItems;
};

type SecondaryNavItem = {
  id: string;
  text: string;
  icon: React.ReactNode;
  group: 'secondary';
  'data-cy': string;
  access: string[];
  directTo: string | null;
  onClick: () => void;
};

export const getSecondaryNavItems = (setIsSupportOpen, isChatBotEnabled) => {
  const secondaryNavItems: SecondaryNavItem[] = [
    {
      id: 'account',
      text: 'Account',
      icon: <Icons.Account />,
      directTo: `${PATHS.ACCOUNT}/details`,
      group: 'secondary',
      'data-cy': 'navbarAccountTab',
      access: ['user'],
      onClick: () => { }
    }
  ];

  if (isChatBotEnabled) {
    secondaryNavItems.unshift({
      id: 'chat',
      text: 'Chat with us',
      icon: <Icons.Chat />,
      group: 'secondary',
      onClick: () => window.Intercom('show'),
      'data-cy': 'navbarChatTab',
      access: ['user'],
      directTo: null
    });
  } else {
    secondaryNavItems.unshift({
      id: 'support',
      text: 'Support',
      icon: <Icons.Help />,
      group: 'secondary',
      onClick: () => setIsSupportOpen((isSupportOpen) => !isSupportOpen),
      'data-cy': 'navbarSupportTab',
      access: ['user'],
      directTo: null
    });
  }
  return secondaryNavItems;
};
