import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import StyledButton from '../../styles/components/Button';

import * as Icons from '../Icons';

const ButtonBack = ({ onClick, dataCyTestId }) => {
  const history = useHistory();

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <StyledButton
      data-cy={dataCyTestId}
      onClick={onClick || handleBackClick}
      modifiers={{ marginBottom: true, inlineLink: true, altHover: true }}
    >
      <Icons.ArrowLeftHeavy /> Back
    </StyledButton>
  );
};

ButtonBack.propTypes = {
  onClick: PropTypes.func,
};

ButtonBack.defaultProps = {
  onClick: null,
};

export default ButtonBack;
