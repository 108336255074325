import PropTypes from 'prop-types';

const userData = {
  account_id: PropTypes.number,
  email: PropTypes.string,
  first_name: PropTypes.string,
  id: PropTypes.number,
  is_admin: PropTypes.bool,
  last_login: PropTypes.number,
  last_name: PropTypes.string,
  password: PropTypes.string,
  role: PropTypes.string,
};

export default userData;
