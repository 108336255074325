// Import Dependencies
import styled from 'styled-components';

// Import vars
import { spacing } from '../../../utils/styles';

const Item = styled.li`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin: ${spacing.small} 0;
`;

export default Item;
