import PropTypes from 'prop-types';

const navItem = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
  to: PropTypes.string,
};

export default navItem;
