// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Utilities
import { stopPropagation } from '../../utils/functions';

// Import Components
import * as Icon from '../../components/Icons';

// Import Styled Componenys
import StyledModal from '../../styles/components/Modal';
import Button from '../../components/Button';

const Modal = ({ open, title, onClose, blurb, children, modifiers, ...props }) =>
  open && (
    <StyledModal onClick={onClose} modifiers={modifiers} {...props}>
      <StyledModal.Inner onClick={stopPropagation} modifiers={modifiers}>
        <StyledModal.Actions modifiers={modifiers}>
          <Button
            onClick={onClose}
            modifiers={{
              inlineLink: !(modifiers && modifiers.image),
              smallPadding: !!(modifiers && modifiers.image),
              iconOnly: true,
              altHover: true,
              largeIcon: !(modifiers && modifiers.image),
            }}
          >
            <Icon.Close />
          </Button>
        </StyledModal.Actions>
        {(title || blurb) && (
          <StyledModal.Header>
            {title && <h2>{title}</h2>}
            {blurb && <p>{blurb}</p>}
          </StyledModal.Header>
        )}
        <StyledModal.Content modifiers={modifiers}>{children}</StyledModal.Content>
      </StyledModal.Inner>
    </StyledModal>
  );

Modal.propTypes = {
  blurb: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string,
};

Modal.defaultProps = {
  blurb: null,
  open: false,
  title: null,
};

export default Modal;
