// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Home = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path d="M428.9,500H309.6c-11.6,0-21-9.5-21-21.1v-98.6h-77.2v98.6c0,11.7-9.4,21.1-21,21.1H71.1c-11.6,0-21-9.5-21-21.1V200.7c0-5.6,2.2-11,6.2-14.9L235.1,6.2c8.2-8.2,21.5-8.2,29.8,0l178.9,179.6c4,4,6.2,9.3,6.2,14.9v278.2C450,490.5,440.5,500,428.9,500z M330.7,457.7h77.2V209.5L250,51L92.1,209.5v248.3h77.2v-98.6c0-11.7,9.4-21.1,21-21.1h119.3c11.6,0,21,9.5,21,21.1V457.7z" />
  </Icon>
);

export default Home;
