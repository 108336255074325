// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import Globals
import { spacing } from '../../../utils/styles';

const Content = styled.div`
  min-height: ${spacing.xLarge};
  padding: ${spacing.base};
  position: relative;

  > :last-child {
    margin-bottom: 0;
  }

  /* Modifier: image */
  ${(props) =>
    getModifier(props, 'image') &&
    `
      padding: 0;
    `};

  /* Modifier: shareModal */
  ${(props) =>
    getModifier(props, 'shareModal') &&
    `
      padding: 2.188rem 2.5rem 2.5rem;
    `};

  ${(props) =>
    getModifier(props, 'leaveModal') &&
    `
      height: 15.438rem;
      padding: 2.188rem 2.5rem 2.5rem;
    `};
`;

export default Content;
