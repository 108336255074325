import orderBy from 'lodash/orderBy';

const getLowercaseName = (element: { name: string }) => {
  if (element.name) {
    return element.name.toLowerCase();
  }
  return '';
};

const sortListByLowercaseName = (list: any) =>
  orderBy(list, [(element) => getLowercaseName(element), 'label']);

export default sortListByLowercaseName;
