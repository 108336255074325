import Cookies from 'js-cookie';

const ACCESS_TOKEN_NAME = process.env.REACT_APP_ACCESS_TOKEN_NAME;

const checkAuthentication = () => {
  const hasAccessToken = !!Cookies.get(ACCESS_TOKEN_NAME);
  return hasAccessToken;
};

export default checkAuthentication;
