const validConfirmPassword = (fieldName, confirmPasswordValue, passwordValue, errors) => {
  let updatedErrors = errors;
  if (fieldName === 'passwordConfirm') {
    if (confirmPasswordValue !== passwordValue) {
      if (!updatedErrors || updatedErrors[0] === 'Minimum 8 characters') {
        updatedErrors = ['Password must match'];
      }
    }
  }

  return updatedErrors;
};

export default validConfirmPassword;
