// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const AddHeavy = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path d="M500 191H309V0H191v191H0v118h191v191h118V309h191z" />
  </Icon>
);

export default AddHeavy;
