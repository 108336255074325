// Import Dependencies
import styled from 'styled-components';

// Import Elements
import { rgba } from 'polished';
import Input from './Input';

// Import Globals
import typography from '../../global/typography';

// Import Utilities
import { getModifier } from '../../../utils/functions';
import { colors, global } from '../../../utils/styles';

const Label = styled.label`
  ${typography.body};
  color: ${colors.white};
  cursor: pointer;
  line-height: 1;
  padding-left: 22px;
  position: relative;

  &:before {
    background-color: ${colors.grey400};
    border: solid 1px ${colors.transparent};
    content: '';
    cursor: pointer;
    height: 14px;
    left: 0;
    position: absolute;
    top: 4px;
    transition: background-color ${global.transitionSpeed} ${global.easing} 0s,
      border-color ${global.transitionSpeed} ${global.easing} 0s;
    width: 14px;
  }

  &:after {
    background-image: url(data:image/svg+xml;base64,ICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDUwMCA1MDAiIGZpbGw9IiMwMDAwMDAiPg0KICAgIDxwYXRoIGQ9Ik0xOTMuOCA0NDAuNEwwIDI0Ni41bDc1LjEtNzUuMSAxMTguOCAxMTguOCAyMzEtMjMwLjYgNzUuMSA3NS4yeiIgLz4NCiAgPC9zdmc+);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 10px;
    left: 2px;
    opacity: 0;
    position: absolute;
    top: 6px;
    transition: opacity ${global.transitionSpeed} ${global.easing} 0s;
    width: 10px;
  }

  &:hover {
    &:before {
      background-color: ${colors.white};
    }
  }

  ${Input}:focus + &:before {
    border-color: ${rgba(colors.white, 0.4)};
  }

  ${Input}:checked + &:before {
    background-color: ${colors.orange};
  }

  ${Input}:checked + &:after {
    opacity: 1;
  }

  ${Input}:disabled + & {
    opacity: 0.5;
  }

  /* Modifier: hasError */
  ${(props) =>
    getModifier(props, 'hasError') &&
    `
    &:before {
      background-color: ${colors.error};
      border-color: ${colors.error};
    }
  `};

  /* Modifier: grey */
  ${(props) =>
    getModifier(props, 'grey') &&
    `
      color: ${rgba(colors.white, 0.5)};
  `};
`;

export default Label;
