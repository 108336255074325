// Import Dependencies
import styled from 'styled-components';

// Import elements
import Hint from './Hint';
import Inner from './Inner';

// Import vars
import { breakpoint, componentWidth, spacing } from '../../../utils/styles';

const Sidebar = styled.div`
  background-color: rgba(216, 216, 216, 0.1);
  padding: ${spacing.base};
  width: ${componentWidth.sidebar.default};

  ${breakpoint.medium`
    height: 100%;
    left: ${componentWidth.nav.medium};
    overflow-x: hidden;
    overflow-y: auto;
    padding: ${spacing.medium};
    position: fixed;
    width: ${componentWidth.sidebar.medium};
  `};

  ${breakpoint.xxLarge`
    width: ${componentWidth.sidebar.xxLarge};
  `};

  ${breakpoint.xxxLarge`
    width: ${componentWidth.sidebar.xxxLarge};
  `};
`;

Sidebar.Hint = Hint;
Sidebar.Inner = Inner;

export default Sidebar;
