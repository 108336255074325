import { gql } from '@apollo/client';

export const DASHBOARD_QUERY = gql`
  query DashboardQuery {
    capturesByAccountId {
      id
      captureDate
      code
      site {
        id
        buildings {
          squareMeters
        }
      }
    }
    reportsByAccountId {
      id
      createdAt
      name
      fields {
        siteIds
      }
    }
    sitesByAccountId {
      id
      buildingIds
      name
      manager
    }
    usersByAccountId {
      id
      firstName
      lastName
      lastSignInAt
      signInCount
    }
  }
`;
