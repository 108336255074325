// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledHeader from '../../styles/components/Header';

const Header = React.forwardRef(({ children, hasWhiteLabelingNavbar, disabled, ...props }, ref) => (
  <StyledHeader ref={ref} hasWhiteLabelingNavbar={hasWhiteLabelingNavbar} disabled={disabled} {...props}>
    {children}
  </StyledHeader>
));

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Header;
