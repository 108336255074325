import PropTypes from 'prop-types';
import React from 'react';
import { LogoTitle } from '..';
import { Loader } from '../../../../components';
import * as Icons from '../../../../components/Icons';
import {
  Container,
  Logo,
  LogoAction,
  LogoContainer,
  LogoControlLayer,
  LogoInfoContainer,
  LogoSection,
  LogoSize,
  Preview,
  Title,
  Underline
} from './brandingSectionStyles';

const BrandingSection = ({
  defaultLogo,
  handelLogoError,
  handelLogoUpload,
  handleLogoDelete,
  handlePreview,
  hasTooltip,
  isUploadingLogo,
  isNotBannerLogoSection,
  logoId,
  logoUrl,
  logoSize,
  tempLogoData,
  title,
  tooltipTexts
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Underline />

      <LogoSection>
        <LogoContainer>
          <Logo src={tempLogoData || logoUrl || defaultLogo} onError={handelLogoError} />

          <LogoControlLayer>
            <LogoAction onClick={handelLogoUpload}>
              <Icons.Camera />
            </LogoAction>

            {logoUrl && (
              <LogoAction onClick={() => handleLogoDelete(logoId)}>
                <Icons.Delete />
              </LogoAction>
            )}
          </LogoControlLayer>
        </LogoContainer>

        <LogoInfoContainer>
          <LogoTitle title={title} hasTooltip={hasTooltip} tooltipTexts={tooltipTexts} />
          {logoSize && <LogoSize>Recommended image size:</LogoSize>}
          <LogoSize>{logoSize}</LogoSize>
        </LogoInfoContainer>

        {isNotBannerLogoSection && (
          <Preview onClick={handlePreview} disabled={isUploadingLogo}>
            <Loader isFetching={isUploadingLogo}>PREVIEW</Loader>
          </Preview>
        )}
      </LogoSection>
    </Container>
  );
};

BrandingSection.propTypes = {
  defaultLogo: PropTypes.shape().isRequired,
  handelLogoError: PropTypes.func.isRequired,
  handelLogoUpload: PropTypes.func.isRequired,
  handleLogoDelete: PropTypes.func.isRequired,
  handlePreview: PropTypes.func.isRequired,
  hasTooltip: PropTypes.bool,
  isUploadingLogo: PropTypes.bool.isRequired,
  isNotBannerLogoSection: PropTypes.bool,
  logoId: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  tempLogoData: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tooltipTexts: PropTypes.shape(PropTypes.string),
  logoSize: PropTypes.string.isRequired
};

BrandingSection.defaultProps = {
  hasTooltip: false,
  tooltipTexts: [],
  isNotBannerLogoSection: false
};

export default BrandingSection;
