// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import Hint from '../Hint';

// Import Styled Components
import StyledTable from '../../styles/components/Table';

const TableHeader = ({ cols, hasActions, doubleActions, hintContent }) => (
  <thead>
    <tr>
      {cols &&
        cols.map((item) => <StyledTable.Header key={item.id}>{item.label}</StyledTable.Header>)}
      {(hasActions || hintContent) && (
        <StyledTable.Header modifiers={{ alignCenter: !!hintContent }}>
          {hintContent && <Hint {...hintContent} />}
        </StyledTable.Header>
      )}
      {doubleActions && <StyledTable.Header />}
    </tr>
  </thead>
);

TableHeader.propTypes = {
  cols: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasActions: PropTypes.bool.isRequired,
  doubleActions: PropTypes.bool,
  hintContent: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
};

TableHeader.defaultProps = {
  doubleActions: false,
  hintContent: null,
};

export default TableHeader;
