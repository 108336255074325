// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Chat = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
      <path d="M240-240 131-131q-14 14-32.5 6.344T80-152v-668q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240Zm-26-60h606v-520H140v600l74-80Zm-74 0v-520 520Zm130-99h253q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T523-459H270q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T270-399Zm0-130h420q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T690-589H270q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T270-529Zm0-130h420q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T690-719H270q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T270-659Z" />
    </svg>
  </Icon>
);

export default Chat;
