import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useHandleInvalidToken } from '../../../../../hooks';
import { PATHS } from '../../../../../utils/constants';
import { INVITE_USER_MUTATION, UPDATE_USER_BY_ID_MUTATION } from '../../../utils/mutations';
import { UPDATE_CACHE_FOR_INVITE_USER_MUTATION } from '../../../utils/fragments';

const useHandlers = (userId) => {
  const history = useHistory();

  const { checkInvalidTokenError } = useHandleInvalidToken();

  const [inviteUser, { loading: isInviting, error: inviteUserError }] = useMutation(
    INVITE_USER_MUTATION,
    {
      onCompleted: () => history.push(PATHS.USERS),
      onError: (e) => {
        checkInvalidTokenError(e);
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            usersByAccountId(existingRefs = []) {
              const newRef = cache.writeFragment({
                data: data.inviteUser,
                fragment: UPDATE_CACHE_FOR_INVITE_USER_MUTATION,
              });

              return [...existingRefs, newRef];
            },
          },
        });
      },
    }
  );

  const [updateUserById, { loading: isUpdating, error: updateUserError }] = useMutation(
    UPDATE_USER_BY_ID_MUTATION,
    {
      onCompleted: () => history.push(PATHS.USERS),
      onError: (e) => {
        checkInvalidTokenError(e);
      },
    }
  );

  const isProcessing = isInviting || isUpdating;
  const error = inviteUserError || updateUserError;
  const message = error?.message;

  const handleSubmit = async (data) => {
    const { email, firstName, lastName } = data;
    const { protocol, hostname, port } = window.location;
    const hostDomain = `${protocol}//${hostname}${port && `:${port}`}`;

    if (userId) {
      await updateUserById({
        variables: {
          userId,
          user: {
            firstName,
            lastName,
            email,
          },
        },
      });
    } else {
      await inviteUser({
        variables: {
          hostDomain,
          newUserEmail: email,
          firstName,
          lastName,
        },
      });
    }
  };

  return { handleSubmit, isProcessing, error, message };
};

export default useHandlers;
