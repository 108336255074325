import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { useHandleInvalidToken } from '../../hooks';
import { REPORT_PDF_QUERY } from './utils/queries';

const ReportPdf = () => {
  const { checkInvalidTokenError } = useHandleInvalidToken();
  const { shareId } = useParams();

  const {
    data: { reportByShareId } = {
      reportByShareId: {}
    }
  } = useQuery(REPORT_PDF_QUERY, {
    variables: { shareId },
    onError: (e) => {
      checkInvalidTokenError(e);
    }
  });

  const pdfUrl = reportByShareId?.pdfStorage?.signedGetObjectUrl || '';

  useEffect(() => {
    if (pdfUrl) window.location.href = pdfUrl;
  }, [pdfUrl]);

  return null;
};

ReportPdf.propTypes = {};

export default ReportPdf;
