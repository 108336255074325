import Cookies from 'js-cookie';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { getShareLinkToken } from 'pages/App/utils/shareLink';
import { PATHS } from '../utils/constants';

const isPublicOrUnauthorisedRoute = (currentPathName, routesWithoutToken) => {
  return routesWithoutToken.some((route) => {
    const { path } = route;
    const matchResult = matchPath(currentPathName, { path });
    return !!matchResult;
  });
};

const useHandleInvalidToken = (routesWithoutToken = []) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const hasShareLinkToken = getShareLinkToken();

  const isRoutesWithoutToken = isPublicOrUnauthorisedRoute(pathname, routesWithoutToken);
  const INVALID_TOKEN_ENUM = 'INVALID_TOKEN';

  // eslint-disable-next-line complexity
  const checkInvalidTokenError = (error) => {
    const graphqlErrors = error?.graphQLErrors;
    const errorCode = (graphqlErrors?.length && graphqlErrors[0].extensions?.code) || '';

    if (errorCode === INVALID_TOKEN_ENUM && !isRoutesWithoutToken) {
      // pass shareLinkToken to webapp-explore
      if (
        (pathname.startsWith(PATHS.EXPLORE_BETA) ||
          pathname.startsWith(PATHS.EXPLORE_BETA_COMPAT)) &&
        hasShareLinkToken
      )
        return;

      const ACCESS_TOKEN_NAME = process.env.REACT_APP_ACCESS_TOKEN_NAME;
      Cookies.remove(ACCESS_TOKEN_NAME);
      history.push(PATHS.LOGIN);
    }
  };

  return { checkInvalidTokenError };
};

export default useHandleInvalidToken;
