// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';

import * as Icons from '../../../../components/Icons';
import { BoldText, ErrorPageContainer, LinkToHome, Text } from './errorPageStyles';

const ErrorPage = () => {
  const handleLinkToHome = () => {
    window.location.href = '/';
  };

  return (
    <ErrorPageContainer>
      <Icons.ErrorPage />
      <BoldText>Ooops!!</BoldText>
      <Text>
        Something went wrong!! <LinkToHome onClick={handleLinkToHome}>Go back to home.</LinkToHome>
      </Text>
    </ErrorPageContainer>
  );
};

ErrorPage.propTypes = {};

export default ErrorPage;
