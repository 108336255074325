// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import elements
import Item from './Item';
import Inner from './Inner';

import { $tsToFix } from '../../../utils/types';
import { breakpoint, grid } from '../../../utils/styles';

const Grid = styled.ul<$tsToFix>`
  ${breakpoint.medium`
    align-content: flex-start;
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: -${grid.gutter / 2}px;
  `};

  ${(props: $tsToFix) =>
    getModifier(props, 'reverse') &&
    breakpoint.medium`
      flex-direction: row reverse;
  `};

  @media print {
    clear: both;
    display: block;
    margin: 0;
    text-align: left;
    width: 100%;
  }
`;

export { Grid, Item, Inner };
