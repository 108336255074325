// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const TickBold = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path d="M193.8 440.4L0 246.5l75.1-75.1 118.8 118.8 231-230.6 75.1 75.2z" />
  </Icon>
);

export default TickBold;
