import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import SkandDatasets from 'skand.data-management/src/main/datasetsWrapper';
import { PageMain } from '../../skandComponents';
import { sizes } from '../../utils/styles';

const Datasets = ({ hasWhiteLabelingNavbar, hasSideNavbar }) => {
  const topNavbarHeight = hasWhiteLabelingNavbar
    ? `calc(${sizes.whiteLabelingColorBar} + ${sizes.whiteLabelingContentBar})`
    : '0px';

  return (
    <div>
      <Helmet>
        <title>Datasets</title>
      </Helmet>
      <PageMain modifiers={{ datasets: true, topNavbarHeight, hasSideNavbar }}>
        <SkandDatasets topNavbarHeight={topNavbarHeight} />
      </PageMain>
    </div>
  );
};

Datasets.propTypes = {
  hasWhiteLabelingNavbar: PropTypes.bool,
  hasSideNavbar: PropTypes.bool,
};

Datasets.defaultProps = {
  hasWhiteLabelingNavbar: false,
  hasSideNavbar: true,
};

export default Datasets;
