// Import Dependencies
import styled from 'styled-components';

// Import Gobals
import { spacing } from '../../../utils/styles';

const Message = styled.p`
  padding: ${spacing.small} ${spacing.base};
`;

export default Message;
