import { useQuery } from '@apollo/client';

import { useHandleInvalidToken } from '../../../hooks';
import ACCOUNT_PAGE_QUERY from './queries';

const useFetchData = () => {
  const { checkInvalidTokenError } = useHandleInvalidToken();

  const {
    data: { userByAccountId: userData } = { userByAccountId: { id: '' } },
    loading: isFetching,
    error
  } = useQuery(ACCOUNT_PAGE_QUERY, {
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      checkInvalidTokenError(e);
    }
  });

  const message = error?.message;

  const account = userData?.id ? { accountName: userData.account.name } : null;
  const user = userData?.id ? { ...userData, role: userData.isAdmin ? 'Admin' : 'Member' } : null;

  return { user, account, isFetching, message, error };
};

export default useFetchData;
