import React from 'react';
import PropTypes from 'prop-types';
import { BoldText } from '../forgotPasswordStyles';

const ResultMessage = ({ email }) => {
  const supportEmail = 'support@skand.io';
  const supportEmailSubject = 'Request assistance for login into Skand';

  return (
    <>
      <p>
        If we found a user with the email address <BoldText>{email}</BoldText> you will receive an
        email from us shortly.
      </p>
      <p>
        For more support, please contact us at{' '}
        <BoldText>
          <a href={`mailto:${supportEmail}?subject=${supportEmailSubject}`}>{supportEmail}</a>
        </BoldText>
      </p>
    </>
  );
};

ResultMessage.propTypes = {
  email: PropTypes.string,
};

ResultMessage.defaultProps = {
  email: '',
};

export default ResultMessage;
