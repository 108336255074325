import React from 'react';
import PropTypes from 'prop-types';

import { Button, Portal } from '../../components';
import { Actions } from '../../styles/components';

const Confirmation = ({ open, setOpen, message, submitAction }) => {
  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    submitAction();
    handleClose();
  };

  if (!open) return false;

  return (
    <Portal open={open} onClose={handleClose}>
      <p>{message}</p>
      <Actions>
        <Actions.Item>
          <Button onClick={handleSubmit}>Yes</Button>
        </Actions.Item>
        <Actions.Item>
          <Button onClick={handleClose} modifiers={{ secondary: true }}>
            No
          </Button>
        </Actions.Item>
      </Actions>
    </Portal>
  );
};

Confirmation.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  message: PropTypes.string,
  submitAction: PropTypes.func,
};

Confirmation.defaultProps = {
  open: false,
  setOpen: null,
  message: '',
  submitAction: null,
};

export default Confirmation;
