// Import Dependencies
import styled from 'styled-components';

// Import vars
import { rgba } from 'polished';
import typography from '../../global/typography';
import { colors, spacing } from '../../../utils/styles';

const Heading = styled.dt`
  ${typography.smallCaps};
  color: ${rgba(colors.white, 0.5)};
  margin-bottom: ${spacing.xSmall};
`;

export default Heading;
