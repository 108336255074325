// Import Dependencies
import styled from 'styled-components';

// Import vars
import typography from '../../global/typography';
import { colors, global, spacing } from '../../../utils/styles';

const Item = styled.dd`
  margin: ${spacing.xSmall} 0;

  a {
    ${typography.link};
    ${typography.body};
    border: none;

    &,
    &:link,
    &:visited {
      color: ${colors.white};
      text-transform: none;
    }

    &:hover {
      border: none;
      color: ${colors.orange};

      svg {
        fill: ${colors.orange};
      }
    }
  }

  svg {
    display: inline-block;
    fill: ${colors.white};
    height: 9px;
    margin-right: ${spacing.xxSmall};
    transform: translate(0, -1px);
    transition: fill ${global.transitionSpeed} ${global.easing} 0s;
    width: 9px;
  }
`;

export default Item;
