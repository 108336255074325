// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import vars
import { fontSize, spacing } from '../../../utils/styles';

const Label = styled.dt`
  display: block;
  font-size: ${fontSize.xSmall};
  font-weight: 500;
  margin-bottom: ${spacing.base};
  position: relative;
  text-transform: capitalize;

  &:last-child {
    margin-bottom: 0;
  }

  /* Modifier: Color */
  ${(props) =>
    getModifier(props, 'color') &&
    `
      &:before {
        background-color: ${getModifier(props, 'color')};
        border-radius: 4px;
        content: "";
        display: inline-block;
        height: 4px;
        margin-right: 8px;
        width: 12px;
        vertical-align: middle;
      }
    `};

  /* Modifier: marginLeft */
  ${(props) =>
    getModifier(props, 'marginLeft') &&
    `
      margin-bottom: 0;
      margin-left: 10px;
      `};
`;

export default Label;
