import styled from 'styled-components';

import { fontSize, colors } from '../../../../utils/styles';

export const Container = styled.div`
  align-items: center;
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: auto;
  position: relative;
`;

export const Message = styled.p`
  color: ${colors.white};
  font-size: ${fontSize.large};
  line-height: 2.25rem;
  margin-bottom: 0;
`;

export const Link = styled.a`
  color: ${colors.blue500};
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
`;

export const LeftTopBackground = styled.div`
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  height: 20rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 20rem;
  z-index: -1;
`;

export const LeftBottomBackground = styled.div`
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  height: 10rem;
  left: 0;
  position: absolute;
  width: 10rem;
  z-index: -1;
`;

export const RightBottomBackground = styled.div`
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  height: 25rem;
  position: absolute;
  right: 0;
  width: 25rem;
  z-index: -1;
`;
