// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const NavigateBefore = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor">
      <path d="m12 15-5-5 5-5 1.062 1.062L9.125 10l3.937 3.938Z" />
    </svg>
  </Icon>
);

export default NavigateBefore;
