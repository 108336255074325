import styled from 'styled-components';

import { colors } from '../../utils/styles';

export const InviteButtonHoverMessage = styled.p`
  color: ${colors.blue500};
  font-size: 0.75rem;
  margin-top: 1rem;
  opacity: ${(props: { isInviteButtonHovered: boolean }) =>
    props.isInviteButtonHovered ? '1' : '0'};
`;
