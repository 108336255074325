// Import Dependencies
import styled from 'styled-components';

// Import Globals
import typography from '../../global/typography';
import { colors, global, spacing } from '../../../utils/styles';

const Header = styled.div`
  background-color: ${colors.white};
  border-radius: ${global.radius} ${global.radius} 0 0;
  color: ${colors.grey500};
  padding: ${spacing.base};

  h2 {
    ${typography.headingMedium};
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

export default Header;
