/* eslint-disable complexity */
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useRouteMatch } from 'react-router-dom';
import { Space } from '@skand/lego';
import { Button, Form, Loader, Notification, Portal, Sidebar, Title } from '../../components';
import { Container, PageMain, Section } from '../../skandComponents';
import { Card } from '../../styles/components';
import { Grid, GridInner, GridItem } from '../../styles/objects';
import { PATHS } from '../../utils/constants';
import { checkUrlMatch } from '../../utils/functions';
import {
  getPasswordFields,
  getSubNavItems,
  getUserFields,
  useFetchData,
  useHandlers,
  getAccountFields
} from './utils';

const Account = ({ hasWhiteLabelingNavbar }) => {
  const match = useRouteMatch();

  const { user, account, isFetching } = useFetchData();
  const {
    handleUserSubmit,
    handlePasswordSubmit,
    handleEditPassword,
    handleHideModal,
    handleLogout,
    handleManageSubscription,
    isProcessing,
    userMessage,
    accountMessage,
    accountError,
    userError
  } = useHandlers(user);

  const passwordFields = getPasswordFields();
  const subNavItems = getSubNavItems();
  const userFields = getUserFields();
  const accountFields = getAccountFields();

  const renderPersonalDetails = () => {
    return (
      <>
        <GridItem modifiers={{ cols: 6 }}>
          <GridInner>
            <Card>
              <Title type="h2">Signed in as</Title>

              <Loader isFetching={isFetching}>
                <Form
                  fields={userFields}
                  formData={user}
                  onSubmit={handleUserSubmit}
                  errorMessage={userError ? userMessage : null}
                  isProcessing={isFetching}
                  submitLabel="Update details"
                  submitButtonProps={{ 'data-cy': 'accountUpdateButton' }}
                />
              </Loader>
              <Space />
              <Button
                onClick={handleEditPassword}
                modifiers={{ secondary: true, lego: true }}
                data-cy="accountChangeButton"
              >
                Change password
              </Button>

              {!userError && userMessage && (
                <Notification
                  modifiers={{ error: false, spacingTop: true }}
                  data-cy="accountUpdateMessage"
                >
                  {userMessage}
                </Notification>
              )}
            </Card>
          </GridInner>
        </GridItem>

        <GridItem modifiers={{ cols: 6 }}>
          <GridInner>
            <Card>
              <Title type="h2">Current organisation</Title>

              <Loader isFetching={isFetching}>
                <Form
                  fields={accountFields}
                  formData={account}
                  onSubmit={handleManageSubscription}
                  errorMessage={accountError ? accountMessage : null}
                  isProcessing={isFetching}
                  submitLabel="Manage subscription"
                />
              </Loader>
            </Card>
          </GridInner>
        </GridItem>
      </>
    );
  };

  const renderPasswordModal = () => {
    const { path } = match;
    const modalActive = checkUrlMatch(path, '/account/details/password');

    if (!modalActive) return false;
    return (
      <Portal title="Update password" open={modalActive} onClose={handleHideModal}>
        <Form
          fields={passwordFields}
          onSubmit={handlePasswordSubmit}
          errorMessage={false}
          isProcessing={isProcessing}
          submitButtonProps={{ 'data-cy': 'accountSubmitButton' }}
        />
        {userMessage && (
          <Notification modifiers={{ error: userError, noLeftPadding: true }}>
            <p style={{ paddingLeft: '16px' }} data-cy="accountPasswordMessage">
              {userMessage}
            </p>
            {userError && (
              <Link to={PATHS.FORGOT_PASSWORD} target="_blank" rel="noopener noreferrer">
                Forgot password?
              </Link>
            )}
          </Notification>
        )}
      </Portal>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Account</title>
      </Helmet>
      <PageMain modifiers={{ hasWhiteLabelingNavbar }}>
        <Sidebar
          title="Account"
          subNav={{
            items: subNavItems,
            logOut: handleLogout
          }}
        />

        <Container>
          <Section>
            <Grid>{renderPersonalDetails()}</Grid>
          </Section>
          {renderPasswordModal()}
        </Container>
      </PageMain>
    </div>
  );
};

Account.propTypes = {
  hasWhiteLabelingNavbar: PropTypes.bool
};

Account.defaultProps = {
  hasWhiteLabelingNavbar: false
};

export default Account;
