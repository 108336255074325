import { gql } from '@apollo/client';

export const CREATE_STORAGE_MUTATION = gql`
  mutation CreateStorageMutation($input: CreateStorageInput) {
    createStorage(input: $input) {
      id
      signedGetObjectUrl
    }
  }
`;

export const DELETE_STORAGE_MUTATION = gql`
  mutation DeleteStorageMutation($storageId: ID!) {
    deleteStorage(storageId: $storageId)
  }
`;

export const CREATE_ACCOUNT_FILES_MUTATION = gql`
  mutation CreateAccountFilesMutation($storageIds: [ID]!) {
    createAccountFiles(storageIds: $storageIds)
  }
`;

export const UPDATE_ACCOUNT_MUTATION = gql`
  mutation UpdateAccountMutation($input: UpdateAccountInput) {
    updateAccount(input: $input) {
      id
      bannerId
      bannerUrl
      brandingColor
      brandingStatus
      hasBanner
      hasSkandWatermark
      bannerLogoStorageId
      bannerLogoStorageUrl
      logoId
      logoUrl
    }
  }
`;
