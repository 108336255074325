// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledTable from '../../styles/components/Table';

const TableRow = ({ cols, item, renderTableActions, hintContent, ...props }) => {
  if (!item) return false;
  return (
    <StyledTable.Row data-cy={props.dataCyTestId + 'Row'}>
      {cols &&
        cols.length &&
        cols.map((col) => (
          <StyledTable.Cell key={col.id} {...col} {...props}>
            {col.transformer ? col.transformer(item[col.key], item) : item[col.key]}
          </StyledTable.Cell>
        ))}
      {(renderTableActions || hintContent) && renderTableActions(item)}
    </StyledTable.Row>
  );
};

TableRow.propTypes = {
  renderTableActions: PropTypes.func,
  cols: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  item: PropTypes.shape({}).isRequired,
  hintContent: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
};

TableRow.defaultProps = {
  renderTableActions: null,
  hintContent: null,
};

export default TableRow;
