// Import Dependencies
import styled from 'styled-components';

// Import Gobals
import { rgba } from 'polished';
import typography from '../../global/typography';
import { colors, spacing } from '../../../utils/styles';
import { getIsBetaEnabled } from 'utils/betaEnabled';

const Label = styled.label`
  ${typography.smallCaps};
  color: ${(props) => {
    getIsBetaEnabled() && props.betaPageName === 'accountDetailsPage'
      ? '#000000'
      : rgba(colors.white, 0.5);
  }};
  display: flex;
  margin-bottom: ${spacing.xSmall};
`;

export default Label;
