import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Form, Loader } from '../../../../components';
import { getFields, useHandlers } from './utils';

const UserDetail = ({ userDetail, isFetching, error, message }) => {
  const { userId } = useParams();
  const { handleSubmit, isProcessing, message: mutationMessage } = useHandlers(userId);

  const fields = getFields();

  return (
    <Loader isFetching={isFetching}>
      <Form
        fields={fields}
        formData={userId && userDetail}
        onSubmit={handleSubmit}
        errorMessage={mutationMessage || (error && message)}
        isProcessing={isProcessing}
        submitLabel={userId ? 'Update user' : 'Send invitation'}
        submitButtonProps={{ 'data-cy': 'userDetailSubmitButton' }}
      />
    </Loader>
  );
};

UserDetail.propTypes = {
  userDetail: PropTypes.shape(),
  isFetching: PropTypes.bool,
  error: PropTypes.bool,
  message: PropTypes.string,
};

UserDetail.defaultProps = {
  userDetail: {},
  isFetching: false,
  error: false,
  message: '',
};

export default UserDetail;
