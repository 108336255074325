/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-use-before-define */
import Button from 'components/Button';
import Field from 'components/Field';
import Notification from 'components/Notification';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Divider from 'components/Divider';
import StyledForm, { Fields } from 'styles/components/Form';
import { PATHS } from 'utils/constants';
import { $tsToFix } from 'utils/types';
import {
  buildFormData,
  getFieldError,
  getFormErrors,
  isObjectEmpty
} from '../../../../utils/functions';
import { MessageGrey } from '../../loginStyles';
import { getLoginFields } from '../../utils';
import { LinkWrapper } from './formStyles';

interface ILoginFormProps {
  isProcessing: boolean;
  loginErrorMessage: string;
  sendOneTimeLoginTokenErrorMessage: string;
  handleLogin: (formData: $tsToFix) => void;
  handleLoginWithIdp: () => void;
  handleSendOneTimeLoginToken: (formData: $tsToFix) => void;
}

const loginFields = getLoginFields();
const emailField = loginFields[0];
const passwordField = loginFields[1];

const LoginForm: React.FC<ILoginFormProps> = ({
  handleLogin,
  handleLoginWithIdp,
  handleSendOneTimeLoginToken,
  isProcessing,
  loginErrorMessage
}: ILoginFormProps) => {
  const [isUsingOneTimeTokenToLogin, setIsUsingOneTimeTokenToLogin] = useState<boolean>(false);
  const [hasSentOneTimeLoginToken, setHasSentOneTimeLoginToken] = useState<boolean>(false);
  const [formData, setFormData] = useState(buildFormData(loginFields, 'value'));
  const [errors, setErrors] = useState(buildFormData(loginFields, 'error'));

  const isPasswordRequired = !isUsingOneTimeTokenToLogin;

  const continueWithPassword = () => {
    setHasSentOneTimeLoginToken(false);
    setIsUsingOneTimeTokenToLogin(false);
  };

  const validateField = (value, name, validate) => {
    const updatedErrors = getFieldError(value, validate, name, formData);
    setErrors((previousErrors) => ({
      ...previousErrors,
      [name]: updatedErrors
    }));
  };

  const handleBlur = (e, validate) => {
    const { value, name } = e.target;
    if (!validate || !validate.length) return;
    validateField(value, name, validate);
  };

  const handleChange = (e, validate, error, type) => {
    if (!type) return;

    const { value, name } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));

    if (!error || !validate || !validate.length) return;
    validateField(value, name, validate);
  };

  const submitForm = (e) => {
    e.preventDefault();

    const updatedErrors = !isPasswordRequired
      ? getFormErrors([emailField], formData)
      : getFormErrors(loginFields, formData);
    setErrors(updatedErrors);

    if (isObjectEmpty(updatedErrors)) {
      return;
    }

    if (isUsingOneTimeTokenToLogin) {
      setHasSentOneTimeLoginToken(true);
      handleSendOneTimeLoginToken(formData);
      return;
    }

    handleLogin(formData);
  };

  return (
    <StyledForm onSubmit={submitForm}>
      <Fields>
        <Field
          key={emailField.id}
          {...emailField}
          onChange={handleChange}
          onBlur={handleBlur}
          value={formData[emailField.id]}
          error={errors[emailField.id]}
          placeholder="Input email"
        />
        {isPasswordRequired && (
          <Field
            key={passwordField.id}
            {...passwordField}
            onChange={handleChange}
            onBlur={handleBlur}
            value={formData[passwordField.id]}
            error={errors[passwordField.id]}
            placeholder="Input password"
          />
        )}
      </Fields>

      {!isUsingOneTimeTokenToLogin && loginErrorMessage && (
        <Notification modifiers={{ error: true }} data-cy="loginForm Error">
          {loginErrorMessage}
        </Notification>
      )}

      {hasSentOneTimeLoginToken ? (
        <LinkWrapper>
          <MessageGrey>
            If we found a user with the email address, you will receive an email from us shortly.
          </MessageGrey>
        </LinkWrapper>
      ) : (
        <Button
          type="submit"
          disabled={isProcessing}
          modifiers={{ isProcessing, lego: true }}
          icon={null}
          css={{ width: '100%', 'max-width': '24rem', 'margin-bottom': '12px' }}
        >
          {isUsingOneTimeTokenToLogin ? 'SEND LOGIN LINK' : 'LOG IN'}
        </Button>
      )}

      {!isPasswordRequired ? (
        <LinkWrapper>
          <Button
            icon={null}
            onClick={(e) => {
              e.preventDefault();
              continueWithPassword();
            }}
            modifiers={{ textLink: true, noBorder: true }}
          >
            Continue with password
          </Button>
        </LinkWrapper>
      ) : (
        <>
          <Divider topMargin="1rem" bottomMargin="1.25rem" text="or" maxWidth="24rem" />
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleLoginWithIdp();
            }}
            disabled={isProcessing}
            modifiers={{ isProcessing, lego: true, secondary: true }}
            icon={null}
            css={{ width: '100%', 'max-width': '24rem', 'margin-bottom': '12px' }}
          >
            Enterprise SSO
          </Button>
          <LinkWrapper>
            <Link
              to={PATHS.FORGOT_PASSWORD}
              target="_blank"
              rel="noopener noreferrer"
              data-cy="loginForgotPasswordLink"
            >
              Forgot password?
            </Link>
          </LinkWrapper>
          {/* {isOneTimeLoginVisibleToUser && (
            <LinkWrapper>
              <Button
                icon={null}
                onClick={(e) => {
                  e.preventDefault();
                  continueWithOneTimeToken();
                }}
                modifiers={{ textLink: true, noBorder: true }}
              >
                Continue with login link
              </Button>
            </LinkWrapper>
          )} */}
          <MessageGrey>Don&apos;t have an account? </MessageGrey>
          <Button
            icon={null}
            onClick={(e) => {
              e.preventDefault();
              window.open('https://skand.io/pricing');
            }}
            modifiers={{ textLink: true, noBorder: true }}
          >
            Try free demo
          </Button>
        </>
      )}
    </StyledForm>
  );
};

export default LoginForm;
