// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import vars
import typography from '../../global/typography';
import { breakpoint, colors, global, spacing } from '../../../utils/styles';

const Item = styled.li`
  display: block;
  position: relative;
  width: 100%;

  a,
  button {
    &,
    &:link,
    &:visited {
      ${typography.smallCaps}
      color: ${colors.white};
      display: block;
      opacity: 0.5;
      padding: 10px 0;
      transition: opacity 200ms ${global.easing} 0s;
    }

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }

  &:first-child {
    a {
      &,
      &:link,
      &:visited {
        padding-top: 0;
      }
    }
  }

  &:after {
    background-color: ${colors.white};
    bottom: auto;
    content: '';
    display: block;
    height: 32px;
    left: auto;
    position: absolute;
    right: 0; // The size of spacing on the right of containing sidebar
    top: 50%;
    transition: transform 200ms ${global.easing} 0s;
    transform: translateY(-50%) scaleY(0);
    width: 4px;

    ${breakpoint.medium`
      right: -${spacing.medium}; // The size of spacing on the right of containing sidebar
    `};
  }

  /* Modifier: is Active */
  ${(props) =>
    getModifier(props, 'isActive') &&
    `
      &:after {
        transform: translateY(-50%) scaleY(1);
      }

      a {
        &,
        &:link,
        &:visited {
          opacity: 1;
        }
      }
  `};

  /* Modifier: is disabled */
  ${(props) =>
    getModifier(props, 'isDisabled') &&
    `
      opacity: 0.3;
      pointer-events: none;
  `};
`;

export default Item;
