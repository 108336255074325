const setFieldValue = (field: { [x: string]: any; type: string; options: any[] }, key: string) => {
  if (key === 'error') return null;
  if (field[key]) return field[key];
  if (field.type === 'multiCheckbox') {
    return field.options.map((option) => option.value);
  }
  if (field.type === 'checkboxList') {
    return [];
  }
  return '';
};

const buildFormData = (fields: any[], key: string) =>
  fields.reduce(
    (a, field) => ({
      ...a,
      [field.name]: setFieldValue(field, key),
    }),
    {}
  );

export default buildFormData;
