// Import Dependencies
import styled from 'styled-components';

// Import Gobals
import { spacing } from '../../../utils/styles';

const Hint = styled.div`
  position: absolute;
  right: ${spacing.xxSmall};
  top: 0;
`;

export default Hint;
