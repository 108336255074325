// Import Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';

// Import Components
import NavItem from '../NavItem';

// Import Styled Components
import StyledSubNav from '../../styles/components/SubNav';

const SubNav = ({
  title, items, logOut, ...props
}) => (
  <StyledSubNav {...props}>
    {title && <StyledSubNav.Title type="h3">{title}</StyledSubNav.Title>}
    <StyledSubNav.Inner>
      {items && items.map((item, i) => item && <NavItem key={item.id} i={i} subNav {...props} {...item} />)}
      {logOut && (
        <StyledSubNav.Item {...props}>
          <button onClick={logOut}>Log out</button>
        </StyledSubNav.Item>
      )}
    </StyledSubNav.Inner>
  </StyledSubNav>
);

SubNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  logOut: PropTypes.func,
  title: PropTypes.string,
};

SubNav.defaultProps = {
  logOut: null,
  title: null,
};

export default withRouter(SubNav);
