// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import Elements
import Actions from './Actions';
import Fields from './Fields';
import ErrorMessage from './ErrorMessage';
import Legend from './Legend';

// Import Globals
import { spacing } from '../../../utils/styles';

const Form = styled.form`
  /* Modifier: storybook */
  ${(props) =>
    getModifier(props, 'storybook') &&
    `
    width: 400px;
  `};

  /* Modifier: noMarginBottom */
  ${(props) =>
    getModifier(props, 'noMarginBottom') &&
    `
    margin-bottom: 0;
  `};
`;

// Set elements
Form.Actions = Actions;
Form.ErrorMessage = ErrorMessage;
Form.Legend = Legend;
Form.Fields = Fields;

export default Form;
export { Actions, ErrorMessage, Legend, Fields };
