// Import Dependencies
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// Import Styled Components
import StyledField from '../../styles/components/Field';
// Import Utilities
import { stringify } from '../../utils/functions';
import Checkbox from '../Checkbox';
import CheckboxList from '../CheckboxList';
import FauxField from '../FauxField';
import Hint from '../Hint';
// Import Components
import * as Icons from '../Icons';
import MultiCheckbox from '../MultiCheckbox';
import PlaceAutocomplete from '../PlaceAutocomplete';
import Radio from '../Radio';
import SearchInput from '../SearchInput';
import Select from '../Select';
import TextArea from '../TextArea';
import TextInput from '../TextInput';

class Field extends Component {
  // Set default form data
  state = {
    fieldOpen: (this.props.collapsible && this.props.collapsibleOpen) || !this.props.collapsible,
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      fieldOpen: !prevState.fieldOpen,
    }));
  };

  handleBlur = (e) => {
    const { onBlur, validate } = this.props;
    if (onBlur) onBlur(e, validate);
  };

  handleChange = (e) => {
    const { onChange, validate, error, type } = this.props;
    onChange(e, validate, error, type);
  };

  handleDatePickerChange = (selectedValue) => {
    const { name } = this.props;
    const eventObject = { target: { name, value: selectedValue, checked: false } };
    this.handleChange(eventObject);
  };

  handleSelectChange = (selectedOption) => {
    const { name } = this.props;
    const eventObject = {
      target: { name, value: selectedOption && selectedOption.value, checked: false },
    };
    this.handleChange(eventObject);
  };

  renderField() {
    const { type, onBlur, onChange, ...props } = this.props;

    if (type === 'select') {
      return <Select onBlur={this.handleBlur} onChange={this.handleSelectChange} {...props} />;
    }

    if (type === 'search') {
      return (
        <SearchInput onBlur={this.handleBlur} onChange={this.handleChange} type="text" {...props} />
      );
    }

    if (type === 'checkbox') {
      return <Checkbox onBlur={this.handleBlur} onChange={this.handleChange} {...props} />;
    }

    if (type === 'multiCheckbox') {
      return <MultiCheckbox onBlur={this.handleBlur} onChange={this.handleChange} {...props} />;
    }

    if (type === 'checkboxList') {
      return <CheckboxList onBlur={this.handleBlur} onChange={this.handleChange} {...props} />;
    }

    if (type === 'radio') {
      return <Radio onBlur={this.handleBlur} onChange={this.handleChange} {...props} />;
    }

    if (type === 'places') {
      return <PlaceAutocomplete onChange={this.handleChange} {...props} type="text" />;
    }

    if (type === 'fauxField') return <FauxField {...props} />;

    if (type === 'textArea') {
      return <TextArea onBlur={this.handleBlur} onChange={this.handleChange} {...props} />;
    }

    return (
      <TextInput
        modifiers={{ lego: true }}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        type={type}
        {...props}
      />
    );
  }

  render() {
    const {
      id,
      'data-cy': dataCy,
      label,
      error,
      tooltip,
      collapsible,
      render,
      hintContent,
      isRequired,
      betaPageName,
      ...props
    } = this.props;
    const { fieldOpen } = this.state;

    // If field has a custom render prop - return here
    if (render) return render();

    return (
      <StyledField modifiers={{ hasBorderBottom: collapsible }} {...props}>
        {label && (
          <StyledField.Label betaPageName={betaPageName} htmlFor={id}>
            {label}
            {isRequired && <StyledField.RequiredStar>&nbsp;*</StyledField.RequiredStar>}
            {collapsible && (
              <StyledField.Toggle
                onClick={this.handleToggle}
                type="button"
                modifiers={{ isActive: fieldOpen }}
              >
                <Icons.ChevronDown />
              </StyledField.Toggle>
            )}
          </StyledField.Label>
        )}
        {fieldOpen && (
          <div>
            {this.renderField()}
            {error && (
              <StyledField.InlineError data-cy={dataCy + 'Error'}>
                {stringify(error)}
              </StyledField.InlineError>
            )}
            {tooltip && <StyledField.InlineTooltip>{tooltip}</StyledField.InlineTooltip>}
          </div>
        )}

        {hintContent && (
          <StyledField.Hint>
            <Hint {...hintContent} />
          </StyledField.Hint>
        )}
      </StyledField>
    );
  }
}

Field.propTypes = {
  collapsible: PropTypes.bool,
  collapsibleOpen: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  hintContent: PropTypes.shape({ title: PropTypes.string, content: PropTypes.string }),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  render: PropTypes.func,
  tooltip: PropTypes.string,
  type: PropTypes.string.isRequired,
  validate: PropTypes.arrayOf(PropTypes.func),
  betaPageName: PropTypes.string
};

Field.defaultProps = {
  collapsible: false,
  collapsibleOpen: false,
  error: null,
  hintContent: null,
  label: null,
  name: null,
  onBlur: null,
  render: null,
  tooltip: null,
  validate: null,
  betaPageName: null
};

export default Field;
