import { getName } from '../../../utils/functions';

const getTableCols = (users) => [
  { id: '1', label: 'Name', key: 'name' },
  { id: '2', label: 'Code', key: 'code' },
  {
    id: '3',
    label: 'Manager',
    key: 'manager',
    transformer: (value) => getName(users, value, 'No manager'),
  },
  {
    id: '4',
    label: 'Buildings',
    key: 'buildingsCount',
  },
];

export default getTableCols;
