// Import Dependencies
import styled from 'styled-components';

// Import Gobals
import { colors } from '../../../utils/styles';
import typography from '../../global/typography';

const ErrorMessage = styled.p`
  ${typography.base};
  color: ${colors.error};
`;

export default ErrorMessage;
