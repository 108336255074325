/**
 * Function to convert a string into a number, handy for processing
 * values from form data
 */

export function stringToNumber(string) {
  return parseFloat(string);
}

export default stringToNumber;
