import { gql } from '@apollo/client';

export const UPDATE_CACHE_FOR_INVITE_USER_MUTATION = gql`
  fragment newUser on User {
    id
    firstName
    lastName
    email
  }
`;
