import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FilterBar, Notification, SubNav, Table, Title } from '../../components';
import { Confirmation, PageMain } from '../../skandComponents';
import { Actions, StyledTable } from '../../styles/components';
import { sortListByLowercaseName } from '../../utils/functions';
import {
  getFilterTabs,
  getSubNavItems,
  getTableCols,
  nameOrCodeIncludes,
  useFetchData,
  useHandlers
} from './utils';

const Sites = ({ hasWhiteLabelingNavbar }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { sessionData, sites, users, isFetching, error, message } = useFetchData();
  const {
    // handleAdd,
    // handleEdit,
    // handleDeleteConfirmation,
    handleDelete
  } = useHandlers(setConfirmationOpen);

  const { accountId } = sessionData;
  const filterTabs = getFilterTabs(accountId);
  const activeTab = filterTabs[0].id;
  const subNavItems = getSubNavItems();
  const tableCols = getTableCols(users);

  const renderTableActions = (item) => {
    if (item.accountId !== accountId) return <StyledTable.Cell />;

    return (
      <StyledTable.Cell modifiers={{ actions: true }}>
        <Actions>
          {/* <Actions.Item>
            <TableAction
              id={item.id}
              label={<Icons.Edit />}
              action={handleEdit}
              modifiers={{ iconOnly: true }}
              data-cy="sitesEditButton"
            />
          </Actions.Item> */}
          {/* <Actions.Item>
            <TableAction
              id={item.id}
              label={<Icons.Close />}
              action={handleDeleteConfirmation}
              modifiers={{ iconOnly: true }}
            />
          </Actions.Item> */}
        </Actions>
      </StyledTable.Cell>
    );
  };

  // eslint-disable-next-line react/prop-types
  const renderBody = ({ data }) => {
    const sortedSites = sortListByLowercaseName(data);

    return (
      <div>
        {message && <Notification modifiers={{ error }}>{message}</Notification>}
        <Table
          cols={tableCols}
          data={sortedSites}
          renderTableActions={renderTableActions}
          isFetching={isFetching}
          dataCyTestId="sitesTable"
        />
      </div>
    );
  };

  const renderActions = () => {
    return (
      // <Button onClick={handleAdd} modifiers={{ fullWidth: true }}>
      //   Add a site
      // </Button>
      <></>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Projects</title>
      </Helmet>
      <PageMain modifiers={{ hasWhiteLabelingNavbar }}>
        <FilterBar
          render={renderBody}
          renderActions={renderActions}
          activeTab={activeTab}
          filterTabs={filterTabs}
          filterFunction={nameOrCodeIncludes}
          placeholder="Name or code"
          data={sites}
          isFetching={isFetching}
          dataCyTestId="sitesSearchBar"
        >
          <Title type="h2">Manage</Title>
          <SubNav items={subNavItems} modifiers={{ borderBottom: true }} />
        </FilterBar>
      </PageMain>
      <Confirmation
        open={confirmationOpen}
        setOpen={setConfirmationOpen}
        message="Are you sure you want to delete this site?"
        submitAction={handleDelete}
      />
    </div>
  );
};

Sites.propTypes = {
  hasWhiteLabelingNavbar: PropTypes.bool
};

Sites.defaultProps = {
  hasWhiteLabelingNavbar: false
};

export default Sites;
