// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { rgba } from 'polished';
import { getModifier } from '../../../utils/functions';

// Import elements
import Inner from './Inner';
import Item from './Item';
import Title from './Title';

// Import vars
import { colors, spacing } from '../../../utils/styles';

const SubNav = styled.div`
  border-top: solid 1px ${rgba(colors.grey400, 0.8)};
  margin-bottom: ${spacing.medium};
  padding-top: ${spacing.medium};

  /* Modifier: borderBottom */
  ${(props) =>
    getModifier(props, 'borderBottom') &&
    `
      border-bottom: solid 1px ${rgba(colors.grey400, 0.8)};
      padding-bottom: ${spacing.base}
  `};
`;

// Set elements
SubNav.Inner = Inner;
SubNav.Item = Item;
SubNav.Title = Title;

export default SubNav;
