import PropTypes from 'prop-types';
import React from 'react';
import { Divider as StyledDivider } from '../../styles/components/Divider';

const Divider = ({ topMargin, bottomMargin, text, maxWidth }) => {
  return (
    <StyledDivider topMargin={topMargin} bottomMargin={bottomMargin} maxWidth={maxWidth}>
      {text}
    </StyledDivider>
  );
};

Divider.propTypes = {
  topMargin: PropTypes.string,
  bottomMargin: PropTypes.string,
  maxWidth: PropTypes.string,
  text: PropTypes.string.isRequired,
};

Divider.defaultProps = {
  topMargin: "1rem",
  bottomMargin: "1rem",
  maxWidth: "100%"
};

export default Divider;
