// Import Dependencies
import styled from 'styled-components';

const Total = styled.dl`
  display: inline-block;
  left: 50%;
  margin: 0;
  padding-right: 120px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export default Total;
