// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const CrossBold = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path d="M500 84L416 0 250 166 84 0 0 84l166 166L0 416l84 84 166-166 166 166 84-84-166-166z" />
  </Icon>
);

export default CrossBold;
