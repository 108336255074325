import { matchPath } from 'react-router';

import { $tsToFix } from '../types';

const checkUrlMatch = (pathname: $tsToFix, path: $tsToFix) => {
  const match = matchPath(pathname, { path });
  return (match && match.isExact) || (match && match.path !== '/');
};

export default checkUrlMatch;
