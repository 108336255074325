// Import Dependencies
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Import Styled Componenys
import StyledCheckbox from '../../styles/components/Checkbox';

class MultiCheckbox extends PureComponent {
  componentDidMount = () => {
    const { name, value, onChange } = this.props;
    if (name === 'id' && !value.length) {
      onChange({
        target: {
          name,
          value: [],
        },
      });
    }
  };
  handleAllChange = (e) => {
    const { onChange, name, options } = this.props;
    onChange({
      ...e,
      target: {
        name,
        value: e.target.checked ? options.map((option) => option.value) : [],
      },
    });
  };

  render() {
    const {
      options,
      value,
      name,
      id,
      label,
      sites,
      selectedCaptures,
      setSelectedCaptures,
      renderAfterCheckbox,
      renderAfterOption,
      ...props
    } = this.props;

    if (options && !options.length) {
      return <p>No {label} available</p>;
    }

    const isSiteSelection = id === 'id';

    return (
      <div>
        <StyledCheckbox>
          <StyledCheckbox.Input
            type="checkbox"
            id={id}
            name={name}
            onChange={this.handleAllChange}
            checked={value?.length === options.length || false}
          />
          <StyledCheckbox.Label htmlFor={id}>Select all</StyledCheckbox.Label>
        </StyledCheckbox>
        {options.map((option) => {
          const checked = !!(
            value?.length &&
            typeof value !== 'string' &&
            value.find((item) => item === option.value)
          );

          return (
            <StyledCheckbox key={option.id}>
              <StyledCheckbox.Input
                type="checkbox"
                checked={checked}
                id={id + option.id}
                name={name}
                value={option.value}
                {...props}
              />
              <StyledCheckbox.Label htmlFor={id + option.id}>{option.label}</StyledCheckbox.Label>
              {renderAfterOption && renderAfterOption(option.value)}
              {isSiteSelection &&
                checked &&
                renderAfterCheckbox &&
                renderAfterCheckbox({
                  value: option.value,
                  name,
                  id: option.id,
                  label: option.label,
                  sites,
                  selectedCaptures,
                  setSelectedCaptures,
                })}
            </StyledCheckbox>
          );
        })}
      </div>
    );
  }
}

MultiCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape()),
  renderAfterOption: PropTypes.func,
  renderAfterCheckbox: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
};

MultiCheckbox.defaultProps = {
  label: null,
  value: null,
  sites: [],
  renderAfterOption: null,
  renderAfterCheckbox: null,
};

export default MultiCheckbox;
