import { isRequired, isValidPassword } from '../../../utils/validators';

const getPasswordFields = () => [
  {
    id: 'currentPassword',
    name: 'currentPassword',
    label: 'Current Password',
    type: 'password',
    isRequired: true,
    validate: [isRequired, isValidPassword],
    'data-cy': 'accountCurrentPasswordField',
  },
  {
    id: 'password',
    name: 'password',
    label: 'New Password',
    type: 'password',
    isRequired: true,
    validate: [isRequired, isValidPassword],
    'data-cy': 'accountNewPasswordField',
  },
  {
    id: 'passwordConfirm',
    name: 'passwordConfirm',
    label: 'Confirm New Password',
    type: 'password',
    isRequired: true,
    validate: [isRequired, isValidPassword],
    'data-cy': 'accountPasswordConfirmField',
  },
];

export default getPasswordFields;
