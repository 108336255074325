import React from 'react';

import StyledContainer from './containerStyles';

type ContainerProps = {
  children?: React.ReactNode;
  style?: {};
  modifiers?: {};
};

const Container = ({ children = null, style = {}, modifiers = {} }: ContainerProps) => (
  <StyledContainer style={style} modifiers={modifiers}>
    {children}
  </StyledContainer>
);

export default Container;
