// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Operations = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.95 31.951" {...props}>
    <g>
      <g>
        <path d="M21.009,22.119h-9.782c-1.809,0-3.3,1.502-3.3,3.312v5.1h16.339v-5.1C24.268,23.621,22.816,22.119,21.009,22.119z" />
        <circle cx="16.117" cy="15.565" r="4.696" />
        <path d="M8.997,9.43c0-0.997-0.803-1.803-1.798-1.803h-5.38C0.824,7.627,0,8.434,0,9.43v2.817h8.997V9.43z" />
        <circle cx="4.509" cy="4.002" r="2.583" />
        <path d="M30.177,7.627h-5.38c-0.995,0-1.795,0.806-1.795,1.803v2.817h8.948V9.431C31.95,8.434,31.172,7.627,30.177,7.627z" />
        <circle cx="27.486" cy="4.002" r="2.583" />
        <path
          d="M12.138,7.822h8.266c0.57,0,1.033-0.45,1.033-1.021c0-0.571-0.463-1.022-1.033-1.022h-8.266
			c-0.571,0-1.033,0.451-1.033,1.022C11.105,7.371,11.567,7.822,12.138,7.822z"
        />
        <path
          d="M8.838,20.958c0.21,0,0.422-0.062,0.606-0.195c0.462-0.336,0.564-0.981,0.23-1.442l-3.77-5.205
			c-0.336-0.464-0.981-0.566-1.443-0.231c-0.463,0.335-0.565,0.981-0.23,1.443L8,20.534C8.203,20.812,8.519,20.958,8.838,20.958z"
        />
        <path
          d="M26.945,13.982c-0.48-0.31-1.118-0.168-1.426,0.312l-3.252,5.075c-0.31,0.479-0.168,1.119,0.312,1.429
			c0.172,0.108,0.364,0.161,0.558,0.161c0.34,0,0.674-0.166,0.87-0.476l3.251-5.073C27.566,14.931,27.426,14.291,26.945,13.982z"
        />
      </g>
    </g>
  </Icon>
);

export default Operations;
