/* eslint-disable complexity */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Container from 'styles/objects/Container';
import Section from 'styles/objects/Section';
import { Checkbox, Loader, SubNav, Title } from '../../components';
import { PageMain } from '../../skandComponents';
import Sidebar from '../../styles/objects/Sidebar';
import defaultReportLogo from './assets/defaultReportLogo.svg';
import defaultSharedSiteLogo from './assets/defaultSharedSiteLogo.svg';
import {
  CheckboxSection,
  ColorLabel,
  ColorPickerSection,
  ColorTitle,
  PageLink,
  PageText
} from './brandingStyles';
import { BrandingSection, BrandingUploader, ColorPicker, LearnMoreSection } from './components';
import { getSubNavItems, useFetchData, useHandlers } from './utils';

const Branding = () => {
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [uploaderOpen, setUploaderOpen] = useState(false);
  const [logoType, setLogoType] = useState(null);
  const [tempLogoData, setTempLogoData] = useState(null);
  const [tempReportData, setTempReportData] = useState(null);
  const [tempBannerData, setTempBannerData] = useState(null);
  const [newBrandingColor, setNewBrandingColor] = useState(null);
  const subNavItems = getSubNavItems();

  const { account, isFetching } = useFetchData();
  const {
    handlePoweredBySkandClick,
    handleReportColorUpdate,
    handleReportLogoDelete,
    handleReportLogoError,
    handleReportLogoUpload,
    handleReportPreview,
    handleBannerLogoUpload,
    handleBannerLogoDelete,
    handleBannerLogoError,
    handleBannerColorUpdate,
    handleSharedSiteLogoDelete,
    handleSharedSiteLogoError,
    handleSharedSiteLogoUpload,
    handleSharedSitePreview
  } = useHandlers({
    setLogoType,
    setTempReportData,
    setTempBannerData,
    setTempLogoData,
    setUploaderOpen,
    setNewBrandingColor,
    accountId: account.id
  });

  const {
    logoId,
    logoUrl,
    bannerId,
    bannerUrl,
    bannerLogoStorageId,
    bannerLogoStorageUrl,
    hasSkandWatermark,
    brandingStatus,
    brandingColor
  } = account;

  return (
    <div>
      <Helmet>
        <title>Branding</title>
      </Helmet>
      <PageMain>
        <Sidebar>
          <Sidebar.Inner>
            <Title type="h2">Manage</Title>
            <SubNav items={subNavItems} modifiers={{ borderBottom: true }} />
          </Sidebar.Inner>
        </Sidebar>

        <Loader isFetching={isFetching}>
          {brandingStatus ? (
            <Container>
              <Section>
                <>
                  <Title type="h3">Branding</Title>
                  <PageText>
                    The branding package allows for further branding customisation of banner,
                    reports and shared links.{' '}
                    <PageLink
                      href="https://support.skand.io/portal/en/kb/articles/branding-package"
                      target="_blank"
                    >
                      Learn more here!
                    </PageLink>
                  </PageText>
                  <PageText>
                    Please ensure your images are in either JPG or PNG format before uploading.
                  </PageText>

                  <BrandingSection
                    title="WEBAPP BANNER"
                    logoSize="Height - 40 px"
                    tempLogoData={tempBannerData}
                    logoUrl={bannerLogoStorageUrl}
                    logoId={bannerLogoStorageId}
                    defaultLogo={defaultSharedSiteLogo}
                    handelLogoError={handleBannerLogoError}
                    handelLogoUpload={handleBannerLogoUpload}
                    handleLogoDelete={handleBannerLogoDelete}
                    isUploadingLogo={isUploadingLogo}
                  />

                  <ColorTitle>BRAND COLOUR</ColorTitle>
                  <ColorPickerSection>
                    <ColorPicker
                      selectedColor={newBrandingColor || brandingColor}
                      setSelectedColor={setNewBrandingColor}
                      handleClose={handleBannerColorUpdate}
                    />
                    <ColorLabel>{newBrandingColor || brandingColor}</ColorLabel>
                  </ColorPickerSection>

                  <BrandingSection
                    title="REPORT LOGO"
                    logoSize="160 * 90 px"
                    tempLogoData={tempReportData}
                    logoUrl={bannerUrl}
                    logoId={bannerId}
                    defaultLogo={defaultReportLogo}
                    handelLogoError={handleReportLogoError}
                    handelLogoUpload={handleReportLogoUpload}
                    handleLogoDelete={handleReportLogoDelete}
                    handlePreview={handleReportPreview}
                    hasTooltip
                    tooltipTexts={[
                      'REPORT LOGO',
                      'The updated logo will reflect on newly created reports automatically.',
                      'On previously created reports the updated logo will only change after updating the report'
                    ]}
                    isUploadingLogo={isUploadingLogo}
                    isNotBannerLogoSection
                  />

                  <ColorTitle>REPORT PDF BANNER COLOUR</ColorTitle>
                  <ColorPickerSection>
                    <ColorPicker
                      selectedColor={newBrandingColor || brandingColor}
                      setSelectedColor={setNewBrandingColor}
                      handleClose={handleReportColorUpdate}
                    />
                    <ColorLabel>{newBrandingColor || brandingColor}</ColorLabel>
                  </ColorPickerSection>

                  <BrandingSection
                    title="SHARE LINK LOGO"
                    logoSize="62 * 62 px"
                    tempLogoData={tempLogoData}
                    logoUrl={logoUrl}
                    logoId={logoId}
                    defaultLogo={defaultSharedSiteLogo}
                    handelLogoError={handleSharedSiteLogoError}
                    handelLogoUpload={handleSharedSiteLogoUpload}
                    handleLogoDelete={handleSharedSiteLogoDelete}
                    handlePreview={handleSharedSitePreview}
                    isUploadingLogo={isUploadingLogo}
                    isNotBannerLogoSection
                  />

                  <CheckboxSection>
                    <Checkbox
                      modifiers={{ lego: true }}
                      name="hasSkandWatermark"
                      value={hasSkandWatermark}
                      options={[
                        {
                          id: 'hasSkandWatermark',
                          label: 'Including Powered by Skand watermark',
                          value: true
                        }
                      ]}
                      onChange={handlePoweredBySkandClick}
                    />
                  </CheckboxSection>

                  <BrandingUploader
                    accountId={account?.id}
                    logoType={logoType}
                    modalOpen={uploaderOpen}
                    setIsUploading={setIsUploadingLogo}
                    setModalOpen={setUploaderOpen}
                    setTempBannerData={setTempBannerData}
                    setTempReportData={setTempReportData}
                    setTempLogoData={setTempLogoData}
                  />
                </>
              </Section>
            </Container>
          ) : (
            <LearnMoreSection />
          )}
        </Loader>
      </PageMain>
    </div>
  );
};

export default Branding;
