// Import Dependencies
import styled from 'styled-components';

// Import Elements
import Info from './Info';
import Title from './Title';
import Close from './Close';

// Import vars
import { colors, global } from '../../../utils/styles';

const Hint = styled.button`
  display: inline-block;
  transition: opacity ${global.transitionSpeed} ${global.easing} 0s;

  svg {
    fill: ${colors.blue500};
    height: 20px;
    pointer-events: none;
    width: 20px;
  }

  &:hover {
    opacity: 0.5;
  }
`;

Hint.Info = Info;
Hint.Title = Title;
Hint.Close = Close;

export default Hint;
