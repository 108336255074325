// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Componenys
import StyledRadio from '../../styles/components/Radio';

const Radio = ({
  options, value, name, ...props
}) => (
  <div>
    {options.map(option => (
      <StyledRadio key={option.id}>
        <StyledRadio.Input
          type="radio"
          checked={value === option.value}
          name={name}
          {...props}
          {...option}
        />
        <StyledRadio.Label htmlFor={option.id}>
          {option.label}
        </StyledRadio.Label>
      </StyledRadio>
      ))}
  </div>
);

Radio.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
};

Radio.defaultProps = {
  value: '',
};

export default Radio;
