// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { CheckBox } from '@skand/lego';

// Import Styled Componenys
import StyledCheckbox, { CheckboxLegoLabel } from '../../styles/components/Checkbox';

const Checkbox = ({
  modifiers, options, value, name, id, error: hasError, ...props
}) => {
  if (modifiers.lego) {
    return (
      <div>
        {options.map(option => (
          <CheckBox
            key={option.id}
            name={name}
            checked={value}
            tone="dark"
            {...props}
          >
            <CheckboxLegoLabel checked={value}>{option.label}</CheckboxLegoLabel>
          </CheckBox>
        ))}
      </div>
    );
  }

  return (
    <div>
      {options.map(option => (
        <StyledCheckbox key={option.id}>
          <StyledCheckbox.Input type="checkbox" checked={value} name={name} {...props} {...option} />
          <StyledCheckbox.Label htmlFor={option.id} modifiers={{ hasError }}>
            {option.label}
          </StyledCheckbox.Label>
        </StyledCheckbox>
      ))}
    </div>
  );
};

Checkbox.propTypes = {
  modifiers: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  })).isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.string,
  error: PropTypes.arrayOf(PropTypes.string),
};

Checkbox.defaultProps = {
  modifiers: {},
  value: null,
  label: null,
  error: null,
};

export default Checkbox;
