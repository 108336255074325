// Import Dependencies
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';

// Import Styled Components
import StyledChart from '../../styles/components/Chart';
import Loader from '../Loader';

// Import Utilities
import { getBarChartColor } from '../../utils/functions';
import { colors } from '../../utils/styles';

class BarChart extends PureComponent {
  renderColour = (node) => getBarChartColor(node.id);

  renderBar = ({ y, x, color, width, height }) => {
    const defaultColor = colors.blue500;
    const transform = `translate(${(width - 8) / 2}, 0)`;
    return (
      <g transform={transform}>
        <rect y={y} x={x} fill={defaultColor} width={8} height={height} rx={4} ry={4} />
        <rect y={y + 4} x={x} fill={defaultColor} width={8} height={Math.abs(height - 4)} />
      </g>
    );
  };

  renderLegend = () => {
    const { keys } = this.props;

    return (
      <StyledChart.Legend modifiers={{ topRight: true }}>
        {keys &&
          keys.length > 1 &&
          keys.map((item) => (
            <StyledChart.Label
              key={item}
              modifiers={{ color: getBarChartColor(item), marginLeft: true }}
            >
              {item}
            </StyledChart.Label>
          ))}
      </StyledChart.Legend>
    );
  };

  render() {
    const { data, isFetching, keys, indexBy } = this.props;
    if (!data) return <p>No chart data found</p>;

    return (
      <StyledChart.Container modifiers={{ fontWeightMedium: true, bar: true }}>
        <Loader isFetching={isFetching}>
          {this.renderLegend()}
          <ResponsiveBar
            theme={{
              axis: {
                fontSize: '12px',
                ticks: {
                  text: {
                    fill: `${colors.white}`,
                  },
                },
              },
            }}
            axisBottom={{
              orient: 'bottom',
              tickPadding: 20,
              tickSize: 0,
            }}
            axisLeft={{
              orient: 'left',
              tickPadding: 20,
              tickSize: 0,
            }}
            margin={{
              bottom: 35,
              left: 40,
              right: 20,
              top: 5,
            }}
            enableGridX={false}
            enableGridY={false}
            isInteractive={false}
            colorBy={this.renderColour}
            barComponent={this.renderBar}
            innerPadding={8}
            padding={0.5}
            data={data}
            groupMode="grouped"
            enableLabel={false}
            keys={keys}
            indexBy={indexBy}
          />
        </Loader>
      </StyledChart.Container>
    );
  }
}

BarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isFetching: PropTypes.bool,
  keys: PropTypes.arrayOf(PropTypes.string),
  indexBy: PropTypes.string,
};

BarChart.defaultProps = {
  data: null,
  isFetching: false,
  keys: [],
  indexBy: '',
};

export default BarChart;
