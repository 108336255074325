// Import Dependencies
import styled from 'styled-components';

// Import vars
import { colors, fontSize, global, spacing } from '../../../utils/styles';

const FilterTab = styled.li`
  margin-right: ${spacing.medium};

  label {
    color: ${colors.grey300};
    cursor: pointer;
    display: block;
    font-size: ${fontSize.small};
    font-weight: bold;
    margin-bottom: -1px;
    padding-bottom: 10px;
    position: relative;
    text-transform: uppercase;
    transition: color ${global.transitionSpeed} ${global.easing},
      border ${global.transitionSpeed} ${global.easing};

    &:hover {
      color: ${colors.white};
    }

    &:after {
      background-color: ${colors.orange};
      bottom: 0;
      content: '';
      display: block;
      height: 4px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%) scale(0);
      transition: transform 200ms ${global.easing} 0s;
      width: 100%;
    }
  }

  input[type='radio'] {
    left: -5000px;
    position: absolute;

    &:checked ~ label {
      color: ${colors.white};

      &:after {
        background-color: ${colors.orange};
        transform: translateX(-50%) scale(1);
      }
    }
  }
`;

export default FilterTab;
