// Import Dependencies
import styled from 'styled-components';

// Import Elements
import { rgba } from 'polished';
import Item from './Item';

// Import vars
import { colors, global } from '../../../utils/styles';

const TextInputSuggestions = styled.div`
  background: ${rgba(colors.grey400, 0.5)};
  border-color: transparent;
  background: ${colors.grey600};
  box-shadow: ${global.shadowDropdown};
`;

TextInputSuggestions.Item = Item;

export default TextInputSuggestions;
