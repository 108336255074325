import PropTypes from 'prop-types';
import ReactColorPicker from 'rc-color-picker';
import 'rc-color-picker/assets/index.css';
import React from 'react';
import { StyledContainer } from './colorPickerStyles';

const ColorPicker = ({ selectedColor, setSelectedColor, handleOpen, handleClose, disabled }) => {
  const handleChange = ({ color }) => {
    setSelectedColor(color);
  };

  return (
    <StyledContainer disabled={disabled}>
      <ReactColorPicker
        color={selectedColor}
        enableAlpha={false}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        placement="bottomRight"
      />
    </StyledContainer>
  );
};

ColorPicker.propTypes = {
  selectedColor: PropTypes.string.isRequired,
  setSelectedColor: PropTypes.func.isRequired,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
  disabled: PropTypes.bool
};

ColorPicker.defaultProps = {
  handleOpen: () => {
    console.log('colorPicker opened');
  },
  handleClose: () => {
    console.log('colorPicker closed');
  },
  disabled: false
};

export default ColorPicker;
