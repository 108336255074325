/* eslint-disable complexity */
const getFieldValue = (type, value, checked, previousState) => {
  // Checkboc value
  if (type === 'checkbox') return checked;

  // Create array of values of multiCheckbox
  if ((type === 'multiCheckbox' || type === 'checkboxList') && !Array.isArray(value)) {
    if (checked) {
      if (undefined === previousState) {
        return [value];
      }
      return [...previousState, value];
    }
    const i = previousState.findIndex((item) => item === value);
    return [...previousState.slice(0, i), ...previousState.slice(i + 1)];
  }

  // by default return value
  return value;
};

export default getFieldValue;
