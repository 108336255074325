import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './demoConfirmStyles';

import { PATHS } from '../../utils/constants';

const DemoConfirm = () => {
  return (
    <Container>
      <div>
        <p>We had received your demo request. Our team will contact you as soon as possible.</p>
      </div>
      <div>
        <Link to={PATHS.LOGIN} data-cy="demoConfirmGoBackLink">
          Go back to login page
        </Link>
      </div>
    </Container>
  );
};

export default DemoConfirm;
