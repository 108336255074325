import { baseFontFamily, colors, fontSize, fontWeight } from '../../utils/styles';

const typography = {
  headingXLarge: `
    font-family: ${baseFontFamily};
    font-size: ${fontSize.xLarge};
    font-weight: ${fontWeight.bold};
    line-height: 1.3;
  `,
  headingLarge: `
    font-family: ${baseFontFamily};
    font-size: ${fontSize.large};
    font-weight: ${fontWeight.bold};
    line-height: 1.48;
  `,
  headingMedium: `
    font-family: ${baseFontFamily};
    font-size: ${fontSize.medium};
    font-weight: ${fontWeight.medium};
    line-height: 1.45;
  `,
  headingBase: `
    font-family: ${baseFontFamily};
    font-size: ${fontSize.small};
    font-weight: ${fontWeight.medium};
    line-height: 1.5;
  `,
  body: `
    font-family: ${baseFontFamily};
    font-size: ${fontSize.base};
    font-weight: ${fontWeight.normal};
    line-height: 1.5;
  `,
  callToAction: `
    font-family: ${baseFontFamily};
    font-size: ${fontSize.base};
    font-weight: ${fontWeight.medium};
    line-height: 1.5;
  `,
  label: `
    font-family: ${baseFontFamily};
    font-size: ${fontSize.xSmall};
    font-weight: ${fontWeight.medium};
    line-height: 1.5;
  `,
  smallCaps: `
    font-family: ${baseFontFamily};
    font-size: ${fontSize.xSmall};
    font-weight: ${fontWeight.medium};
    line-height: ${18 / 12};
    text-transform: uppercase;
  `,
  link: `
    border-bottom: solid 1px transparent;
    color: ${colors.orange};
    display: inline;
    font-family: ${baseFontFamily};
    font-size: ${fontSize.xSmall};
    font-weight: ${fontWeight.medium};
    line-height: 1.5;
    text-transform: uppercase;

    &:hover {
      border-color: ${colors.orange};
    }
  `,
  textLink: `
    border-bottom: solid 1px transparent;
    display: inline;
    font-family: ${baseFontFamily};
    font-size: ${fontSize.base};
    font-weight: ${fontWeight.normal};
    line-height: 1.5;

    &:hover {
      border-color: ${colors.orange};
    }
  `,
};

export default typography;
