// eslint-disable-next-line complexity
const formatPlace = (place) => {
  const segments = place.address_components;
  const { location } = place.geometry;

  const streetNumber = segments.find((s) => s.types[0] === 'street_number');
  const route = segments.find((s) => s.types[0] === 'route');
  const locality = segments.find((s) => s.types[0] === 'locality');
  const region = segments.find((s) => s.types[0] === 'administrative_area_level_1');
  const postCode = segments.find((s) => s.types[0] === 'postal_code');
  const country = segments.find((s) => s.types[0] === 'country');

  const formattedAddress = {
    streetAddress: `${streetNumber && streetNumber.short_name} ${route && route.short_name}`,
    locality: locality ? locality.long_name : null,
    region: region ? region.short_name : null,
    postalCode: postCode ? postCode.short_name : null,
    country: country ? country.long_name : null,
    latitude: location.lat(),
    longitude: location.lng(),
  };

  return formattedAddress;
};

export default formatPlace;
