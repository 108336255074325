import styled from 'styled-components';

export const NullHeaderLogo = styled.div`
  height: 62px;
  width: 62px;
`;

export const CustomizedHeaderLogo = styled.img`
  height: 62px;
  object-fit: cover;
  width: 62px;
`;
