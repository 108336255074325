// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Help = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path d="M250,41.7c114.9,0,208.3,93.5,208.3,208.3S364.9,458.3,250,458.3S41.7,364.9,41.7,250S135.1,41.7,250,41.7 M250,0C111.9,0,0,111.9,0,250s111.9,250,250,250s250-111.9,250-250S388.1,0,250,0L250,0z" />
    <g>
      <path d="M225.6,305.3c0.1-16.1,1.9-27.7,5.5-34.6c3.5-6.9,11.4-15.7,23.5-26.2c8.6-8.6,15.4-16.8,20.5-24.6c5.1-7.8,7.6-16.5,7.6-26c0-10.9-2.9-19.4-8.7-25.5c-5.8-6-14-9.1-24.8-9.1c-9.2,0-16.9,2.6-23.3,7.7c-6.4,5.1-9.6,12.7-9.6,22.8l-39.2,0c-0.3-20.1,6.3-36.8,19.8-48c13.5-11.2,31-16.8,52.4-16.8c23.2,0,41.2,6,54.2,18.1c13,12.1,19.4,28.8,19.4,50.3c0,14.3-4.1,27.6-12.2,39.9c-8.1,12.2-18.3,23.4-30.7,33.6c-6.3,5.6-10.2,11-11.8,16.1c-1.6,5.1-2.4,12.5-2.4,22.3H225.6z M266.5,375h-41v-39.4h41V375z" />
    </g>
  </Icon>
);

export default Help;
