// Import Dependencies
import styled from 'styled-components';

// Import Gobals
import typography from '../../global/typography';
import { spacing } from '../../../utils/styles';

const Legend = styled.legend`
  ${typography.headingMedium};
  margin-bottom: ${spacing.base};
`;

export default Legend;
