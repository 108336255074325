// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Pin = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <g>
      <path d="M249,43.5c74.7,0,135.4,59.5,135.4,132.5c0,48-71.1,165.8-135.4,255.8c-64.3-90-135.4-207.8-135.4-255.8C113.6,103,174.3,43.5,249,43.5 M249,1.9c-97.8,0-177.1,78-177.1,174.2S249,501.9,249,501.9s177.1-229.6,177.1-325.8S346.8,1.9,249,1.9L249,1.9z" />
    </g>
    <g>
      <circle cx="249" cy="171.1" r="41.7" />
    </g>
  </Icon>
);

export default Pin;
