import { useTreatments } from '@splitsoftware/splitio-react';
import React, { forwardRef, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { WEBAPP_BETA_SWITCH } from 'splitConfig';
import { getIsBetaEnabled, setIsBetaEnabled } from 'utils/betaEnabled';
import { PATHS } from 'utils/constants';
import * as Icons from '../../components/Icons';
import BubbleBoxBar from './bubbleBoxBar';
import {
  Container,
  StyledSmallText,
  StyledTagBox,
  StyledTagIcon,
  StyledTagItem,
  StyledTagText
} from './bubbleBoxStyles';
import { useIsBetaFeatureEnabled } from 'pages/App/utils/useIsBetaFeatureEnabled';

const title = 'SKAND SUPPORT';
const subtitle = 'Thanks for visiting Skand, how can we help you?';

const BubbleBox = forwardRef(({ setIsSupportOpen }, ref) => {
  const [selectedMenu, setSelectedMenu] = useState(null);

  const treatment = useTreatments([WEBAPP_BETA_SWITCH]);
  const isWebappBetaSwitchEnabledByFlag = treatment[WEBAPP_BETA_SWITCH]?.treatment === 'on';
  const isWebappBetaEnabled = useIsBetaFeatureEnabled();

  const betaSwitch = {
    id: 'switchToBeta',
    onClick: () => {
      setIsBetaEnabled(!isWebappBetaEnabled);
      window.location.replace('/');
    },
    text: isWebappBetaEnabled ? 'Switch to Classic' : 'Switch to Beta'
  };

  const helpCenter = {
    id: 'HelpCenter',
    text: 'Help Centre',
    url: 'https://skand.cargo.site/'
  };

  const defaultMenu = [
    ...(isWebappBetaSwitchEnabledByFlag ? [betaSwitch] : []),
    ...(isWebappBetaEnabled ? [helpCenter] : []),
    {
      id: 'ContactSupport',
      text: 'Contact Support',
      url: 'mailto:support@skand.io'
    }
  ];

  const selectedMenuItems = useMemo(() => {
    switch (selectedMenu) {
      default:
        return defaultMenu;
    }
  }, [selectedMenu]);

  return (
    <Container ref={ref}>
      <BubbleBoxBar title={title} setIsSupportOpen={setIsSupportOpen} />

      {selectedMenu ? (
        <StyledTagBox hasIconBefore onClick={() => setSelectedMenu(null)}>
          <StyledTagIcon hasIconBefore>
            <Icons.NavigateBefore />
          </StyledTagIcon>
          <StyledTagText>{selectedMenu}</StyledTagText>
        </StyledTagBox>
      ) : (
        <StyledSmallText>{subtitle}</StyledSmallText>
      )}

      {selectedMenuItems.map((item) => {
        if (item.url) {
          return (
            <a key={item.id} href={item.url} target="_blank">
              <StyledTagItem data-pendo={item.dataPendo}>
                <StyledTagText>{item.text}</StyledTagText>
              </StyledTagItem>
            </a>
          );
        }

        if (item.link) {
          return (
            <Link key={item.id} to={item.link}>
              <StyledTagItem data-pendo={item.dataPendo}>
                <StyledTagText>{item.text}</StyledTagText>
              </StyledTagItem>
            </Link>
          );
        }

        return (
          <StyledTagItem key={item.id} data-pendo={item.dataPendo} onClick={item.onClick}>
            <StyledTagText>{item.text}</StyledTagText>
          </StyledTagItem>
        );
      })}
    </Container>
  );
});

export default BubbleBox;
