// Import Dependencies
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Import Styled Componenys
import StyledCheckbox from '../../styles/components/Checkbox';
import DefectList from '../../styles/components/DefectList';

class CheckboxList extends PureComponent {
  handleAllChange = (e) => {
    const { onChange, name, options } = this.props;
    onChange({
      ...e,
      target: {
        name,
        value: e.target.checked ? options.map(option => option.value) : [],
      },
    });
  };

  render() {
    const {
      options, value, name, id, label, renderAfterOption, selectedDefectId, ...props
    } = this.props;

    if (options && !options.length) {
      return <p>No {label} available</p>;
    }

    return (
      <DefectList>
        <StyledCheckbox>
          <DefectList.Item>
            <StyledCheckbox.Input
              type="checkbox"
              id={id}
              name={name}
              onChange={this.handleAllChange}
              checked={(value && (value.length === options.length)) || false}
            />
            <StyledCheckbox.Label htmlFor={id} modifiers={{ grey: true }}>
              Select all
            </StyledCheckbox.Label>
          </DefectList.Item>
        </StyledCheckbox>
        {options.map(option => (
          <StyledCheckbox key={option.id}>
            <DefectList.Item modifiers={{ isActive: parseInt(selectedDefectId, 10) === option.id }}>
              <StyledCheckbox.Input
                type="checkbox"
                checked={(value && value.find(item => item === option.value)) || false}
                id={id + option.id}
                name={name}
                value={option.value}
                {...props}
              />
              <StyledCheckbox.Label htmlFor={id + option.id} />
              <Link to={`/explore/${option.id}`}>{option.label}</Link>
              {renderAfterOption && renderAfterOption(option)}
            </DefectList.Item>
          </StyledCheckbox>
        ))}
      </DefectList>
    );
  }
}

CheckboxList.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]) })).isRequired,
  renderAfterOption: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  selectedDefectId: PropTypes.string,
};

CheckboxList.defaultProps = {
  label: null,
  value: null,
  renderAfterOption: null,
  selectedDefectId: null,
};

export default CheckboxList;
