import { Root } from '@skand/webapp-datasets';
import { LeafContainer } from 'components/LeafContainer';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BetaReset } from 'utils/betaReset';

export const DatasetsBeta = ({ hasWhiteLabelingNavbar }) => {
  return (
    <LeafContainer hasWhiteLabelingNavbar={hasWhiteLabelingNavbar}>
      <BetaReset />
      <Helmet>
        <title>Datasets</title>
      </Helmet>

      <Root />
    </LeafContainer>
  );
};

DatasetsBeta.propTypes = {
  hasWhiteLabelingNavbar: PropTypes.bool
};

DatasetsBeta.defaultProps = {
  hasWhiteLabelingNavbar: false
};
