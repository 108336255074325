// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { rgba } from 'polished';
import { getModifier } from '../../../utils/functions';

// Import Gobals
import typography from '../../global/typography';
import { colors, spacing } from '../../../utils/styles';

const Header = styled.th`
  ${typography.smallCaps};
  background-color: ${rgba(colors.black, 0.2)};
  color: ${colors.white};
  padding: ${spacing.small} ${spacing.base};
  text-align: left;

  /* Modifier: Align Center */
  ${(props) =>
    getModifier(props, 'alignCenter') &&
    `
        text-align: center;
      `};
`;

export default Header;
