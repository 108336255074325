// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import Globals
import { spacing, layer } from '../../../utils/styles';

const Actions = styled.div`
  position: absolute;
  right: ${spacing.base};
  top: ${spacing.base};
  z-index: ${layer.modal + 1};

  /* Modifier: image */
  ${(props) =>
    getModifier(props, 'image') &&
    `
      top: 0;
      right: 0;
    `};

  ${(props) =>
    getModifier(props, 'shareModal') &&
    `
      align-items: center
      display: flex;
      height: 2.125rem;
      top: 2.188rem;
    `};
  ${(props) => getModifier(props, 'leaveModal') && `display: none;`}
`;

export default Actions;
