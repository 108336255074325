/**
 * Address lookup, uses
 * https://github.com/kenny-hibino/react-places-autocomplete
 */

import React from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import PropTypes from 'prop-types';

// Import Components
import * as Icons from '../../components/Icons';

// Import Styled Components
import TextInput from '../../styles/components/TextInput';
import TextInputSuggestions from '../../styles/components/TextInputSuggestions';

class PlaceAutocomplete extends React.Component {
  state = { address: this.props.value, backupAddress: this.props.value };

  handleChange = (address) => {
    this.setState({ address, backupAddress: address });
  };

  updateFormFieldValue = (address) => {
    const { onChange, name } = this.props;

    // Geocode then pass to form
    geocodeByAddress(address || this.state.backupAddress)
      .then((results) =>
        onChange({
          target: {
            name,
            value: results,
          },
        })
      )
      .catch((error) => console.error('Error', error));
  };

  handleSelect = (address) => {
    this.updateFormFieldValue(address);
  };

  handleCloseClick = (e) => {
    const { onChange, name } = this.props;
    e.preventDefault();

    this.field.focus();

    // Clear the local address
    this.setState({
      address: '',
    });

    // Clear the value in the form
    onChange({
      target: {
        name,
        value: '',
      },
    });
  };

  handleBlur = () => {
    // If they haven't changed the address, just reinstate it from a backup
    if (this.state.address === '') {
      this.setState({
        address: this.state.backupAddress,
      });

      this.updateFormFieldValue();
    }
  };

  render() {
    const { error: hasError } = this.props;

    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={{ types: ['address'] }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div>
            <TextInput
              ref={(input) => {
                this.field = input;
              }}
              modifiers={{ hasError, hasIcon: true }}
              {...getInputProps({
                placeholder: 'Search locations',
                onBlur: this.handleBlur,
              })}
            />

            <TextInput.InnerButton
              href="#"
              onClick={this.handleCloseClick}
              modifiers={{ isActive: !!this.state.address }}
            >
              <Icons.Close />
            </TextInput.InnerButton>

            <TextInputSuggestions>
              {suggestions.map((suggestion) => (
                <TextInputSuggestions.Item {...getSuggestionItemProps(suggestion)}>
                  <span>{suggestion.description}</span>
                </TextInputSuggestions.Item>
              ))}
            </TextInputSuggestions>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

PlaceAutocomplete.propTypes = {
  error: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.string]),
};

PlaceAutocomplete.defaultProps = {
  error: null,
  value: null,
};

export default PlaceAutocomplete;
