import React from 'react';
import PropTypes from 'prop-types';

import StyledSection from './sectionStyles';

const Section = ({ children, modifiers }) => (
  <StyledSection modifiers={modifiers}>{children}</StyledSection>
);

Section.propTypes = {
  children: PropTypes.node,
  modifiers: PropTypes.shape(),
};

Section.defaultProps = {
  children: null,
  modifiers: null,
};

export default Section;
