// Import Dependencies
import styled from 'styled-components';

// Import vars
import { colors, spacing, global } from '../../../utils/styles';

const Close = styled.button`
  position: absolute;
  right: ${spacing.small};
  top: ${spacing.small};
  transition: opacity ${global.transitionSpeed} ${global.easing} 0s;

  svg {
    width: ${spacing.small};
    height: ${spacing.small};
    fill: ${colors.orange};
  }

  &:hover {
    opacity: 0.5;
  }
`;

export default Close;
