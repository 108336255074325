// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Components
import Hint from '../Hint';
import SubNav from '../SubNav';
import Title from '../Title';

// Import Styled Components
import StyledSidebar from '../../styles/objects/Sidebar';

const Sidebar = ({ title, subNav, hintContent, children, 'data-cy': dataCy }) => (
  <StyledSidebar>
    <StyledSidebar.Inner>
      {title && (
        <Title data-cy={dataCy + 'Title'} type="h2">
          {title}
        </Title>
      )}
      {hintContent && (
        <StyledSidebar.Hint>
          <Hint {...hintContent} />
        </StyledSidebar.Hint>
      )}
      {subNav && <SubNav {...subNav} />}
      {children}
    </StyledSidebar.Inner>
  </StyledSidebar>
);

Sidebar.propTypes = {
  hintContent: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  title: PropTypes.string,
  subNav: PropTypes.shape({}),
  children: PropTypes.node,
};

Sidebar.defaultProps = {
  children: null,
  hintContent: null,
  subNav: null,
  title: null,
};

export default Sidebar;
