// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { rgba } from 'polished';
import { getModifier } from '../../../utils/functions';

// Import Gobals
import { colors, global } from '../../../utils/styles';

const Row = styled.tr`
  background-color: ${colors.transparent};
  transition: background-color ${global.transitionSpeed} ${global.easing} 0s;

  /* Modifier: No Hover */
  ${(props) =>
    !getModifier(props, 'noHover') &&
    `
      &:hover {
        background-color: ${rgba(colors.white, 0.05)};
      }
    `};
`;

export default Row;
