import { useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSplitConfig } from 'splitConfig';
import { resetSentryUser } from 'utils/sentry';
import posthog from 'posthog-js';
import { CREATE_PORTAL_SESSION, LOGOUT_MUTATION, UPDATE_USER_MUTATION } from '.';
import { useHandleInvalidToken } from '../../../hooks';
import { PATHS } from '../../../utils/constants';

const useHandlers = () => {
  const history = useHistory();
  const { removeKeyAndCookie } = useSplitConfig();
  const { checkInvalidTokenError } = useHandleInvalidToken();

  const [logout] = useMutation(LOGOUT_MUTATION, {
    onCompleted: () => {
      const ACCESS_TOKEN_NAME = process.env.REACT_APP_ACCESS_TOKEN_NAME;
      localStorage.clear();
      Cookies.remove(ACCESS_TOKEN_NAME);
      window.Intercom('shutdown');
      posthog.reset();
      resetSentryUser();
      removeKeyAndCookie();
    },
    onError: (e) => {
      checkInvalidTokenError(e);
      const ACCESS_TOKEN_NAME = process.env.REACT_APP_ACCESS_TOKEN_NAME;
      Cookies.remove(ACCESS_TOKEN_NAME);
      removeKeyAndCookie();
    }
  });

  const [userMessage, setUserMessage] = useState('');
  const [accountMessage, setAccountMessage] = useState('');

  const [updateUser, { loading: isProcessing, error: userError }] = useMutation(
    UPDATE_USER_MUTATION,
    {
      onCompleted: async () => {
        setUserMessage('Successfully updated!');

        // await sleep(2000);
        // history.push(`${PATHS.ACCOUNT}/details`);
      },
      onError: (e) => {
        checkInvalidTokenError(e);
        setUserMessage(e.message);
      }
    }
  );

  const [createPortalSession, { error: accountError }] = useMutation(CREATE_PORTAL_SESSION, {
    onCompleted: async (data) => {
      window.open(data.createSubscriptionPortalSession);
    },
    onError: (e) => {
      setAccountMessage(e.message);
    }
  });

  const handleUserSubmit = async (data) => {
    const { firstName, lastName } = data;
    await updateUser({ variables: { user: { firstName, lastName } } });
  };

  const handlePasswordSubmit = async (data) => {
    const { currentPassword, password: newPassword, passwordConfirm } = data;
    await updateUser({ variables: { user: { currentPassword, newPassword, passwordConfirm } } });
  };

  const handleEditPassword = () => {
    history.push('/account/details/password');
  };

  const handleHideModal = () => {
    history.push(`${PATHS.ACCOUNT}/details`);
  };

  const handleLogout = async () => {
    await logout();
  };

  const handleManageSubscription = async () => {
    await createPortalSession();
  };

  return {
    handleUserSubmit,
    handlePasswordSubmit,
    handleEditPassword,
    handleHideModal,
    handleLogout,
    handleManageSubscription,
    userMessage,
    accountMessage,
    isProcessing,
    userError,
    accountError
  };
};

export default useHandlers;
