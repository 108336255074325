// Import Dependencies
import styled from 'styled-components';

// Import vars
import { rgba } from 'polished';
import { colors, spacing, global } from '../../../utils/styles';

const Item = styled.span`
  border-color: transparent;
  display: block;
  color: ${rgba(colors.white, 0.5)};
  padding: ${spacing.xSmall} ${spacing.small};
  transition: color ${global.transitionSpeed} ${global.easing} 0s;

  &:hover {
    cursor: pointer;
    color: ${colors.white};
  }

  &:first-child {
    padding-top: 18px;
  }

  &:last-child {
    padding-bottom: 18px;
  }
`;

export default Item;
