// Import Dependencies
import styled from 'styled-components';

import { colors } from '../../../utils/styles';

const RequiredStar = styled.span`
  color: ${colors.blue500};
`;

export default RequiredStar;
