// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Close = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path d="M285.4,250L492.7,42.7c9.8-9.8,9.8-25.6,0-35.4s-25.6-9.8-35.4,0L250,214.6L42.7,7.3c-9.8-9.8-25.6-9.8-35.4,0s-9.8,25.6,0,35.4L214.6,250L7.3,457.3c-9.8,9.8-9.8,25.6,0,35.4c4.9,4.9,11.3,7.3,17.7,7.3s12.8-2.4,17.7-7.3L250,285.4l207.3,207.3c4.9,4.9,11.3,7.3,17.7,7.3s12.8-2.4,17.7-7.3c9.8-9.8,9.8-25.6,0-35.4L285.4,250z" />
  </Icon>
);

export default Close;
