// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const Logo = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.15137 11.3504V7.99994C4.15137 6.19788 5.85774 4.73724 7.67034 4.73724C9.48281 4.73724 10.9692 6.15261 10.9692 7.95425C10.9692 9.75616 9.5165 11.3504 7.70418 11.3504H4.15137ZM0 8.10288V15.5H7.83388C12.0575 15.5 15.4355 12.1988 15.4355 8C15.4355 3.80158 11.9649 0.5 7.74094 0.5C3.51757 0.5 0 3.90375 0 8.10288Z"
      fill="url(#paint0_linear)"
    />
    <rect x="4.14941" y="11.3506" width="3.63083" height="4.14952" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 15.5001H4.14952V11.3506H0V15.5001Z"
      fill="#F47C31"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="5.93297"
        y1="9.36102"
        x2="0.412631"
        y2="11.7969"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.15" />
      </linearGradient>
    </defs>
  </Icon>
);

export default Logo;
