// Import Dependencies
import styled from 'styled-components';

// Import vars
import typography from '../../global/typography';
import { spacing } from '../../../utils/styles';

const Hint = styled.h3`
  ${typography.headingMedium};
  margin-bottom: ${spacing.small};
`;

export default Hint;
