import PropTypes from 'prop-types';
import React from 'react';
import { CustomLegoButton, ElementTypes, getButtonStyles } from '../../styles/components/Button';
import { getButtonElement } from '../../utils/functions';
import Loader from '../Loader';

const Button = ({ children, modifiers, icon, ...props }) => {
  if (modifiers?.lego) {
    const buttonStyles = getButtonStyles(modifiers);
    return (
      <CustomLegoButton
        tone={buttonStyles.tone}
        variant={buttonStyles.variant}
        modifiers={modifiers}
        {...props}
      >
        {icon && icon}
        {children}
        {modifiers && modifiers.isProcessing && (
          <Loader modifiers={{ black: !(modifiers.secondary || modifiers.link) }} />
        )}
      </CustomLegoButton>
    );
  }

  const elementType = getButtonElement(props);
  const ElementType = ElementTypes[elementType];

  return (
    <ElementType modifiers={modifiers} {...props}>
      {icon && icon}
      {children}
      {modifiers && modifiers.isProcessing && (
        <Loader modifiers={{ black: !(modifiers.secondary || modifiers.link) }} />
      )}
    </ElementType>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  modifiers: PropTypes.shape({}),
};

Button.defaultProps = {
  modifiers: null,
  icon: null,
};

export default Button;
