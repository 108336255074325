import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { some } from 'lodash';
import ReactSelect from 'react-select';

import { getCustomStyles } from './SelectStyles';
import { filterOptionsBySearchTerm } from './utils';

class Select extends PureComponent {
  // Temporary workaround, should be fixed once https://github.com/JedWatson/react-select/pull/4080 got approved
  // Once approved, upgrade react-select and (1) remove constructor, (2) remove `if (this.select.current)...` in componentDidUpdate
  // (3) remove `ref={this.selectRef}`
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();

    const { value, options } = this.props;

    let valueOption = {};
    if (typeof value === 'string') {
      valueOption = options.find((option) => option.id === value);
    }

    this.state = {
      selectedOption: value ? valueOption : null,
      placeholder: this.props.placeholder,
      filteredOptions: this.props.options,
    };
  }

  componentDidUpdate = () => {
    const { selection, options } = this.props;

    if (selection && selection.category_id !== null) {
      if (selection.site_id === null) {
        this.setState({ selectedOption: null });
      }
      if (selection.site_id !== null) {
        const updatedSelectedSite = options.find((option) => option.value === selection.site_id);
        this.setState({ selectedOption: updatedSelectedSite });
      }
    }

    if (this.selectRef.current && this.selectRef.current.select) {
      this.selectRef.current.select.getNextFocusedOption = () => null;
    }
  };

  defaultPlaceholderState = this.props.placeholder;

  clearPlaceholder = () => this.setState({ placeholder: '' });

  handleBlur = (e) => {
    const { onBlur } = this.props;
    this.setState({ placeholder: this.defaultPlaceholderState });

    if (onBlur) {
      onBlur(e);
    }
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });

    this.props.onChange(selectedOption);
  };

  handleInputChange = (inputValue) => {
    const { options } = this.props;

    const orderedOptions = filterOptionsBySearchTerm(inputValue, options);
    this.setState({ filteredOptions: orderedOptions });
  };

  filterOption = (option, inputValue) => option.label.toLowerCase().includes(inputValue);

  render() {
    const { selectedOption, placeholder, filteredOptions } = this.state;

    const {
      options,
      name,
      searchable,
      error: hasError,
      clearable,
      disabled,
      defaultValue,
    } = this.props;

    const customStyles = getCustomStyles({});

    const noOptions = (some(options, { value: '' }) && options.length === 1) || !options.length;

    return (
      <ReactSelect
        styles={customStyles}
        ref={this.selectRef}
        name={name}
        value={selectedOption || defaultValue}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onClick={this.clearPlaceholder}
        onFocus={this.clearPlaceholder}
        onBlur={this.handleBlur}
        options={filteredOptions}
        clearable={clearable || false}
        placeholder={placeholder}
        searchable={searchable}
        modifiers={{ hasError, noOptions: noOptions || disabled }}
        disabled={noOptions || disabled}
        filterOption={this.filterOption}
        onSelectResetsInput={false}
        onBlurResetsInput={false}
        blurInputOnSelect
      />
    );
  }
}

Select.propTypes = {
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  placeholder: PropTypes.string,
  searchable: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.string,
  selection: PropTypes.shape(),
};

Select.defaultProps = {
  clearable: false,
  disabled: false,
  error: null,
  placeholder: 'Please select',
  searchable: false,
  value: null,
  defaultValue: null,
  selection: null,
};

export default Select;
