import { useMutation } from '@apollo/client';
import { pick } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useHandleInvalidToken } from '../../../hooks';
import { PATHS } from '../../../utils/constants';
import { formatPlace, isObject } from '../../../utils/functions';
import { UPDATE_CACHE_FOR_CREATE_SITE_MUTATION } from './fragments';
import { CREATE_SITE_MUTATION, UPDATE_SITE_MUTATION } from './mutations';

const UPDATE_SITE_FIELDS = ['name', 'siteId'];

const CREATE_SITE_FIELDS = ['name'];

const useHandlers = (siteId, siteDetail) => {
  const history = useHistory();
  const { checkInvalidTokenError } = useHandleInvalidToken();

  const [updateSite] = useMutation(UPDATE_SITE_MUTATION, {
    onCompleted: () => history.push(PATHS.SITES),
    onError: (e) => checkInvalidTokenError(e),
  });

  const [createSite] = useMutation(CREATE_SITE_MUTATION, {
    onCompleted: () => history.push(PATHS.SITES),
    onError: (e) => checkInvalidTokenError(e),
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          sitesByAccountId: (existingRefs = []) => {
            const newRef = cache.writeFragment({
              data: data.createSite,
              fragment: UPDATE_CACHE_FOR_CREATE_SITE_MUTATION,
            });

            return [...existingRefs, newRef];
          },
        },
      });
    },
  });

  const handleSubmit = (data) => {
    const noChangeOnManagerId = siteDetail.managerId === data.managerId;
    const manager = noChangeOnManagerId ? data.managerName : data.managerId;

    const { address } = data;

    let formattedData = {
      ...data,
      siteId,
      manager,
      ...(isObject(address) && formatPlace(address[0])),
    };

    if (siteId) {
      formattedData = pick(formattedData, UPDATE_SITE_FIELDS);
      updateSite({ variables: { input: formattedData } });
    } else {
      formattedData = pick(formattedData, CREATE_SITE_FIELDS);
      createSite({ variables: { input: formattedData } });
    }
  };

  return { handleSubmit };
};

export default useHandlers;
