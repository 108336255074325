import styled from 'styled-components';

import { fontSize, colors, spacing } from '../../../../utils/styles';

export const Container = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${spacing.small};
  position: relative;
`;

export const Title = styled.div`
  color: ${colors.white};
  font-size: ${fontSize.xSmall};
  font-weight: 500;
  line-height: 1.125rem;
  margin-right: ${spacing.small};
  text-transform: uppercase;
`;

export const IconButton = styled.div`
  color: ${colors.white};
  cursor: pointer;
  height: 1.25rem;
  opacity: 0.5;
  width: 1.25rem;

  &:hover {
    opacity: 1;
  }
`;

export const InfoTooltip = styled.div`
  background-color: ${colors.black};
  padding: 1rem;
  position: absolute;
  left: 11rem;
  top: 2rem;
  width: 13rem;
  z-index: 99;
`;

export const InfoText = styled.p`
  color: ${colors.white};
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-bottom: 0.75rem;
`;

export const InfoDismiss = styled.p`
  color: ${colors.white};
  cursor: pointer;
  float: right;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;
  margin-bottom: 0;
  opacity: 0.5;
  text-decoration-line: underline;

  :hover {
    opacity: 1;
  }
`;
