/* eslint-disable complexity */
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import { useQuery } from '@apollo/client';

import { useHandleInvalidToken } from '../../../hooks';
import { DashboardQuery } from './graphqlTypes/DashboardQuery';
import { DASHBOARD_QUERY } from './queries';

const useFetchData = () => {
  const { checkInvalidTokenError } = useHandleInvalidToken();

  const ONE_MINUTE = 60000;
  const {
    loading,
    error,
    data: {
      capturesByAccountId: captures,
      reportsByAccountId: reports,
      sitesByAccountId: sites,
      usersByAccountId: users,
    } = {
      capturesByAccountId: [],
      reportsByAccountId: [],
      sitesByAccountId: [],
      usersByAccountId: [],
    },
  } = useQuery<DashboardQuery>(DASHBOARD_QUERY, {
    pollInterval: ONE_MINUTE,
    onError: (e) => {
      checkInvalidTokenError(e);
    },
  });

  const sortedReports = orderBy(reports, ['createdAt', 'name'], ['desc', 'asc']);
  const sortedSites = orderBy(sites, ['name'], ['asc']);
  const usersWithSignInRecord = filter(users, 'lastSignInAt');
  const sortedUsers = orderBy(usersWithSignInRecord, ['lastSignInAt'], ['desc']);

  return {
    captures,
    reports: sortedReports,
    sites: sortedSites,
    users: sortedUsers,
    isFetching: loading,
    message: error?.message,
    error,
  };
};

export default useFetchData;
