import { isEmail, isRequired } from '../../../../../utils/validators';

const getFields = () => [
  {
    id: 'firstName',
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    isRequired: true,
    validate: [isRequired],
    'data-cy': 'userDetailFirstNameField',
  },
  {
    id: 'lastName',
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    isRequired: true,
    validate: [isRequired],
    'data-cy': 'userDetailLastNameField',
  },
  {
    id: 'email',
    name: 'email',
    label: 'Email',
    type: 'text',
    isRequired: true,
    validate: [isRequired, isEmail],
    'data-cy': 'userDetailEmailField',
  },
];

export default getFields;
