import styled from 'styled-components';

import { colors } from '../../utils/styles';

export const Container = styled.div`
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vh;
  opacity: ${(props) => (props.display ? 1 : 0)};
  position: relative;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  width: 100vw;
`;

export const Logo = styled.img`
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  object-fit: cover;
  position: absolute;
  width: 4.05vw;
`;

export const ReportContainer = styled.div`
  opacity: ${(props) => (props.display ? 1 : 0)};
  position: relative;
  transition: opacity 0.3s;
  transform: scale(1.5);
  transform-origin: top center;
  width: 100vw;
`;

export const ReportWrapper = styled.div`
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 792px;
  left: 50%;
  position: relative;
  transform: translate(-50%, 0);
  width: 612px;
`;

export const Banner = styled.img`
  background-color: ${colors.white};
  object-fit: cover;
  position: absolute;
  right: 40px;
  top: 64px;
  width: 10vw;
`;

export const ColorBar = styled.div`
  background: ${(props) => props.background || colors.blue500};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 50px;
`;
