import * as routeComponents from '../pages';
import { PATHS } from './constants';

const privateExplore = [
  {
    pathName: 'Explore',
    path: `${PATHS.EXPLORE}`,
    component: routeComponents.Explore,
    betaComponent: routeComponents.ExploreBeta
  },
  {
    pathName: 'Explore Not Found',
    path: `${PATHS.EXPLORE}/not-found`,
    component: routeComponents.Explore,
    betaComponent: routeComponents.Explore
  },
  ...[PATHS.EXPLORE_BETA_PROJECT, PATHS.EXPLORE_BETA_COMPAT].map((path) => ({
    component: routeComponents.ExploreBeta,
    betaComponent: routeComponents.ExploreBeta,
    path,
    pathName: path
  }))
];

const privateDashboard = [
  {
    pathName: 'Dashboard',
    path: `${PATHS.ROOT}`,
    component: routeComponents.Dashboard
  }
];

const privateDatasets = [
  {
    pathName: 'Datasets',
    path: `${PATHS.DATASETS}`,
    component: routeComponents.Datasets,
    betaComponent: routeComponents.DatasetsBeta
  },
  ...[
    PATHS.DATASETS_FOLDERS,
    PATHS.DATASETS_FOLDER_FILES,
    PATHS.DATASETS_BETA_COMPAT_FOLDER_FILES,
    PATHS.DATASETS_BETA_COMPAT_FOLDERS
  ].map((path) => ({
    component: routeComponents.DatasetsBeta,
    betaComponent: routeComponents.DatasetsBeta,
    path,
    pathName: path
  })),
  {
    pathName: 'Datasets',
    path: `${PATHS.DATASETS}/not-found`,
    component: routeComponents.Datasets,
    betaComponent: routeComponents.Datasets
  },
  {
    pathName: 'Datasets',
    path: `${PATHS.DATASETS}/:datasetId`,
    component: routeComponents.Datasets,
    betaComponent: routeComponents.Datasets
  },
  {
    pathName: 'Datasets',
    path: `${PATHS.DATASETS}/edit/:datasetId`,
    component: routeComponents.Datasets,
    betaComponent: routeComponents.Datasets
  },
  {
    pathName: 'Datasets',
    path: `${PATHS.DATASETS}/files`,
    component: routeComponents.Datasets,
    betaComponent: routeComponents.Datasets
  },
  {
    pathName: 'Datasets',
    path: `${PATHS.DATASETS}/files/:parentNodeId`,
    component: routeComponents.Datasets,
    betaComponent: routeComponents.Datasets
  },
  {
    pathName: 'Datasets',
    path: `${PATHS.DATASETS}/pdf/file/:fileId`,
    component: routeComponents.Datasets,
    betaComponent: routeComponents.Datasets
  },
  {
    pathName: 'Datasets',
    path: `${PATHS.DATASETS}/projects`,
    component: routeComponents.Datasets,
    betaComponent: routeComponents.Datasets
  },
  {
    pathName: 'Datasets',
    path: `${PATHS.DATASETS}/projects/new`,
    component: routeComponents.Datasets,
    betaComponent: routeComponents.Datasets
  },
  {
    pathName: 'Datasets',
    path: `${PATHS.DATASETS}/projects/:projectId`,
    component: routeComponents.Datasets,
    betaComponent: routeComponents.Datasets
  },
  {
    pathName: 'Datasets',
    path: `${PATHS.DATASETS}/projects/:projectId/folder/:parentNodeId`,
    component: routeComponents.Datasets,
    betaComponent: routeComponents.Datasets
  },
  {
    pathName: 'Datasets',
    path: `${PATHS.DATASETS}/renderObjects`,
    component: routeComponents.Datasets,
    betaComponent: routeComponents.Datasets
  }
];

const privateAccount = [
  {
    pathName: 'Account',
    path: `${PATHS.ACCOUNT}/details`,
    component: routeComponents.Account,
    betaComponent: routeComponents.ManageBeta
  },
  {
    pathName: 'Account Password',
    path: `${PATHS.ACCOUNT}/details/password`,
    component: routeComponents.Account,
    betaComponent: routeComponents.ManageBeta
  }
];

const privateOperations = [
  {
    notExact: true,
    pathName: 'Operations',
    path: `${PATHS.OPERATIONS}`,
    component: routeComponents.Operations
  },
  {
    notExact: true,
    pathName: 'Operations',
    path: `${PATHS.OPERATIONS}/annotate/process/:processId/file/:fileId`,
    component: routeComponents.Operations
  }
];

const privateBranding = [];

const privateReportV2 = [
  {
    pathName: 'Create Report',
    path: `${PATHS.REPORTS}/new`,
    component: routeComponents.ReportsV2,
    betaComponent: routeComponents.ReportsBeta
  },
  {
    pathName: 'Edit Report',
    path: `${PATHS.REPORTS}/:reportId`,
    component: routeComponents.ReportsV2
  },
  {
    pathName: 'Online View',
    path: `${PATHS.REPORTS}/view/:reportId`,
    component: routeComponents.ReportsV2,
    betaComponent: routeComponents.ReportsV2
  },
  {
    pathName: 'Reports Table',
    path: `${PATHS.REPORTS}`,
    component: routeComponents.ReportsV2,
    betaComponent: routeComponents.ReportsBeta
  },
  ...[
    // PATHS.REPORTS_BETA
    PATHS.REPORTS_VIEW
  ].map((path) => ({
    component: routeComponents.ReportsBeta,
    betaComponent: routeComponents.ReportsBeta,
    path,
    pathName: path
  }))
];

const privateSite = [];

const privateUsers = [];

const privateAuth = [
  {
    pathName: 'Azure Auth',
    path: `${PATHS.AUTH}/azure`,
    component: routeComponents.AzureAuth,
    betaComponent: routeComponents.AzureAuth
  }
];

const privateManage = [
  {
    pathName: 'Sites',
    path: `${PATHS.MANAGE}`,
    component: routeComponents.Sites,
    betaComponent: routeComponents.ManageBeta
  },
  {
    pathName: 'Branding',
    path: `${PATHS.BRANDING}`,
    component: routeComponents.Branding
  },
  {
    pathName: 'Branding Preview',
    path: `${PATHS.BRANDING}/preview/:logoType`,
    component: routeComponents.BrandingPreview
  },
  {
    pathName: 'Users',
    path: `${PATHS.USERS}`,
    component: routeComponents.Users,
    betaComponent: routeComponents.ManageBeta
  },
  {
    pathName: 'User Create',
    path: `${PATHS.USERS}/new`,
    component: routeComponents.Users
  },
  {
    pathName: 'User Edit',
    path: `${PATHS.USERS}/:userId`,
    component: routeComponents.Users
  },
  {
    pathName: 'Sites',
    path: `${PATHS.SITES}`,
    component: routeComponents.Sites
  },
  {
    pathName: 'Site Create',
    path: `${PATHS.SITES}/new`,
    component: routeComponents.SiteDetail
  },
  {
    pathName: 'Site Edit',
    path: `${PATHS.SITES}/:siteId`,
    component: routeComponents.SiteDetail
  },
  {
    pathName: 'Site Buildings',
    path: `${PATHS.SITES}/:siteId/:buildingId`,
    component: routeComponents.SiteDetail
  },
  ...[
    // PATHS.MANAGE_BETA,
    PATHS.MANAGE_BRANDING,
    PATHS.MANAGE_GROUPS,
    PATHS.MANAGE_GROUPS_CREATE_DETAILS,
    PATHS.MANAGE_GROUPS_CREATE_PERMISSIONS,
    PATHS.MANAGE_GROUPS_CREATE_ROLES,
    PATHS.MANAGE_GROUPS_CREATE_USERS,
    PATHS.MANAGE_GROUPS_DETAILS,
    PATHS.MANAGE_GROUPS_PERMISSIONS,
    PATHS.MANAGE_GROUPS_ROLES,
    PATHS.MANAGE_GROUPS_USERS,
    PATHS.MANAGE_ROLES,
    PATHS.MANAGE_ROLES_CREATE_DETAILS,
    PATHS.MANAGE_ROLES_CREATE_PERMISSIONS,
    PATHS.MANAGE_ROLES_DETAILS,
    PATHS.MANAGE_ROLES_GROUPS,
    PATHS.MANAGE_ROLES_PERMISSIONS,
    PATHS.MANAGE_ROLES_USERS,
    PATHS.MANAGE_SUBSCRIPTION,
    PATHS.MANAGE_TEMPLATES,
    PATHS.MANAGE_TEMPLATES_CREATE,
    PATHS.MANAGE_TEMPLATES_DETAILS,
    PATHS.MANAGE_TEMPLATES_EDIT,
    PATHS.MANAGE_UNAUTHORIZED,
    PATHS.MANAGE_USERS,
    PATHS.MANAGE_USERS_DETAILS,
    PATHS.MANAGE_USERS_GROUPS,
    PATHS.MANAGE_USERS_PERMISSIONS,
    PATHS.MANAGE_USERS_ROLES
  ].map((path) => ({
    betaComponent: routeComponents.ManageBeta,
    path,
    pathName: path
  }))
];

export const allPrivateRoutes = [
  ...privateAccount,
  ...privateAuth,
  ...privateBranding,
  ...privateDatasets,
  ...privateOperations,
  ...privateReportV2,
  ...privateExplore,
  ...privateDashboard,
  ...privateSite,
  ...privateManage,
  ...privateUsers
];

export const publicRoutes = [
  {
    pathName: 'Forgot Password',
    path: PATHS.FORGOT_PASSWORD,
    component: routeComponents.ForgotPasswordBeta,
    betaComponent: routeComponents.ForgotPasswordBeta
  },
  {
    pathName: 'Forgot Password Reset',
    path: `${PATHS.FORGOT_PASSWORD}/reset/:key`,
    component: routeComponents.ResetPasswordBeta,
    betaComponent: routeComponents.ResetPasswordBeta
  },
  {
    pathName: 'Set Invitation Password',
    path: `${PATHS.INVITATION}/set-password/:key`,
    component: routeComponents.SetPasswordBeta,
    betaComponent: routeComponents.SetPasswordBeta
  },
  {
    pathName: 'Page to break',
    path: `/page/to/break`,
    component: routeComponents.PageToBreak
  },
  {
    pathName: 'Explore Share',
    path: `${PATHS.EXPLORE_SHARE}/:externalShareId/:sharedToId`,
    component: routeComponents.Explore,
    betaComponent: routeComponents.Explore
  },
  {
    pathName: 'Explore Share Not Found',
    path: `${PATHS.EXPLORE_SHARE}/not-found`,
    component: routeComponents.Explore,
    betaComponent: routeComponents.Explore
  },
  {
    pathName: 'Report PDF',
    path: `/public${PATHS.REPORTS}/:shareId/pdf`,
    component: routeComponents.ReportPdf,
    betaComponent: routeComponents.ReportPdf
  },
  {
    pathName: 'Report Appendix PDF',
    path: `/public/appendix/:filename`,
    component: routeComponents.ReportsV2
  },
  {
    pathName: 'Error Beta',
    path: PATHS.EXPLORE_BETA_ERROR,
    component: routeComponents.ExploreBeta,
    betaComponent: routeComponents.ExploreBeta
  },
  {
    pathName: 'Error Beta Empty Resource',
    path: PATHS.EXPLORE_BETA_EMPTY_RESOURCE,
    component: routeComponents.ExploreBeta,
    betaComponent: routeComponents.ExploreBeta
  },
  // For both old UI and new UI - also need to update zip request email url when changed
  {
    pathName: 'Beta Dataset Zip Download',
    path: PATHS.DATASETS_ZIP_DOWNLOAD,
    component: routeComponents.DatasetsBeta,
    betaComponent: routeComponents.DatasetsBeta
  },
  // Deprecated
  {
    pathName: 'Datasets Zip Download',
    path: `${PATHS.DATASETS}/download/:zipRequestId`,
    component: routeComponents.Datasets
  },
  // Deprecated
  {
    pathName: 'Datasets Structured Zip Download',
    path: `${PATHS.DATASETS}/download/structured/:structuredZipRequestId`,
    component: routeComponents.Datasets
  }
];

export const loginRoutes = [
  {
    pathName: 'Login',
    path: PATHS.LOGIN,
    component: routeComponents.LogInBeta,
    notExact: true
  }
];

export const unauthorisedRoutes = [
  {
    pathName: 'Demo',
    path: PATHS.DEMO,
    component: routeComponents.Demo
  },
  {
    pathName: 'Demo Confirm',
    path: `${PATHS.DEMO}/confirm`,
    component: routeComponents.DemoConfirm
  }
];

export const allUnauthorisedRoutes = [...loginRoutes, ...unauthorisedRoutes];
