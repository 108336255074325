import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      bearerToken
      email
      accountId
    }
  }
`;

export const SEND_ONE_TIME_LOGIN_TOKEN_MUTATION = gql`
  mutation SendOneTimeLoginToken($email: String!, $hostUrl: String!) {
    sendOneTimeLoginToken(email: $email, hostUrl: $hostUrl)
  }
`;

export const LOGIN_WITH_ONE_TIME_TOKEN_MUTATION = gql`
  mutation LoginWithOneTimeToken($token: String!) {
    loginWithOneTimeToken(token: $token) {
      bearerToken
      email
    }
  }
`;

export const LOGIN_WITH_IDP_TOKEN = gql`
  mutation LoginWithIdpToken {
    loginWithIdpToken {
      bearerToken
      email
      accountId
    }
  }
`;
