import styled from 'styled-components';
import { colors, sizes } from '../../../../utils/styles';

export const NavbarContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #d0d0d0;
`;

export const NavbarColorLine = styled.div`
  background-color: ${(props) => props.color};
  height: ${sizes.whiteLabelingColorBar};
`;

export const WhiteLabelContainer = styled.div`
  align-items: center;
  background-color: ${colors.white};
  display: flex;
  height: ${sizes.whiteLabelingContentBar};
  padding: 1rem 2rem;
`;

export const WhiteLabelLogo = styled.img`
  height: 100%;
  object-fit: contain;
  user-drag: none;
  user-select: none;
`;
