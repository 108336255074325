import { PATHS } from '../../../utils/constants';
import { filterSites, formatDate, getName } from '../../../utils/functions';
import getScannedData from './getScannedData';

import * as types from './graphqlTypes/DashboardQuery';

const getCharts = (
  captures: types.DashboardQuery['capturesByAccountId'],
  reports: types.DashboardQuery['reportsByAccountId'],
  sites: types.DashboardQuery['sitesByAccountId'],
  users: types.DashboardQuery['usersByAccountId'],
  filterSelection: null
) => {
  return [
    {
      id: 1,
      title: 'User list',
      type: 'table',
      cols: [
        { id: '1', label: 'First Name', key: 'firstName' },
        { id: '2', label: 'Last Name', key: 'lastName' },
        {
          id: '3',
          label: 'Last Login',
          key: 'lastSignInAt',
          transformer: formatDate,
        },
        {
          id: '4',
          label: 'Logins',
          key: 'signInCount',
        },
      ],
      data: users?.slice(0, 5),
    },
    {
      id: 2,
      title: 'Project list',
      type: 'table',
      actionLabel: 'Manage projects',
      actionUrl: PATHS.SITES,
      cols: [
        { id: '1', label: 'Name', key: 'name' },
        {
          id: '2',
          label: 'Manager',
          key: 'manager',
          transformer: (value: string) => getName(users, value, 'No manager'),
        },
        {
          id: '3',
          label: 'Buildings',
          key: 'buildingIds',
          transformer: (value: string[]) => value?.length || 0,
        },
      ],
      data: sites
        ?.filter((site: types.DashboardQuery_sitesByAccountId | null) =>
          filterSites(site, filterSelection)
        )
        .slice(0, 5),
    },
    {
      id: 7,
      title: 'Meters Scanned (Thousand Meters)',
      type: 'bar',
      data: captures && getScannedData(captures),
      keys: ['Meters Scanned'],
      indexBy: 'date',
    },
    {
      id: 8,
      title: 'Reports list',
      type: 'table',
      actionLabel: 'View reports',
      actionUrl: PATHS.REPORTS,
      cols: [
        {
          id: '1',
          label: 'Date',
          key: 'createdAt',
          transformer: formatDate,
        },
        {
          id: '2',
          label: 'Report Name',
          key: 'name',
        },
        {
          id: '3',
          label: 'Sites',
          key: 'fields',
          transformer: (value: { siteIds: string[] }) => value?.siteIds?.length || 0,
        },
      ],
      data: (reports as any).slice(0, 5),
    },
  ];
};

export default getCharts;
