// Import Dependencies
import styled from 'styled-components';

// Import elements
import Logo from './Logo';

// Import vars
import { breakpoint, componentWidth, sizes, colors, layer } from '../../../utils/styles';

const Header = styled.header`
  background-color: ${colors.white};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  left: 0;
  margin: 0;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  overflow: hidden;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  position: fixed;
  width: ${componentWidth.nav.default};
  z-index: ${layer.nav};
  box-shadow: 1px 0px 5px -1px rgba(216, 214, 217, 0.5);

  ${breakpoint.medium`
    bottom: auto;
    flex-direction: column;

    // fallback for browsers before 2022
    height: ${(props) =>
      props.hasWhiteLabelingNavbar
        ? `calc(100vh - ${sizes.whiteLabelingColorBar} - ${sizes.whiteLabelingContentBar})`
        : `100vh`};
    height: ${(props) =>
      props.hasWhiteLabelingNavbar
        ? `calc(100dvh - ${sizes.whiteLabelingColorBar} - ${sizes.whiteLabelingContentBar})`
        : `100dvh`};

    justify-content: flex-start;
    width: ${componentWidth.nav.medium};
    box-shadow: 0px 1px 5px -1px rgba(216, 214, 217, 0.5);
  `};
`;

// Set elements
Header.Logo = Logo;

export default Header;
