// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { rgba } from 'polished';
import { getModifier } from '../../../utils/functions';
import { colors, spacing } from '../../../utils/styles';

const Toggle = styled.button`
  margin-left: auto;
  transition: none;

  svg {
    fill: ${rgba(colors.white, 0.5)};
    height: ${spacing.small};
    width: ${spacing.small};
  }

  /* Modifier: isActive */
  ${(props) =>
    getModifier(props, 'isActive') &&
    `
      transform: rotate(180deg);
    `};
`;

export default Toggle;
