import { isRequired, isEmail } from '../../../utils/validators';

const getLoginFields = () => [
  {
    id: 'email',
    name: 'email',
    label: 'Email Address',
    type: 'email',
    'data-cy': 'loginEmailField',
    validate: [isRequired, isEmail],
  },
  {
    id: 'password',
    name: 'password',
    label: 'Password',
    type: 'password',
    'data-cy': 'loginPasswordField',
    validate: [isRequired],
  },
];

export default getLoginFields;
