// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import Globals
import { grow } from '../../global/animations';
import { colors } from '../../../utils/styles';

const Loader = styled.div`
  display: flex;
  justify-content: space-between;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;

  span {
    animation: ${grow} 900ms ease-in-out infinite;
    background: ${(props) => props.theme.primaryColor};
    border-radius: 100%;
    display: block;
    height: 6px;
    opacity: 0.6;
    position: relative;
    width: 6px;

    &:nth-child(1) {
      animation-delay: 0;
    }

    &:nth-child(2) {
      animation-delay: 100ms;
    }

    &:nth-child(3) {
      animation-delay: 200ms;
    }

    /* Modifier: Black */
    ${(props) =>
      getModifier(props, 'black') &&
      `
        background: ${colors.black};
    `};
  }
`;

export default Loader;
