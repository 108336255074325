// Import Dependencies
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
// Import Prop types
import { formFieldPropType } from '../../propTypes';
// Import Styled Components
import StyledForm from '../../styles/components/Form';
// Import Utilities
import {
  buildFormData,
  getFieldError,
  getFieldValue,
  getFormErrors,
  isObjectEmpty,
} from '../../utils/functions';
// Import Components
import Button from '../Button';
import Field from '../Field';
import Notification from '../Notification';
import { StyledDisabledSubmitMessage } from './formStyles';

class Form extends PureComponent {
  // Set default form data
  state = {
    formData: this.props.formData || buildFormData(this.props.fields, 'value'),
    errors: this.props.errors || buildFormData(this.props.fields, 'error'),
  };

  componentWillReceiveProps(nextProps) {
    // If erors have changed, reset error state
    if (nextProps.errors !== this.props.errors) {
      this.setState(() => ({
        errors: nextProps.errors || buildFormData(this.props.fields, 'error'),
      }));
    }

    // If new fields are added, regenerate the form data
    if (nextProps.fields.length !== this.props.fields.length && !this.props.formData) {
      this.setState((prevState) => ({
        formData: { ...buildFormData(nextProps.fields, 'value'), ...prevState.formData },
      }));
    }
  }

  // Handle field change
  handleChange = async (e, validate, error, type) => {
    const { onChange } = this.props;
    const { value, name, checked } = e.target;

    if (!type) return;

    // Update the state
    await this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: getFieldValue(type, value, checked, prevState.formData[name]),
      },
    }));

    if (onChange) {
      const { formData } = this.state;
      onChange(formData);
    }

    // Revalidate if the field has an errors as a validation array
    if (!error || !validate || !validate.length) return;
    this.validateField(value, name, validate);
  };

  // Handle field blur
  handleBlur = (e, validate) => {
    const { relatedTarget } = e;
    const { value, name } = e.target;

    if (
      (relatedTarget && relatedTarget.getAttribute('type') === 'submit') ||
      (relatedTarget && relatedTarget.getAttribute('type') === 'button')
    ) {
      e.preventDefault();
      return;
    }

    if (!validate || !validate.length) return;
    this.validateField(value, name, validate);
  };

  // Handle form submit
  handleSubmit = (e, secondary) => {
    e.preventDefault();

    const { onSubmit, fields } = this.props;
    const { formData } = this.state;

    const hasErrors = this.validateForm();

    if (hasErrors) return;

    onSubmit(formData, secondary, fields);
  };

  // Handle secondary button click
  handleSecondaryButtonClick = (e) => {
    // Call handle submit but pass secondary paramter and event
    this.handleSubmit(e, true);
  };

  // Validate individual field
  validateField = (value, name, validate) => {
    const { formData } = this.state;
    // Create error string
    const errors = getFieldError(value, validate, name, formData);

    // Update the state
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [name]: errors,
      },
    }));
  };

  // Validate the form
  validateForm = () => {
    const { fields } = this.props;
    const { formData } = this.state;

    // Get form errors
    const errors = getFormErrors(fields, formData);

    // Update the state
    this.setState(() => ({
      errors,
    }));

    return isObjectEmpty(errors);
  };

  render() {
    const {
      errorMessage,
      fields,
      legend,
      onSubmit,
      submitLabel,
      isProcessing,
      noSubmit,
      onChange,
      disableSubmit,
      disabledSubmitMessage,
      disableSecondarySubmit,
      cancelButtonAction,
      cancelButtonLabel,
      secondaryButtonLabel,
      renderAfterFields,
      secondaryButtonIcon,
      inlineActions,
      submitButtonProps,
      betaPageName,
      ...props
    } = this.props;
    const { formData, errors } = this.state;

    return (
      <StyledForm onSubmit={noSubmit ? undefined : this.handleSubmit} {...props}>
        {legend && <StyledForm.Legend>{legend}</StyledForm.Legend>}
        {fields && fields.length && (
          <StyledForm.Fields>
            {fields.map(
              (field) =>
                field && (
                  <Field
                    key={field.id}
                    {...props}
                    {...field}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={formData[field.id]}
                    error={errors[field.id]}
                    betaPageName={betaPageName}
                  />
                )
            )}
          </StyledForm.Fields>
        )}
        {renderAfterFields && renderAfterFields}
        <StyledForm.Actions modifiers={{ inlineActions }}>
          {secondaryButtonLabel && (
            <div>
              <Button
                betaPageName={betaPageName}
                onClick={this.handleSecondaryButtonClick}
                type="button"
                modifiers={{ secondary: true, disabled: disableSecondarySubmit, isProcessing, lego: true }}
                icon={secondaryButtonIcon && secondaryButtonIcon}
              >
                {secondaryButtonLabel}
              </Button>
            </div>
          )}

          {!noSubmit && (
            <div>
              {disabledSubmitMessage && (
                <StyledDisabledSubmitMessage>{disabledSubmitMessage}</StyledDisabledSubmitMessage>
              )}
              <Button
                betaPageName={betaPageName}
                type="submit"
                disabled={disableSubmit || isProcessing}
                modifiers={{ disabled: disableSubmit, isProcessing, lego: true }}
                {...submitButtonProps}
              >
                {submitLabel}
              </Button>
            </div>
          )}

          {cancelButtonAction && (
            <div>
              <Button onClick={cancelButtonAction} type="button" modifiers={{ link: true }}>
                {cancelButtonLabel}
              </Button>
            </div>
          )}
        </StyledForm.Actions>

        {errorMessage && (
          <Notification
            modifiers={{ error: true, spacingTop: true }}
            data-cy={props.dataCyTestId + 'Error'}
          >
            {errorMessage}
          </Notification>
        )}
      </StyledForm>
    );
  }
}

Form.propTypes = {
  cancelButtonAction: PropTypes.func,
  cancelButtonLabel: PropTypes.string,
  disableSecondarySubmit: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  errorMessage: PropTypes.string,
  errors: PropTypes.shape({}),
  fields: PropTypes.arrayOf(PropTypes.shape(formFieldPropType)).isRequired,
  formData: PropTypes.shape({}),
  inlineActions: PropTypes.bool,
  isProcessing: PropTypes.bool,
  legend: PropTypes.string,
  noSubmit: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  renderAfterFields: PropTypes.node,
  secondaryButtonIcon: PropTypes.node,
  secondaryButtonLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  betaPageName: PropTypes.string
};

Form.defaultProps = {
  cancelButtonAction: null,
  cancelButtonLabel: 'Cancel',
  disableSecondarySubmit: false,
  disableSubmit: false,
  errorMessage: null,
  errors: null,
  formData: null,
  inlineActions: false,
  isProcessing: false,
  legend: null,
  noSubmit: false,
  onChange: null,
  onSubmit: null,
  renderAfterFields: null,
  secondaryButtonIcon: null,
  secondaryButtonLabel: null,
  submitLabel: 'Submit',
  betaPageName: null
};

export default Form;
