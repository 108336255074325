import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PATHS } from '../../../utils/constants';
import { CREATE_LEAD_MUTATION } from './mutations';

const useHandlers = () => {
  const history = useHistory();
  const [message, setMessage] = useState('');
  const [createLead, { loading: isProcessing, error }] = useMutation(CREATE_LEAD_MUTATION, {
    onCompleted: () => history.push(`${PATHS.DEMO}/confirm`),
    onError: (e) => setMessage(e.message),
  });

  const handleSubmit = async (data) => {
    const { first_name: firstName, last_name: lastName, email, company } = data;
    await createLead({ variables: { lead: { firstName, lastName, email, company } } });
  };

  return { handleSubmit, message, isProcessing, error };
};

export default useHandlers;
