import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Form, Title } from '../../components';
import { Container, Section, PageMain } from '../../skandComponents';

import { PATHS } from '../../utils/constants';
import { getFields } from './utils';

import useHandlers from './utils/useHandlers';

const Demo = () => {
  const fields = getFields();

  const { handleSubmit, isProcessing, message } = useHandlers();

  return (
    <div>
      <Helmet>
        <title>Demo</title>
      </Helmet>

      <PageMain modifiers={{ noSidebar: true, narrow: true }}>
        <Container>
          <Section>
            <Title type="h2">Demo</Title>
            <p>Before you start your demo, tell us a bit about yourself.</p>

            <Form
              fields={fields}
              onSubmit={handleSubmit}
              errorMessage={message}
              isProcessing={isProcessing}
              submitLabel="Request Demo"
              submitButtonProps={{ 'data-cy': 'demoSubmitButton' }}
            />

            <p>
              <Link to={PATHS.LOGIN} data-cy="demoHaveAccountLink">
                I already have an account
              </Link>
            </p>
          </Section>
        </Container>
      </PageMain>
    </div>
  );
};

export default Demo;
