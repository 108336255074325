import { gql } from '@apollo/client';

export const INVITE_USER_MUTATION = gql`
  mutation InviteUser(
    $hostDomain: String!
    $newUserEmail: String!
    $firstName: String!
    $lastName: String!
  ) {
    inviteUser(
      hostDomain: $hostDomain
      newUserEmail: $newUserEmail
      firstName: $firstName
      lastName: $lastName
    ) {
      id
    }
  }
`;

export const UPDATE_USER_BY_ID_MUTATION = gql`
  mutation UserPageUpdate($userId: ID, $user: UpdateUserInput) {
    updateUserById(userId: $userId, user: $user) {
      id
      email
      firstName
      lastName
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation UserPageDelete($userId: ID) {
    deleteUser(userId: $userId) {
      id
      email
      firstName
      lastName
    }
  }
`;
