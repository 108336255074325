// Import Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Import Styled Components
import StyledNotification from '../../styles/components/Notification';

import * as Icon from '../Icons';

const Notification = ({ children, modifiers, ...props }) => (
  <StyledNotification modifiers={modifiers} {...props}>
    {modifiers.error ? <Icon.CrossBold /> : <Icon.TickBold />}
    {children}
  </StyledNotification>
);

Notification.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.bool,
  modifiers: PropTypes.shape({
    error: PropTypes.bool,
  }),
};

Notification.defaultProps = {
  error: false,
  modifiers: {
    error: false,
  },
};

export default Notification;
