// Import Dependencies
import styled from 'styled-components';
import { colors } from 'utils/styles';

const Divider = styled.div`
  margin: ${(props) => `${props.topMargin} 0 ${props.bottomMargin} 0`};
  max-width: ${(props) => props.maxWidth};
  color: ${colors.white}80;

  display: flex;
  justify-content: center;
  align-items: center;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 0.1rem;
  }

  &::before {
    background: ${colors.white}80;
    margin-right: 0.5rem;
    min-width: ${(props) => `calc((${props.maxWidth} - 2.75rem) / 2)`};
  }

  &::after {
    background: ${colors.white}80;
    margin-left: 0.5rem;
    min-width: ${(props) => `calc((${props.maxWidth} - 2.75rem) / 2)`};
  }
`;

export { Divider };
