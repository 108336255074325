// Import Dependencies
import React from 'react';

// Import Styles
import Icon from '../../styles/components/Icon';

const ChevronDown = ({ ...props }) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path d="M250,392.3c-9.2,0-18-3.7-24.5-10.2L10.2,166.8c-13.5-13.5-13.5-35.5,0-49s35.5-13.5,49,0L250,308.7l190.8-190.9c13.5-13.5,35.5-13.5,49,0c13.5,13.5,13.5,35.5,0,49L274.5,382.2C268,388.7,259.2,392.3,250,392.3z" />
  </Icon>
);

export default ChevronDown;
