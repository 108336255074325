// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';
import { colors, global, spacing } from '../../../utils/styles';

const InnerButton = styled.a`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: flex-end;
  opacity: 0;
  position: absolute;
  right: 4px;
  top: 26px;
  visibility: hidden;
  width: calc(100% - 10px);

  &:hover {
    svg {
      fill: ${colors.orange};
    }
  }

  svg {
    fill: ${colors.grey300};
    margin-right: ${spacing.small};
    height: 12px;
    width: 12px;
    transition: fill ${global.transitionSpeed} ${global.easing};
  }

  /* Modifier: hasError */
  ${(props) =>
    getModifier(props, 'isActive') &&
    `
    opacity: 1;
    visibility: visible;
  `};
`;

export default InnerButton;
