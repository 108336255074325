// Import Dependencies
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Import Components
import BarChart from '../BarChart';
import Button from '../Button';
import LineChart from '../LineChart';
import Table from '../Table';

// Import Styled Components
import StyledChart from '../../styles/components/Chart';

class Chart extends PureComponent {
  renderChart = () => {
    const { type, ...props } = this.props;

    if (type === 'table') {
      return <Table modifiers={{ noMarginBottom: true, compact: true }} {...props} />;
    }

    if (type === 'line') {
      return <LineChart {...props} />;
    }

    if (type === 'bar') {
      return <BarChart {...props} />;
    }

    return <p>There was an error rendering the chart</p>;
  };
  render() {
    const { title, type, actionLabel, actionUrl, ...props } = this.props;

    // Check if widget is rendering a chart
    const isChart = type !== 'table';
    const isLineChart = type === 'line';

    return (
      <StyledChart modifiers={{ paddingRightSmall: isLineChart }} {...props}>
        <StyledChart.Header>
          <StyledChart.Title modifiers={{ marginBottomMidLarge: isChart }}>
            {title}
          </StyledChart.Title>
          {actionLabel && actionUrl && (
            <Button modifiers={{ inlineLink: true }} to={actionUrl}>
              {actionLabel}
            </Button>
          )}
        </StyledChart.Header>
        {this.renderChart()}
      </StyledChart>
    );
  }
}

Chart.propTypes = {
  actionLabel: PropTypes.string,
  actionUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

Chart.defaultProps = {
  actionLabel: null,
  actionUrl: null
};

export default Chart;
