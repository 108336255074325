// eslint-disable-next-line import/no-unresolved
import { Root } from '@skand/webapp-manage';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BetaReset } from 'utils/betaReset';

export const ForgotPasswordBeta = () => {
  return (
    <>
      <BetaReset />
      <Helmet>
        <title>Forgot password</title>
      </Helmet>

      <Root />
    </>
  );
};
