import { gql } from '@apollo/client';

export const CREATE_SITE_MUTATION = gql`
  mutation SiteDetailPageCreate($input: CreateSiteInput) {
    createSite(input: $input) {
      id
      buildingIds
      name
      manager
    }
  }
`;

export const UPDATE_SITE_MUTATION = gql`
  mutation SiteDetailPageUpdate($input: UpdateSiteInput) {
    updateSite(input: $input) {
      id
      buildingIds
      name
      manager
    }
  }
`;
