import { $tsToFix } from '../types';

const filterData = (
  data: $tsToFix,
  filterFunction: $tsToFix,
  text: $tsToFix,
  tabFilterFunction: $tsToFix
) =>
  data &&
  data.length &&
  data.filter(
    (item: $tsToFix) =>
      (!tabFilterFunction || tabFilterFunction(item)) &&
      (!filterFunction || filterFunction(item, text))
  );

export default filterData;
