// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { rgba } from 'polished';
import { getModifier } from '../../../utils/functions';

// Import Gobals
import typography from '../../global/typography';
import { colors, global, spacing } from '../../../utils/styles';

const Cell = styled.td`
  ${typography.body};
  border-bottom: solid 1px ${rgba(colors.white, 0.05)};
  color: ${colors.white};
  padding: ${spacing.small} ${spacing.base};
  width: ${(props) => getModifier(props, 'width') || 'auto'};

  img {
    max-width: ${spacing.xLarge};
  }

  // svg {
  //   display: inline-block;
  //   fill: ${colors.white};
  //   height: 16px;
  //   transition: fill ${global.transitionSpeed} ${global.easing} 0s;
  //   width: 16px;
  // }

  /* Modifier: Align Center */
  ${(props) =>
    getModifier(props, 'alignCenter') &&
    `
      text-align: center;
    `};

  /* Modifier: Actions */
  ${(props) =>
    getModifier(props, 'actions') &&
    `
      text-align: right;
      white-space: nowrap;
    `};

  /* Modifier: Refresh */
  ${(props) =>
    getModifier(props, 'refresh') &&
    `
      padding: ${spacing.small} ${spacing.base} ${spacing.small} 0;
      text-align: left;
      white-space: nowrap;
    `};

  /* Modifier: Break word */
  ${(props) =>
    getModifier(props, 'breakWord') &&
    `
      word-break: break-all;
    `};

  /* Modifier: Capitalise */
  ${(props) =>
    getModifier(props, 'capitalise') &&
    `
      text-transform: capitalize;
    `};

  /* Modifier: Compact */
  ${(props) =>
    getModifier(props, 'compact') &&
    `
      padding: 12px ${spacing.base};
    `};
`;

export default Cell;
