// Import Dependencies
import styled from 'styled-components';

// Import Elements
import { rgba } from 'polished';
import { TextField } from '@skand/lego';
import InnerButton from './InnerButton';

// Import Utilities
import { getModifier } from '../../../utils/functions';
import { placeholder } from '../../global/utils';
// Import Globals
import forms from '../../global/forms';
import { colors } from '../../../utils/styles';

export const CustomLegoTextInput = styled(TextField)`
  max-width: 24rem;
  /* Modifier: hasError */
  ${(props) =>
    getModifier(props, 'hasError') &&
    `
    border-color: ${colors.error};
  `};
`;

const TextInput = styled.input`
  ${forms.fieldReset};
  ${forms.fieldBase};

  ${placeholder`
     color: ${rgba(colors.white, 0.5)};
  `};

  /* Modifier: hasError */
  ${(props) =>
    getModifier(props, 'hasError') &&
    `
    border-color: ${colors.error};
  `};

  /* Modifier: hasIcon */
  ${(props) =>
    getModifier(props, 'hasIcon') &&
    `
    padding-right: 40px;
  `};
`;

TextInput.InnerButton = InnerButton;

export default TextInput;
