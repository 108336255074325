// Import Dependencies
import styled from 'styled-components';

// Import Utilities
import { getModifier } from '../../../utils/functions';

// Import Gobals
import { colors, layer, global } from '../../../utils/styles';

const Inner = styled.div`
  background: ${colors.grey800};
  border-radius: ${global.radius};
  box-shadow: ${global.shadowLarge};
  left: 50%;
  max-height: 90vh;
  max-width: 508px;
  overflow-x: auto;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  z-index: ${layer.modal};

  /* Modifier: Large */
  ${(props) =>
    getModifier(props, 'large') &&
    `
        max-width: 1024px;
    `};

  /* Modifier: autoWidth */
  ${(props) =>
    getModifier(props, 'autoWidth') &&
    `
        max-width: 90%;
        width: auto;
    `};

  /* Modifier: image */
  ${(props) =>
    getModifier(props, 'image') &&
    `
      border-radius: 0;
    `};

  /* Modifier: shareModal */
  ${(props) =>
    getModifier(props, 'shareModal') &&
    `
      height: 13.5rem;
      max-width: 31.25rem;
    `};

  /* Modifier: leaveModal */
  ${(props) =>
    getModifier(props, 'leaveModal') &&
    `
        background: ${colors.black};
        height: 15.438rem;
        width: 31.25rem;
      `};
`;

export default Inner;
