import { gql } from '@apollo/client';

export const UPDATE_CACHE_FOR_CREATE_SITE_MUTATION = gql`
  fragment newSite on Site {
    id
    buildingIds
    name
    manager
  }
`;
