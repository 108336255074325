// Import Dependencies
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Import Components
import Field from '../Field';

// Import Utilities
import { filterData, arrayToOptions } from '../../utils/functions';

// Import Components
import FilterTabs from '../FilterTabs';
import Hint from '../Hint';
import Loader from '../Loader';

// Import Styled Components
import Container from '../../styles/objects/Container';
import Section from '../../styles/objects/Section';
import Sidebar from '../../styles/objects/Sidebar';
import StyledForm from '../../styles/components/Form';

class FilterBar extends PureComponent {
  // Set default form data
  state = {
    text: '',
    activeTab: this.props.activeTab,
    data: this.props.data,
  };

  componentWillReceiveProps(nextProps) {
    this.filterData(nextProps.data);
  }

  // Handle form change
  handleChange = async (e) => {
    const { value, name } = e.target;
    const { data } = this.props;

    // Update value
    await this.setState(() => ({
      [name]: value,
    }));

    this.filterData(data);
  };

  handleBlur = () => {
    // Temp to remove errors
  };

  handleTabChange = async (activeTab) => {
    const { data } = this.props;

    // Update status
    await this.setState(() => ({
      activeTab,
    }));

    this.filterData(data);
  };

  // Filter data
  filterData(data) {
    const { filterFunction, filterTabs } = this.props;
    const { text, activeTab } = this.state;

    // Get filter function from filter tabs
    const tabFilterFunction =
      filterTabs && filterTabs.length
        ? filterTabs.find((item) => item.id === activeTab).filterFunction
        : null;

    // Update the state
    this.setState(() => ({
      data: filterData(data, filterFunction, text, tabFilterFunction),
    }));
  }

  render() {
    const {
      render,
      children,
      placeholder,
      renderActions,
      filterTabs,
      isFetching,
      canCategorize,
      hintContent,
      dataCyTestId,
    } = this.props;
    const { data, text, activeTab } = this.state;
    return (
      <div>
        <Sidebar>
          <Sidebar.Inner data-cy={dataCyTestId + 'Container'}>
            {children}
            {hintContent && (
              <Sidebar.Hint>
                <Hint {...hintContent} />
              </Sidebar.Hint>
            )}
            <Loader isFetching={isFetching}>
              <StyledForm.Fields>
                <Field
                  data-cy={dataCyTestId}
                  id="text"
                  type="text"
                  name="text"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  value={text}
                  label="Search"
                  tone="dark"
                  placeholder={placeholder}
                  disabled={isFetching}
                />
              </StyledForm.Fields>
              {renderActions && renderActions()}
            </Loader>
          </Sidebar.Inner>
        </Sidebar>
        <Container>
          <Section>
            {filterTabs && filterTabs.length > 0 && (
              <FilterTabs
                items={filterTabs}
                handleTabChange={this.handleTabChange}
                activeTab={activeTab}
              />
            )}
            {render({ data })}
          </Section>
        </Container>
      </div>
    );
  }
}

FilterBar.propTypes = {
  activeTab: PropTypes.string,
  canCategorize: PropTypes.bool,
  children: PropTypes.node,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterFunction: PropTypes.func.isRequired,
  filterTabs: PropTypes.arrayOf(PropTypes.shape({})),
  hintContent: PropTypes.shape({ title: PropTypes.string, content: PropTypes.string }),
  isFetching: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  render: PropTypes.func.isRequired,
  renderActions: PropTypes.func,
};

FilterBar.defaultProps = {
  activeTab: '',
  canCategorize: true,
  children: null,
  filterTabs: [],
  hintContent: null,
  placeholder: '',
  renderActions: null,
};

export default FilterBar;
