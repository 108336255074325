import { useTreatments } from '@splitsoftware/splitio-react';
import { WEBAPP_BETA_SWITCH } from '../../../splitConfig';
import { getIsBetaEnabled } from '../../../utils/betaEnabled';

export const useIsBetaFeatureEnabled = () => {
  const userTreatments = useTreatments([WEBAPP_BETA_SWITCH]);

  const userSwitchToBetaFeatureFlag = userTreatments[WEBAPP_BETA_SWITCH]?.treatment === 'on';

  const isBetaEnabledByUser = getIsBetaEnabled();

  // If user can switch to beta, return the user's choice
  if (userSwitchToBetaFeatureFlag) return isBetaEnabledByUser;
  // If user can't switch to beta, return true to enable beta
  return true;
};
