/* eslint-disable complexity */
import { PATHS } from '../../../utils/constants';

const getSideNavbarStatus = (pathname) => {
  const publicPage =
    pathname &&
    (pathname.startsWith(PATHS.LOGIN) ||
      pathname.startsWith(PATHS.DEMO) ||
      pathname.startsWith(PATHS.FORGOT_PASSWORD) ||
      pathname.startsWith(PATHS.CONFIRMATION) ||
      pathname.startsWith(PATHS.INVITATION) ||
      pathname.startsWith(`${PATHS.DATASETS}/download`) ||
      pathname.startsWith(`${PATHS.DATASETS_BETA}/download`) ||
      pathname.startsWith('/public'));

  const privatePageWithoutNavbar =
    pathname &&
    (pathname?.startsWith(`${PATHS.BRANDING}/preview`) ||
      pathname?.startsWith(`${PATHS.EXPLORE}/not-found`) ||
      pathname?.startsWith(`${PATHS.EXPLORE_SHARE}/not-found`) ||
      pathname?.startsWith(`${PATHS.DATASETS}/pdf`) ||
      pathname?.startsWith(`${PATHS.DATASETS}/not-found`) ||
      pathname?.startsWith(`${PATHS.AUTH}/azure`));
  return !publicPage && !privatePageWithoutNavbar;
};

export default getSideNavbarStatus;
