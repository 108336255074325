// Import Dependencies
import styled from 'styled-components';

// Import Globals
import { grid } from '../../../utils/styles';

const Fields = styled.ol`
  margin: 0 -${grid.gutter / 2}px;
`;

export default Fields;
