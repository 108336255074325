import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../../components/Loader';
import { StyledButton } from './buttonStyles';

const Button = ({ children, icon, modifiers }) => {
  return (
    <StyledButton>
      {icon}
      {children}
      {modifiers && modifiers.isProcessing && (
        <Loader modifiers={{ black: !(modifiers.secondary || modifiers.link) }} />
      )}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  modifiers: PropTypes.shape(),
};

Button.defaultProps = {
  modifiers: null,
  icon: null,
};

export default Button;
